import config from '../config';
import { CultureType } from './CompanyCultureType';
import { ReviewCategoryType } from './CompanyReviewType';

const URIs = {
  // Matching Tmp
  post_v1_match_applicants_photos: '/v1/match/applicants/photos',
  post_v1_match_applicants_video: '/v1/match/applicants/video',

  // v2 프로필
  post_profiles_file_upload: '/profiles/file/upload', // 프로필 파일 업로드
  delete_profiles_picture: '/profiles/picture', // 프로필 사진 > 삭제
  get_profiles_basic_info: '/profiles/basic-info', // 기본 정보 > 조회
  put_profiles_basic_info: '/profiles/basic-info', //  기본 정보 > 저장
  get_profiles_education: '/profiles/education', // 학력 정보 > 조회
  put_profiles_education: 'v2/profiles/education', //  학력 정보 > 저장
  get_profiles_career: '/profiles/career', // 경력 정보 > 조회
  post_profiles_career: '/v2/profiles/career', // 경력 정보 > 추가
  put_profiles_career_nhis: '/profiles/career/nhis', // nhis 경력 정보 추가
  put_v2_profiles_career_nhis: '/v2/profiles/career/nhis', // nhis 경력 정보 추가 v2
  put_profiles_career: (sn: number) => `/v2/profiles/career/${sn}`, // 경력 정보 > 수정
  delete_profiles_career: (sn: number) => `/v2/profiles/career/${sn}`, // 경력 정보 > 삭제
  get_profiles_preferential: '/profiles/preferential', // 병역 우대사항 정보 > 조회
  put_profiles_preferential: '/profiles/preferential', //  병역 우대사항 정보 > 저장
  get_profiles_file: '/profiles/file', // 프로필 첨부자료 > 조회
  put_profiles_file: '/profiles/file', //  프로필 첨부자료 > 저장
  get_profiles_prize: '/profiles/prize', // 수상 정보 > 조회
  put_profiles_prize: '/profiles/prize', // 수상 정보 > 저장
  get_profiles_skill: '/profiles/skill', // 지식기술 정보 > 조회
  put_profiles_skill: '/profiles/skill', // 지식기술 정보 > 저장
  get_match_conditions: '/v2/match/conditions', // 선호정보 조회
  put_match_conditions: '/v2/match/conditions', // 선호정보 저장
  put_match_conditions_job_group: '/v3/match/conditions/job-groups', // 선호정보 직군/직무만 저장
  put_match_conditions_locations: '/v3/match/conditions/locations', // 선호정보 지역만 저장
  put_match_conditions_salary: '/v3/match/conditions/salary', // 선호정보 연봉만 저장
  put_match_conditions_welfare: '/v3/match/conditions/welfare', // 선호정보 복지만 저장
  put_match_conditions_working_preference: '/v3/match/conditions/working-preference', // 선호정보 희망 근무 조건만 저장
  get_match_conditions_last_modified: '/match/conditions/last-modified', // 선호정보 마지막 수정일 조회
  post_match_conditions_last_modified: '/match/conditions/last-modified/skip', // 선호정보 마지막 수정일 저장
  get_profiles_experience: '/profiles/experience', // 경험정보 > 조회
  put_profiles_experience: '/profiles/experience', // 경험정보 > 저장
  get_profiles_projects: '/profiles/projects', // 프로젝트 > 목록 조회
  put_profiles_projects: '/profiles/projects', // 프로젝트 > 목록 저장
  post_profiles_projects: '/profiles/projects', // 프로젝트 > 추가
  put_profiles_projects_projectSn: (projectSn:number) => `profiles/projects/${projectSn}`, // 프로젝트 > 수정
  delete_profiles_projects_projectSn: (projectSn:number) => `profiles/projects/${projectSn}`, // 프로젝트 > 삭제
  get_profiles_v2_complete: '/profiles/v2/complete', // 프로필 완성 여부 조회
  put_profiles_file_image: '/profiles/file/image', // 프로필 사진 > 저장

  // 프로필 지원서
  get_profiles_details: '/profiles/details', // 프로필 상세 정보 > 미리보기 전체 조회
  get_profile_resume: '/profile-resume', // 프로필 지원서 > 순번 조회
  get_profile_resume_details: (profileResumeSn: number) => `/profile-resume/${profileResumeSn}`, // 프로필 지원서 > 상세 조회

  // v2 기업 채용 공고
  get_job_postings: (jobPostingSn: number) => `/v2/job-postings/${jobPostingSn}`, // 공고 상세 조회
  put_job_postings_bookmark: (jobPostingSn: number) => `/${jobPostingSn}/bookmark`, // 공고 북마크 추가,
  delete_job_postings_bookmark: (jobPostingSn: number) => `/${jobPostingSn}/bookmark`, // 공고 북마크 해제,

  // 채용 캘린더
  get_calendar_count: '/calendar/count', // 공고 개수 조회
  get_calendar_list: '/calendar/mo/list', // 공고 캘린더 리트 조회 (MO)
  post_calendar_filter: '/calendar/filter', // 캘린더 필터 저장.
  get_calendar_filter: '/calendar/filter', // 캘린더 필터 조회.

  // 매치 정보/대시보드
  get_match_profile: '/match/profile', // 매치 프로필 카드 정보 조회
  put_acc_results_select_match: '/acc/results/select-matching', // acc 결과 매칭 여부 저장
  put_pacc_results_select_match: '/phs/pacc/results/select-matching', // pacc 결과 매칭 여부 저장
  put_pst_results_select_match: '/phs/pst/results/select-matching', // pst 결과 매칭 여부 저장

  // 매치 현황
  get_match_response_status: '/match/response-status', // 매칭 현황 > 응답률 조회
  get_match_list: '/v2/match/list', // 매칭 현황 > 매칭 리스트 조회
  post_match_reject: '/match/reject', // 매칭 현황 > 매칭 거절
  post_v2_match_reject: '/v2/match/reject', // 매칭 현황 > 제안 거절 v2
  post_match_read: '/v2/match/read', // 매칭 현황 > 매칭 탭 전체 읽음 처리
  post_match_read_all: '/v2/match/read/all', // 매칭 현황 > 매칭 탭 전체 읽음 처리
  post_match_like: (matchingSn: number) => `/match/${matchingSn}/like`, // 매칭 현황 > 매칭 관심기업 추가
  post_match_unlike: (matchingSn: number) => `/match/${matchingSn}/unlike`, // 매칭 현황 > 매칭 관심기업 취소

  // 매치 제안
  get_match_config_status: '/match/config/status', // 매칭 제안 > 매칭 제안 설정 조회
  put_match_config_status: '/match/config/status', // 매칭 제안 > 매칭 제안 상태 변경
  put_match_config_job_search_status: '/match/config/job-search/status', // 매칭 제안 > 구직 상태 변경
  get_match_status: '/v3/match/status', // 매칭 제안 > 매칭 현황 조회
  post_match_accept: '/match/accept', // 메세지 > 매칭 제안 > 매칭 수락
  post_match_accept_v2: '/v2/match/accept', // 메세지 > 매칭 제안 > 매칭 수락 CBO 매칭 제안임
  get_match_position_profile: (positionSn: number) => `/match/${positionSn}/profile`, // 제안 받은 프로필 상세 조회

  // 팝업
  get_popup: '/popup', // 팝업조회

  // 매칭 홈
  get_match_profiles_type: '/profiles/type', // 프로필 타입 조회 (일반/개발자)
  put_match_profiles_job_group: '/profiles/job-group', // 취준 정보 > 직군/직무만 저장
  get_match_home_application_status: '/match/home/application/status', // 지원 현황 > 지원 현황 조회
  get_match_home_application_positions: '/v2/match/home/application/positions', // 지원 현황 > 포지션 목록 조회
  get_match_home_writing_resume_positions: '/match/home/writing-resume/positions', // 작성 중인 지원서 > 포지션 목록 조회
  get_match_home_application_hidden_positions: '/match/home/application/hidden-positions', // 지원 현황 > 전형 별 숨김 포지션 목록 조회
  get_match_home_application_response_status: '/match/home/application/response-status', // 지원 현황 > 사용자 응답 현황 조회 (응답률, 응답속도)
  post_match_home_application_certification: '/match/home/application/certification', // 지원 현황 > 취업 활동 증명서
  get_position_bookmarks: '/position/bookmarks', // 매칭 홈 북마크 조회

  post_profiles_audit_confirm_all: '/profiles/audit/confirm/all', // 프로필 > 전체 확인

  // 매칭홈 리뉴얼
  get_match_home_dashboard: '/match/home/dashboard', // 대시보드 조회
  put_match_home_dashboard_maching_setting: '/match/home/dashboard/matching-setting', // 대시보드 매칭설정 저장
  get_match_home_job_postings_acc: '/match/home/job-postings/acc', // ACC 채용공고 조회
  get_match_home_job_postings_now: '/match/home/job-postings/now', // 지금 뜨는 공고 조회
  get_match_home_matching_company: '/match/home/matching-company', // 매칭 기업 조회
  get_match_home_matching_positions: '/match/home/matching-positions', // 매칭 포지션 조회
  get_match_home_matching_quest_status: '/match/home/matching-quest-status', // 매칭 퀘스트 상태 조회
  post_match_home_matching_tutorial_complete: '/match/home/matching-tutorial-complete', // 매칭 튜토리얼 완료
  get_match_home_matching_user_count: '/match/home/matching-user-count', // 매칭 유저 수 조회
  get_match_home_positions_recommend: '/match/home/positions/recommend', // 매칭 추천 포지션 조회
  post_match_home_matching_tutorial_alarm: '/match/home/matching-tutorial-alarm', // 매칭 튜토리얼 알람 발송
  // 북마크
  get_bookmark_status: '/bookmark/status', // 북마크 현황 조회
  get_bookmark_positions: '/bookmark/positions', // 포지션 북마크 목록 조회
  get_bookmark_job_postings: '/bookmark/job-postings', // 공고 북마크 목록 조회
  get_bookmark_companies: '/bookmark/companies', // 기업 북마크 목록 조회

  // v2 대시보드
  get_acc_results_list: '/acc/results/progress-complete', // acc 응시완료 결과 목록 조회
  get_pst_results_list: '/v2/phs/pst/results/progress-complete', // pst 응시완료 결과 목록 조회
  get_pacc_results_list: '/phs/pacc/results/progress-complete', // pacc 응시완료 결과 목록 조회
  put_application_submit_apply: (positionSn: number) => `match/application/${positionSn}/submit-apply`, // 응시 결과 제출 (ACC,PACC,PST)
  get_recruitment_url: '/recruitment/url', // jf3도메인 정보

  // 역량검사 센터
  get_acc_center_apply_count: '/acc/center/apply-count', // 역량검사 응시자 수 조회
  get_acc_center_apply_recent: '/v2/acc/center/apply/recent ', // 최근 응시내역 목록
  get_acc_center_apply_recent_jobda: '/acc/center/apply/recent/jobda',
  delete_acc_center_apply_recent: (sn:number) => `/acc/center/apply/recent/${sn}`, // 최근 응시내역 제거
  get_acc_center_atoz: '/acc/center/atoz', // 역량검사 a to z 목록
  get_acc_center_positions_recommend: '/acc/center/positions/recommend', // 역량검사 결과 맞춤형 포지션 추천
  get_acc_center_postings: '/job-postings/ads/acc', // 역량검사로 채용중인 공고 목록
  get_acc_center_screening_pass_reviews: '/acc/center/screening-pass-reviews', // 역량검사 합격 후기 목록
  get_acc_center_apply_recent_company: '/acc/center/apply/recent/company', // 기업별 최근 응시내역

  // acc 응시 결과
  put_acc_result_name: `/v1/acc`, // acc 응시명 수정
  get_v2_acc_results: '/v2/acc/results', // acc 결과 조회
  delete_v3_acc: '/v3/acc', // acc 삭제
  get_acc_apply_trial_url: `/v1/acc/apply/trial/url`, // 역검 주소 조회

  // ATS 수동 인재 계정 연동
  post_match_applicants_account_link: 'v2/match/applicants/account/link',
  get_resume_exists: `/match/application/resume/exists`, // 프로필 작성중 여부 확인

  // 포지션 지원취소 지원삭제
  delete_match_application_resume: (positionSn: number) => `/match/application/${positionSn}/resume`, // 작성 중 지원서 삭제
  get_position_status: (positionSn: number) => `/position/${positionSn}/status`, // 포지션 상태 조회

  // 대시보드 포지션 숨기기
  put_match_home_application_positions_hide: '/match/home/application/positions/hide', // 지원 현황 > 포지션 숨김
  put_match_home_application_positions_show: '/match/home/application/positions/show', // 지원 현황 > 포지션 숨김 해제

  // ATS지원서
  position_sn: '/position/sn', // 포지션 순번 조회
  resume_auth: '/api/resume/v1/auth', // 지원서 인증
  resume_site: '/resume/site', // 포지션의 지원서사이트 연결 정보 조회
  get_resume_agree: (positionSn: number) => `/match/application/${positionSn}/resume/agree`, // 포지션 지원 동의 여부
  get_resume_agree_url: (positionSn: number) => `/match/application/${positionSn}/resume/agree/url`, // 포지션 지원서 동의 url
  get_resume_sn: (positionSn: number) => `/match/application/${positionSn}/resume`, // 포지션에 제출한 지원서 번호 조회
  post_resume_agree: (positionSn: number) => `/match/application/${positionSn}/resume/agree`, // 포지션 지원 동의 처리

  // 포지션 광고
  get_position_ad: '/position/ad', // 포지션 광고 조회
  post_position_ad_clicks: (positionAdSn:number|string = ':positionAdSn') => `/position/ad/${positionAdSn}/clicks`, // 포지션 광고 클릭수 추가
  post_position_ad_shows: (positionAdSn: number | string = ':positionAdSn') => `/position/ad/${positionAdSn}/shows`, // 포지션 광고 클릭수 추가

  // 포지션 큐레이션
  get_curations: '/curations', // 큐레이션 목록
  get_curations_positions: '/curations/positions', // 큐레이션 목록
  get_curations_sn_positions: (curationSn: number | string = ':curationSn ') => `/curations/v2/${curationSn}/positions`, // 큐레이션 목록

  // 글로벌 검색
  get_search_recommend_keyword: '/search/recommend/keyword', // 추천 키워드
  get_search_v2_similar_keyword: '/search/v2/similar/keyword', // 유사 키워드

  // 기업 채널
  get_companies_search: '/companies/search', // 글로벌 검색 기업목록 조회

  // 챌린지
  get_match_challenge_ad_list: '/match/challenge/ad/list', // 매칭 챌린지 광고 목록 조회
  get_match_challenge_list: '/match/challenge/list', // 매칭 챌린지 목록 조회
  get_match_challenge_sn: (challengeSn: number) => `/match/challenge/${challengeSn}`, // 챌린지 상세 조회
  get_match_challenge_companies: (challengeSn: number) => `/match/challenge/${challengeSn}/companies`, // 챌린지 참여 기업 목록 조회
  get_match_challenge_positions: (challengeSn: number) => `/match/challenge/${challengeSn}/positions`, // 챌린지 포지션 목록 조회
  // 모바일 APP 푸시 알림
  put_users_device_fcm_app_runtime: '/users/device/fcm/app-runtime', // 최근 앱 실행 시간 갱신
  post_users_device_fcm_message: '/users/device/fcm/message', // [테스트용] FCM 메시지 발송
  post_users_device_fcm_notification_message: '/users/device/fcm/notification-message', // [테스트용] FCM 메시지 발송 (title, body 포함)
  post_users_device_fcm_token: '/users/device/fcm/token', // FCM 토큰 저장
  delete_users_device_fcm_token: '/users/device/fcm/token', // FCM 토큰 삭제
  post_users_device_fcm_topic_message: '/users/device/fcm/topic-message', // [테스트용] FCM 토픽으로 메시지 발송
  post_notification_log: '/notification-logs', // 알람 조회 로그 저장

  post_login: '/login',
  post_apple_login: 'oauth2/apple/login',
  post_logout: '/logout',
  get_login_user_info: '/login/user/info',
  get_user_work_log: '/users/work-log/counts',
  get_access_token: '/access-token',
  get_reissue_token: '/reissue-token',
  get_themes_list: '/themes',
  get_themes_detail: (sn: number | string = ':sn') => `/themes/${sn}`,
  get_themes_companies: (sn: number | string = ':sn') => `/themes/${sn}/companies`,
  post_file_upload: '/profiles/file/upload',
  get_file_download: (fileUid: number | string = ':fileUid') => `/file/${fileUid}/download`,
  get_file: (fileUid: number | string = ':fileUid') => `/file/${fileUid}`,
  get_file_host: (fileUid: number | string = ':fileUid') => `${config.host}/file/${fileUid}`,
  get_file_view: (fileUid: number | string = ':fileUid') => `/file/${fileUid}/view`,
  get_file_view_host: (fileUid: number | string = ':fileUid') => `${config.host}/file/${fileUid}/view`,
  get_file_signed_url: (fileUid:number | string = ':fileUid') => `/file/${fileUid}/signed-url`,
  get_notifications: '/v3/notifications',
  put_notifications: '/notifications',
  delete_notifications: '/notifications',
  get_sse_subscribe: '/sse/subscribe', // SSE 구독

  // 회원가입
  post_v3_register: '/v3/register', // 회원가입 핸드폰-IPIN 본인인증 추가
  post_v4_register: '/v4/register',
  post_v5_register: '/v5/register',
  post_profile_input_remind: '/users/notification-setting-item/profile-input-remind',
  get_register_duplication_check_email: '/register/duplication/check/email', // 이메일 중복체크
  get_register_duplication_check_id: '/register/duplication/check/id', // 아이디 중복체크
  get_register_duplication_check_mobile: '/v3/register/duplication/check/mobile', // 휴대폰 중복체크

  // 아이디, 비밀번호 찾기
  post_users_id: '/users/id', // 아이디 찾기
  post_v2_users_id: '/v2/users/id', // 아이디 찾기
  get_users_id_exist: '/users/id/exist', // 아이디 존재 유무
  post_users_password_verification: '/users/password/verification', // 비밀번호 재설정 인증
  post_v2_users_password_verification: '/v2/users/password/verification', // 비밀번호 재설정 인증
  post_users_password_reset: '/users/password/reset', // 비밀번호 재설정
  post_v2_users_password_reset: '/v2/users/password/reset', // 비밀번호 재설정
  post_identity_id: '/identity/id', // 아이디 찾기 (ipin)
  post_users_password_reset_ipin: '/identity/password/reset', // 비밀번호 재설정 (ipin)
  put_v2_identity: '/v2/identity', // 계정 설정 본인인증 저장
  get_identity_mobile: '/identity/mobile', // 휴대폰 및 본인인증 여부 조회
  // 본인인증
  post_verification_sms_send: '/verification/sms/send', // sms  인증
  post_v2_verification_sms_send: '/v2/verification/sms/send', // sms  인증
  post_verification_sms_send_id: '/verification/sms/send/id', // sms  인증
  post_verification_sms_send_password: '/verification/sms/send/password', // sms  인증
  post_verification_sms_check: '/verification/sms/check', // sms  인증
  post_verification_user_sms_send: '/verification/user/sms/send', // 로그인 회원 SMS 본인인증 발송
  post_verification_user_sms_check: '/verification/user/sms/check', // 로그인 회원 SMS 본인인증 인증
  post_verification_user_long_inactive_check: '/verification/user/long-term-inactive/check', // 장기 미이용자 본인 인증 확인

  // KCB 본인 인증
  get_kcb_ipin_identify_request: '/kcb/ipin-identity-request', // IPIN 본인인증 요청
  get_kcb_ipin_identify_verify: '/kcb/ipin-identity-verify', // IPIN 본인인증 결과 확인 및 검증
  get_kcb_sms_identify_request: '/kcb/sms-identity-request', //  SMS 본인인증 요청
  get_kcb_sms_identify_verify: '/kcb/sms-identity-verify', // SMS 본인인증 결과 확인 및 검증

  //* * main */
  get_title_main: '/titles/main', // 메인 컨텐츠 > 타이틀 조회
  get_layouts_contents: '/layouts/contents', // 메인 컨텐츠 > 각 레이아웃 컨텐츠 리스트 조회

  //* * codes */
  get_register_schools: '/codes/schools', // 학교목록 조회
  get_register_major_aliases: '/codes/major-aliases', // 학교목록 조회
  get_codes_job_groups_job_titles: '/codes/v2/job-groups/job-titles', // 직군 직무 전체조회
  get_codes_countries: '/codes/countries', // 국가 전체 조회
  //* * user */
  get_users_marketing_consent: '/users/marketing-consent',
  put_users_mobile: '/users/mobile', // 휴대폰 번호 변경
  get_users_default: '/users/default', // 기본정보 조회
  put_users_default: '/users/default', // 기본정보 수정
  get_user_duplication_check_email: '/users/duplication-check/email', // 이메일 중복체크
  get_user_duplication_check_mobile: '/users/duplication-check/mobile', // 휴대폰 중복체크
  put_users_notification_settings: '/users/notification-settings', // 알림 수신여부 변경
  put_users_notification_settings_item: '/users/notification-setting-item', // 카카오톡 수신 여부 변경
  get_users_account: '/users/account', // 계정정보 조회
  delete_users: '/users', // 계정 삭제
  put_users_password: '/users/password', // 비밀번호 변경사항 저장
  get_users_educations: '/users/educations', // 학력정보 조회
  put_users_educations: '/users/educations', // 학력정보 변경
  put_users_birthdate: '/users/birth-date', // 생년월일 변경 (만 15세 미만 대상)
  put_users_password_expiration_refresh: 'users/password/expiration/refresh', // 비밀번호 만료 시간 갱킹
  // v2 기업 채널
  get_company_employment: (sn: number | string = ':sn') => `/companies/${sn}/employment`, // 기업 채널 > 직원 정보
  get_company_finance: (sn: number | string = ':sn') => `/companies/${sn}/finance`, // 기업 채널 > 재무 정보
  get_company_salary: (sn: number | string = ':sn') => `/companies/${sn}/salary`, // 기업 채널 > 연봉 정보

  // 기업정보관 기업 채널
  get_company_advantage: (sn: number | string = ':sn') => `/companies/${sn}/advantage`, // 기업 채널 > 입사 선배가 알려주는 장점
  get_company_information: (sn: number | string = ':sn') => `/companies/${sn}/information`, // 기업 채널 > 기업 정보
  get_company_interview_practices_pooled_questions: (sn: number | string = ':sn') => `/companies/${sn}/interview-practices/pooled-questions`, // 기업 채널 > 면접후기 > 기출 면접 항목
  get_company_news: (sn: number | string = ':sn') => `/companies/${sn}/news`, // 기업 채널 > 뉴스 정보
  get_company_welfare: (sn: number | string = ':sn') => `/companies/${sn}/welfare`, // 기업 채널 > 복지
  get_company_position: (sn: number | string = ':sn') => `/companies/${sn}/positions`, // 기업 채널 > 현재 채용중인 포지션
  get_company_specialty: (sn: number | string = ':sn') => `/companies/${sn}/specialty`, // 기업 채널 > 특징
  get_company_themes: (sn: number | string = ':sn') => `/v2/companies/${sn}/themes`, // 기업 채널 > 기업 테마
  get_company_header: (sn: number | string = ':sn') => `/v2/companies/${sn}/header`, // 기업 채널 > 기업 헤더
  get_company_employee_stories: (sn: number | string = ':sn') => `/companies/${sn}/employee-stories`, // 기업 채널 > 현직자 이야기

  // 매치 리포트
  get_match_report_companies: `match/report/companies`, // 매치 리포트 > 기업 목록 조회
  get_match_report_companies_sn: (companySn: number | string = ':companySn') => `/match/report/companies/${companySn}`, // 매치 리포트 > 기업 상세 조회
  get_match_report_companies_sn_positions: (companySn: number | string = ':companySn') => `/match/report/companies/${companySn}/positions`, // 매치 리포트 > 기업 포지션 목록 조회
  get_match_report: `/match/report/`, // 매칭 리포트 상세 조회

  // 기업 검색
  get_companies_list: '/companies/list', // 기업을 잡다 목록 조회
  get_companies_names: '/companies/names', // 기업을 잡다 기업명 조회
  get_companies: '/companies', // 블라인드, 경력정보 조회 기업 (NICE DB)

  // 기업 프로필
  // get_company_header: (sn: number | string = ':sn') => `/companies/${sn}/header`, // 기업 헤더 조회
  put_company_like: (companySn: number | string = ':companySn') => `/v2/companies/${companySn}/like`, // 기업 관심기업 추가
  put_company_unlike: (companySn: number | string = ':companySn') => `/v2/companies/${companySn}/unlike`, // 기업 관심기업 취소
  get_company_profile_images: (sn: number | string = ':sn') => `/companies/${sn}/profile/images`, // 기업 사진 목록 조회
  get_company_profile_review_experience: (sn: number | string = ':sn') => `/companies/${sn}/profile/reviews/experience`, // 기업 프로필 특별한경험 리뷰 목록 조회
  get_company_profile_review_best: (sn: number | string = ':sn') => `/companies/${sn}/profile/reviews/best`, // 기업 잡다가 선정한 최고의 리뷰 목록 TOP3 조회
  get_company_profile_keywords: (sn: number | string = ':sn', type: CultureType | ':type' = ':type') => `companies/${sn}/profile/keywords?type=${type}`, // 기업 키워드 목록 조회
  get_company_profile_keyword_review: (companySn: number | string = ':companySn', keywordSn: number | string = ':keywordSn') => `companies/${companySn}/profile/keywords/${keywordSn}/reviews`, // 기업 키워드별 리뷰 목록
  get_company_profile_summary: (companySn: number | string = ':companySn') => `/companies/v2/${companySn}/profile/summary`, // 기업 개요 조회
  get_company_profile_industry: (companySn: number | string = ':companySn') => `/companies/${companySn}/profile/industry`, // 기업 사업분석
  get_company_profile_employment: (companySn: number | string = ':companySn') => `companies/${companySn}/profile/employment`, // 기업 고용 안정성 조회
  get_company_profile_themes: (companySn: number | string = ':companySn') => `companies/${companySn}/themes`, // 기업에 속한 테마 목록 조회
  get_company_profile_salary: (companySn: number | string = ':companySn') => `companies/${companySn}/profile/salary`,

  // 기업 리뷰
  get_companies_reviews: (companySn: number | string = ':companySn', topicType: ReviewCategoryType | ':topicType' = ':topicType') => `/companies/${companySn}/reviews?topicType=${topicType}`,

  // 기업 문화 분석
  get_company_culture_top_5: (companySn: number | string = ':companySn') => `/company/culture/${companySn}`,
  get_company_culture_detail: (companySn: number | string = ':companySn', type: CultureType | ':type' = ':type') => `/company/culture/${companySn}/${type}`,

  // 기업 채용 공고
  get_jobs_company: (companySn: number | string = ':companySn') => `/companies/${companySn}/jobs`,
  get_jobs_company_count: (companySn: number | string = ':companySn') => `/companies/${companySn}/job-count-by-type`,

  // pass
  get_pass_review_list: '/pass/review/list',
  get_pass_review_company_header: (companySn: number | string = ':companySn') => `/pass/review/${companySn}/header`,
  get_pass_review_company_simple: (companySn: number | string = ':companySn') => `/pass/review/${companySn}/simple`,
  get_pass_review_company_pass_detail: (companySn: number | string = ':companySn', passReviewSn: number | string = ':passReviewSn') => `/pass/review/${companySn}/${passReviewSn}/detail`, // passReviewSn 별 상세 정보
  get_pass_review_company_industry: (companySn: number | string = ':companySn') => `/pass/review/${companySn}/industry`,
  get_pass_review_company_exists: (companySn: number | string = ':companySn') => `/pass/review/${companySn}/exists`,

  // 가고싶어요
  get_user_liked_companies: '/user/liked-companies', // 기업 목록 조회
  get_user_liked_companies_companySn_jobs: (companySn: number | string = ':companySn') => `/user/liked-companies/${companySn}/jobs`,
  get_user_liked_companies_jobs: '/user/liked-companies/jobs', // 기업들과 공고 목록 조회
  get_user_liked_companies_new_jobs_count: '/user/liked-companies/new-jobs/count', // 가고싶어요 기업의 최신공고 개수
  get_user_liked_companies_new_jobs: '/user/liked-companies/new-jobs', // 가고싶어요 기업의 최신공고

  // 스마트 필터링
  get_tags_list: '/tags/list', // 태그 리스트 검색
  get_tags_companies_count: '/tags/companies/count', // 태그에 매칭되는 기업 개수
  get_tags_companies: '/tags/companies', // 태그에 매칭되는 기업 조회

  // 넛지 조회
  get_nudges: '/nudges',

  // 이벤트
  post_event: '/event', // 이벤트 회원가입
  get_event_code: '/event/code', // 공유 URL 조회
  get_event_code_check: '/event/code/check', // 이벤트 초대 code 정상 여부 확인
  get_event_count: '/event/count', // 이모티콘 잔여 수량 조회,
  get_event_my_rank: '/event/my-rank', // 나의 랭크 조회,
  get_event_top_rank: '/event/top-rank', // TOP3 랭크 조회,
  get_events: '/events', // 이벤트 날짜 조회,

  // 큐레이션(추천)
  get_recommend_companies: '/recommend/companies', // 가고 싶어요한 기업 큐레이션(추천)
  get_recommend_search_companies: '/recommend/search/companies', // 기업 검색페이지 기업리스트 추천
  get_recommend_search_themes: '/recommend/search/themes', // 기업검색페이지 테마리스트 추천

  // 나만의 큐레이션
  delete_user_curation: (curationSn: number) => `/user/curation/${curationSn}`, // 나의 큐레이션 히스토리 삭제
  get_user_curation_histroy: (curationSn: number) => `/user/curation/${curationSn}/history`, // 나의 큐레이션 히스토리 불러오기
  get_user_curation_history_list: '/user/curation/history', // 나의 큐레이션 히스토리 조회
  post_user_curation_advanced_search: '/user/curation/advanced/search',
  post_user_curation_companies_jobs: (companySn: number) => `/user/curation/companies/${companySn}/jobs`, // 고급 > 기업 공고 조회
  get_user_curation_companies_match_rate: (companySn: number) => `/user/curation/companies/${companySn}/match-rate`, // 기업 문화 분석 점수 조회
  post_user_curation_compare_all_companies: '/user/curation/compare/all-companies', // 고급 > 기업별 비교분석 > 전체기업 조회
  get_user_curation_simple_companies: 'user/curation/simple/companies', // 기업 중심 추천(초급) > 기업 리스트 검색
  get_user_curation_simple_companies_recommend: (companySn: number) => `/user/curation/simple/companies/${companySn}/recommend`, // 기업 중심 추천(초급) > 맞춤형 조건 추천
  post_user_curation_survey: '/user​/curation​/survey', // 피드백 저장

  // 역량검사지원하기
  get_ai_application_check_period: '/ai/application/check/period', // 응시 기간 확인
  get_ai_application_check_submit: '/ai/application/check/submit', // 응시 코드 제출 여부
  post_ai_application_create: '/ai/application/create', // 응시 코드 제출 여부

  // bbq 지원현황
  get_bbq_jobs: '/bbq/jobs',

  // 약관
  get_terms: '/terms', // 회원가입 약관 목록 조회

  // 안내(약관 사전 안내)
  get_notices: (noticeSn?: number) => (noticeSn ? `/notices/${noticeSn}` : '/notices'), // 안내 본문 조회 , 안내 목록 조회

  // 취업 콘텐츠
  get_category_info: '/post/category?type=INFO',
  get_category_all: '/post/category?type=ALL',
  get_category_thumbnails: (categorySn: number) => `/post/${categorySn}/thumbnails`,
  get_thumbnails_all: '/post/thumbnails?type=ALL', // 전체 취업콘텐츠 조회
  get_thumbnails_info: '/post/thumbnails?type=INFO',
  get_post: (postSn: number) => `/post/${postSn}`,
  get_post_pass_review: `/post/pass-review`,
  // 취업 잡다 프로젝트
  get_category_job: '/post/category?type=JOB',
  get_thumbnails_job: '/post/thumbnails?type=JOB', // 전체 취업 잡다 프로젝트 조회

  // 타게팅 홍보 API
  get_ads_sn: (companySn: number | string = ':companySn') => `/ads/${companySn}`, // 기업의 홍보 소재 목록
  get_ads: '/ads', // 전체 보기
  get_ads_companies: '/ads/companies',
  post_ads_view: (adSn: number | string = ':adSn') => `/ads/${adSn}/view`, // 광고 캠페인 클릭 수 증가
  // 기술키워드
  get_skills: '/skills', // 기술키워드 목록 조회
  get_skills_recommend: (skillCode: number) => `/skills/${skillCode}/recommend`, // 추천 키워드 조회

  // 자격증
  get_license: '/licenses', // 자격증 목록 조회

  // 어학 시험
  get_examinations: '/examinations', // 어학시험 목록 조회
  get_examination_code_grade: (code: number) => `/examinations/${code}/grades`, // 어학시험 등급 목록 조회

  // 본인인증 조회
  get_identity: '/identity', // 본인 인증 여부 조회
  get_identity_ci: '/identity/ci', // 본인 인증 여부 조회 (ipin)

  // 매칭 프로필
  get_match_profiles_complete: '/profiles/v2/complete', // 프로필 완성여부
  get_match_profiles_seek: '/match/profiles/job-search', // 취준 정보 조회
  put_match_profiles_seek: '/match/profiles/job-search', // 취준 정보 저장
  get_match_profiles_education: '/match/profiles/education', // 학력정보 조회
  put_match_profiles_education: '/match/profiles/education', // 학력정보 저장
  get_match_profiles_career: '/match/profiles/career', // 경력경험 조회
  put_match_profiles_career: '/match/profiles/career', // 경력경험 저장
  get_match_profiles_skill: '/match/profiles/skill', // 지식기술정보 조회
  put_match_profiles_skill: '/match/profiles/skill', // 지식기술정보 저장
  get_match_profiles_next_step: '/match/profiles/next-step', // 마지막 저장 완료 이후 다음 스텝 조회
  put_match_profiles_acc_step: '/match/profiles/acc-step', // 게이트 단계 완료 처리
  get_match_profiles_agree_job_search: '/match/profiles/agree-job-search', // 구직의사 조회
  put_match_profiles_agree_job_search: '/match/profiles/agree-job-search', // 구직의사 저장
  get_match_profiles_my_blind: '/profiles/my-blind-companies', // 나의 블라인드 조회
  put_match_profiles_my_blind: '/profiles/my-blind-companies', // 나의 블라인드 저장
  // get_match_profiles_blind: '/match/profiles/blind-companies', // NICE 블라인드 기업 목록 조회
  get_match_terms_information: '/terms/matching', // 매칭 동의 약관 불러오기
  get_terms_result_privacy_provision: '/terms/result-privacy-provision', // 역량검사/개역량검사/개구검 결과 제출하기 약관 조회
  post_terms_result_privacy_provision: 'terms/result-privacy-provision', // 역량검사/개역량검사/개구검 결과 제출하기 동의문 생성
  get_terms_third_party_privacy_provision: '/terms/third-party/privacy-provision', // 개인정보 제3자 제공 약관 내용 조회
  post_terms_third_party_privacy_provision: '/terms/third-party/privacy-provision', // 개인정보 제3자 제공 약관 내용 조회(공용) 생성
  get_terms_third_party_privacy_provision_agreement: '/terms/third-party/privacy-provision/agreement', // 개인정보 제3자 제공 약관 동의 여부 조회(공용)
  get_user_terms_agree_matching_agree: '/user-terms-agrees/matching/agree-information-yn', // 역량정보 > 정보활용 동의 조회
  post_user_terms_agree_matching_agree: '/user-terms-agrees/matching/agree-information', // 잡다매치 매칭 약관 동의 생성
  get_user_terms_agree_matching_sensitive_agree: '/user-terms-agrees/profile/sensitive/agree-information-yn', // 매칭 취준정보 민감정보
  post_user_terms_agree_matching_sensitive_agree: '/user-terms-agrees/profile/sensitive/agree-information', // 매칭 취준정보 민감정보
  post_user_terms_agree_alimatalk_acc_agree: '/user-terms-agrees/alimtalk/acc/agree-information', // 알림톡 > 신역량검사 약관 동의 생성 (로그인 없이 동의)
  put_match_profiles_file_upload: '/match/profiles/file/upload', // 매칭 프로필 파일 업로드
  post_user_terms_agree_position_file_agree: '/user-terms-agrees/position/agree-information', // 매칭 포지션 파일 약관 동의 생성
  get_user_terms_agree_position_file_agree: '/user-terms-agrees/position/agree-information-yn', // 매칭 포지션 파일 약관 동의 조회

  // 매칭 포지션
  get_positions: '/position/', // 전체 포지션 목록 조회
  get_positions_sn: (positionSn: number) => `/v2/position/${positionSn}`, // 포지션 JD 상세 조회
  get_position_matching_challenge: (positionSn: number) => `/position/${positionSn}/matching-challenge`, // 매칭 포지션 JD > 동일 챌린지 포지션 조회
  get_position_matching_company: (positionSn: number) => `/position/${positionSn}/matching-company`, // 매칭 포지션 JD > 동일 기업 포지션 조회
  get_position_matching_job: (positionSn: number) => `/position/${positionSn}/matching-job`, // 매칭 포지션 JD > 유사 공고 포지션 조회
  get_match_positions_recommend: '/position/recommend', // 추천 포지션 목록 조회
  get_match_positions_prieview: `/position/preview`, // 매칭 포지션 JD 미리보기
  put_match_positions_bookmark: (positionSn: number) => `/position/${positionSn}/bookmark`, // 포지션 북마크 추가
  delete_match_positions_bookmark: (positionSn: number) => `/position/${positionSn}/bookmark`, // 포지션 북마크 추가
  get_position_profile_job_titles: '/position/profile/job-titles', // 포지션 JD 직무별 프로필 조회
  get_position_profile_recommend: '/position/profile/recommend', // 프로필 > 포지션 추천
  verify_match_application_position_apply: (positionSn: number) => `match/application/v1/${positionSn}/apply/verification`, // 입사 지원 조건 검증
  verify_match_application_position_requirement: (positionSn:number) => `match/application/${positionSn}/requirement/verification`, // 포지션 필수값 검증
  post_match_application_position_apply: (positionSn: number) => `match/application/${positionSn}/apply`, // 매칭 포지션 JD 입사 지원
  delete_match_application_position_apply: (positionSn: number) => `match/application/${positionSn}/apply`, // 매칭 포지션 JD 입사 지원 취소
  get_match_application_position_addition_info: (positionSn: number) => `/match/application/${positionSn}/addition-info`, // 추가 정보 조회
  put_match_application_position_addition_info: (positionSn: number) => `/match/application/${positionSn}/addition-info`, // 추가 정보 저장

  get_match_application_position_files: (positionSn: number) => `/match/application/${positionSn}/files`, // 추가 서류 다운로드
  post_match_application_position_files: (positionSn: number) => `/match/application/${positionSn}/files`, // 추가 서류 제출
  get_match_application_position_acc_status: (positionSn: number) => `/match/application/${positionSn}/position-acc-status`, // 포지션 역검 필수 여부 및 구직자의 역검 전형상태 확인
  get_match_position_navigation_total: (positionSn: number) => `/positions/${positionSn}/navigation/matching-job`, // 매칭 포지션 JD 동일 직무 랜덤 조회 (전체 리스트)
  get_match_position_navigation_recommend: '/positions/navigation/recommend', // 매칭 포지션 JD 추천 리스트 조회 (사이드 탭)
  get_match_position_anchoring: (positionSn: number) => `/positions/${positionSn}/anchoring`, // JD 추천 리스트 앵커링 페이지

  get_position_job_groups: `/position/job-groups`, // 직군별 인기 포지션 조회 > 선택 직군 포지션 랜덤 조회

  // 메인 배너 - 광고
  get_ads_main_banners: `/ads/main-banners`, // 메인배너 광고 조회
  get_ads_main_banners_rolling: '/ads/main-banners/rolling', // 메인 배너 롤링
  get_main_matching_company: `/match/company`, // 메인 > 매칭 기업 조회

  // 광고 성과
  post_ads_impression: (adSn:number) => `/ads/${adSn}/impression`, // 광고 노출 수 증가
  post_ads_views: (adSn:number) => `/ads/${adSn}/views`, // 광고 조회(클릭) 수 증가

  // 채용 공고
  get_job_posting_similar: (jobPostingSn:number) => `${jobPostingSn}/similar`, // 비슷한 공고 조회(랜덤)
  get_job_postings_jobPostingSn: (jobPostingSn:number) => `job-postings/${jobPostingSn}`, // 채용 공고 상세 조회
  get_job_postings_acc: `/job-postings/acc`, // 역량검사로 채용중인 공고 목록

  // 채용 공고 - 광고
  get_job_postings_ads_curation: `job-postings/ads/curation`, // 큐레이션 공고 조회
  get_job_postings_ads_curation_categories: `job-postings/ads/curation/categories`, // 큐레이션 분류 조회
  get_job_postings_ads_recommend: `job-postings/ads/recommend`, // 추천 공고 조회
  get_job_postings_ads_title: `job-postings/ads/title`, // 광고 카테고리별 타이틀 조회
  // 복지
  get_welfare_categories: '/codes/welfare-categories', // 복지 카테고리 조회
  get_welfare: '/codes/welfare', // 복지 카테코리별 복지 조회
  get_welfare_categories_welfares: '/codes/welfare-categories/welfares', // 전체 복지 조회
  // 신역량검사 응시
  get_acc_results: 'acc/results', // 응시 결과 목록
  get_acc_max_apply_chance: '/acc/max-apply-chance', // 최대 응시 회수 조회

  // 신역량검사 응시 결과
  get_acc_result: '/acc/results', // 이전 응시 결과
  get_result_analysis_complete: '/acc/results/analysis-complete', // 신역량검사 응시 분석 완료 존재 여부
  get_acc_result_token: '/acc/results/token', // 응시 분석 결과표 토큰 발급

  // 신역량검사 응시 분석 결과
  get_acc_apply_game: '/acc/apply/game', // 전략 게임 응시 결과
  get_acc_apply_result: '/acc/apply/result', // 종합 응시 결과
  get_acc_apply_self_report: '/acc/apply/self-report', // 성향 파악 응시 결과
  get_acc_apply_video: '/acc/apply/video', // 영상 면접 응시 결과

  // 개발자 센터
  get_phs_pacc_results_game: '/phs/pacc/results/game', // 전략 게임 응시 결과
  get_phs_pacc_results_self_report: '/phs/pacc/results/self-report', // 성향 파악 응시 결과
  get_phs_pacc_results_result: '/phs/pacc/results/result', // 종합 응시 결과
  get_phs_pacc_results_video: '/phs/pacc/results/video', // 영상 면접 응시 결과
  get_phs_pst_apply_max_apply_chance: '/phs/pst/apply/max-apply-chance', // 월 기본 최대 응시가능 횟수(비로그 시 표기)
  get_phs_pst_apply_status: '/phs/pst/apply/status', // 가장 최근 응시 상태
  get_phs_pacc_apply_max_apply_chance: '/phs/pacc/apply/max-apply-chance', // 월 기본 최대 응시가능 횟수(비로그 시 표기)
  get_phs_pacc_apply_status: '/phs/pacc/apply/status', // 가장 최근 응시 상태
  get_phs_center_position: 'phs/center/position', // 개발자 센터 포지션 목록 조회
  get_phs_center_skill: 'phs/center/skill', // 개발자 센터 기술 목록 조회
  put_phs_center_skill: 'phs/center/skill', // 개발자 센터 기술 목록 수정
  get_phs_center_skill_position: 'phs/center/skill/position', // 기술 스택 활용 포지션 조회
  get_phs_center_contents: 'phs/center/contents', // 디챔스 컨텐츠 목록 조회

  // 디챔스
  get_dchamps_grade: '/dchamps/v2/grade', // 디챔스 기간 내 최고 등급 조회
  get_dchamps_info: '/dchamps/v2/info', // 디챔스 정보 조회
  get_dchamps_results: '/dchamps/v2/results', // 디챔스 결과표 리스트 조회
  get_dchamps_results_token: '/dchamps/results/token', // 디챔스 결과표 url,토큰 발급
  get_dchamps_finals_user_status: 'dchamps/v2/finals/user/status', // 디챔스 본선 진출자 여부
  get_dchamps_finals_user_list: '/dchamps/v2/finals/user/list', // 디챔스 본선 진출자 명단 조회
  get_dchamps_apply_status: '/dchamps/v2/apply/status', // 디챔스 응시 상태 조회

  // 개능검 응시 분석 결과
  get_phs_pst_results: '/v2/phs/pst/results', // 응시 결과 조회
  // 매칭 메세지
  get_match_messages: (messageSn: number) => `/v2/match/messages/${messageSn}`, // 메세지 단건 조회
  get_match_messages_list: `/v2/match/messages`, // 매칭 메세지 리스트 조회

  // 매칭 면접 제안 수락 거절
  put_match_interview_accept: (positionSn: number) => `/match/application/${positionSn}/interview-accept`, // 면접 제안 수락
  put_match_interview_denied: (positionSn: number) => `/v2/match/application/${positionSn}/interview-denied`, // 면접 제안 거절
  put_match_interview_adjusted: (positionSn: number) => `/match/application/${positionSn}/interview-adjusted`, // 면접 제안 거절

  // 매칭 대시보드
  get_match_dashboard_application_positions: '/match/dashboard/application-positions', // 입사 지원탭 포지션 조회
  get_match_dashboard_apply_count: '/match/dashboard/apply-count', // 지원 현황 개수
  get_match_dashboard_complete_positions: '/match/dashboard/complete-positions', // 종료 탭 > 포지션 조회
  get_match_dashboard_offer_positions: '/match/dashboard/offer-positions', // 면접 제안 탭 > 포지션 조회
  get_match_dashboard_progress_positions: '/match/dashboard/progress-positions', // 전형 진행 탭 > 포지션 조회
  get_match_dashboard_reply_status: '/match/dashboard/reply-status', // 응답 현황 조회
  get_match_dashboard_status: '/match/dashboard/status', // 프로필 매칭 진행 상태 조회

  // MYPAGE 제휴서비스
  get_partnership: '/partnership', // 제휴 리스트 조회
  get_partnership_universities: '/partnership/universities', // 제휴 대학 리스트 조회
  get_partnership_universities_school_code: (schoolCode: number) => `/partnership/universities/${schoolCode}`, // 제휴 대학 여부 조회
  get_partnership_user_info: '/partnership/user/info', // 회원 제휴 인증현황 조회
  get_partnership_user_verified: '/partnership/user/verified', // 회원 제휴 인증 여부 조회
  post_partnership_user_register_organization: '/partnership/user/register/organization', // 제휴 기관 최종 인증
  post_partnership_user_register_universities: '/partnership/user/register/universities', // 제휴 대학 최종 인증
  put_partnership_user_universities: 'v2/partnership/user/universities', // 대학 인증 정보 업데이트

  // 이벤트 코드
  get_coupons_check: '/coupons/check', // 쿠폰 코드 확인
  post_coupons_register: '/coupons/register', // 쿠폰 등록
  get_coupons_registered: '/coupons/registered', // 등록한 쿠폰 목록 조회

  // MYPAGE 제휴서비스 약관
  get_partnership_terms: '/partnership/terms', // 약관 동의 생성
  // MYPAGE 제휴서비스 인증
  post_partnership_verification_organization_send: '/partnership/verification/organization/send', // 제휴 기관 인증 코드 인증
  post_partnership_verification_university_verify: '/partnership/verification/university/verify', // 제휴 기관 인증
  post_partnership_verification_university_send: '/partnership/verification/university/send', // 제휴 대학 이메일 인증코드 발송
  // MYPAGE 제휴서비스 인증 삭제
  delete_partnership_verification: '/partnership/user', // 제휴서비스 인증 삭제

  // 콘텐츠
  get_post_category_space: '/post/category/space',
  get_post_list: '/post',

  // 이벤트
  get_events_talent_pool_user_status: '/events/talent-pool/user-status', // 유저 상태 조회(인재풀 이벤트)
  post_events_talent_pool_profile_input_apply: 'events/talent-pool/profile-input/apply', // 프로필 입력 이벤트 응모(인재풀 이벤트)
  post_events_talent_pool_final_apply: 'events/talent-pool/final/apply', // 프로필/역량검사 완료 이벤트 응모(인재풀 이벤트)
  post_events_talent_pool_acc_test_apply: 'events/talent-pool/acc-test/apply', // 역량검사 응시 이벤트 응모(인재풀 이벤트)
  post_events_acc_mbti_apply: 'events/acc/mbti/apply', // 찐BTI 이벤트 응모하기
  get_events_talent_pool_acc_test_resume_download: 'events/talent-pool/acc-test/resume-download', // 역량검사 이력서 다운로드 이벤트 응모(인재풀 이벤트)

  get_events_talent_pool_holiday_2023_resume_download: 'events/talent-pool/holiday-2023/resume-download', // 2033년 휴일 이력서 다운로드 이벤트 응모(인재풀 이벤트)
  get_events_profile_complete_2024_profile_download: 'events/profile-complete-2024/profile-download', // 2024년 프로필 완성 이력서 다운로드 이벤트 응모(인재풀 이벤트)
  post_events_talent_pool_holiday_2023_apply: 'events/talent-pool/holiday-2023/apply', // 2033년 휴일 이벤트 응모(인재풀 이벤트)
  get_events_participation_status: '/events/participation-status', // 이벤트 참여 상태 조회
  post_events_profile_apply: '/events/profile/apply', // 프로필 이벤트 참여

  post_events_matching_apply: '/events/matching/apply', // 매칭 이벤트 응모하기
  // 채팅
  get_chat_token: 'chat/token', // 채팅 토큰 발급
  get_chat_company_channel_channel_id: (channelId: string) => `chat/company-channel/${channelId}`, // 채널ID로 기업 채널 Sn 조회
  get_chat_company_company_sn: (companySn: number) => `chat/company/${companySn}`, // 기업 sn으로 채널 id 조회
  get_chat_notifications: (channelId?: string) => (channelId ? `chat/notifications/${channelId}` : 'chat/notifications'), // channelId 있으면, 해당 채널 알림 설정 조회, 없으면 전체 채팅방 알림 설정 조회
  put_chat_notifications: (channelId: string) => `chat/notifications/${channelId}`, // 전체 채팅방 알림 설정 수정
  post_chat_channel_enter_log: (channelId: string) => `chat/channel/${channelId}/enter/log`, // 채팅방 입장 로그
  post_chat_report: (channelId: string, messageId: string) => `/chat/channel/${channelId}/messages/${messageId}/report`, // 채팅 신고

  // NHIS
  post_nhis_auth_request: '/nhis/auth/request', // 건보공 인증 요청
  post_nhis_auth_check: '/nhis/auth/check', // 건보공 인증 여부 확인
  get_nhis_v2_companies_report: '/nhis/v2/companies/report', // 건보공 및 급여 조회

  get_insight_resume_list: '/insight/resume/list', // 지원서 목록 조회
  get_insight_resume: (resumeSn: number) => `/insight/resume/${resumeSn}`, // 지원서 내용 조회
  post_insight_convert_profile: (resumeSn:number) => `/insight/resume/${resumeSn}/convert-profile`, // 지원서 -> 프로필 저장
  get_insight_resume_unread: '/insight/resume/unread', // 미열람 지원서 존재 여부 확인
  post_insight_resume_log: 'insight/resume/log', // 지원서 로그 저장
  get_insight_resume_auto_saved: '/insight/resume/auto-saved', // 자동저장된 지원서 조회
  get_insight_access_token: 'insight/access-token', // 지원서 엑세스 토큰
  // 합격 후기 게시판
  get_pass_review_posts: '/pass-review-posts', // 합격 후기 게시판 목록 조회
  post_pass_review_posts: '/pass-review-posts', // 합격 후기 게시판 글 작성
  get_pass_review_posts_postSn: (postSn: number) => `/pass-review-posts/${postSn}`, // 합격 후기 게시판 글 조회
  put_pass_review_posts_postSn: (postSn: number) => `/pass-review-posts/${postSn}`, // 합격 후기 게시판 글 수정
  delete_pass_review_posts_postSn: (postSn: number) => `/pass-review-posts/${postSn}`, // 합격 후기 게시판 글 삭제
  get_pass_review_posts_questions: '/pass-review-posts/questions', // 합격 후기 게시판 질문 목록 조회
  get_pass_review_posts_banners: '/pass-review-posts/banners', // 합격 후기 게시판 배너 목록 조회
  get_pass_review_posts_comments: (sn:number) => `/pass-review-posts/${sn}/comments`, // 합격 후기 게시판 댓글 목록 조회
  put_pass_review_posts_comments: (sn:number, commentSn:number) => `/pass-review-posts/${sn}/comments/${commentSn}`, // 합격 후기 게시판 댓글 작성
  delete_pass_review_posts_comments: (sn:number, commentSn:number) => `/pass-review-posts/${sn}/comments/${commentSn}`, // 합격 후기 게시판 댓글 삭제
  post_pass_review_posts_comments: (sn:number) => `/pass-review-posts/${sn}/comments`, // 합격 후기 게시판 댓글 수정
  // 로그
  post_v1_logs: '/v1/log', // 로그 저장
  post_v2_notification_logs_send_push: '/v2/log/notification/send/push', // 푸시 알림 발송 로그 저장 - 현재는 입사제안 알림만 가능한 코드
  post_v2_notification_logs_view: '/v2/log/notification/view', // 알림 조회 로그 저장 - 현재는 입사제안 알림만 가능한 코드
  post_v2_log_matching_report_view: '/v2/log/matching-report/view', // 매칭 리포트 조회 로그 저장,

  // 면접 연습
  get_interview_practices_questions_practice_question_sn_results: (practiceQuestionSn: number) => `/v2/interview-practices/questions/${practiceQuestionSn}/results`, // 면접 연습 문제 결과 조회
  get_interview_practices_results: '/v2/interview-practices/results', // 면접 연습 결과 조회
  get_interview_practices_practice_questions_sn_answer_videos: (practiceQuestionSn: number) => `/interview-practices/questions/${practiceQuestionSn}/answer-videos`, // 면접 연습 문제 답변 동영상 조회
};

export const RequestUrlOption = {
  getLoadAdsMainBanners: { method: 'get', url: URIs.get_ads_main_banners },
  getLoadMainMatchingCompany: { method: 'get', url: URIs.get_main_matching_company },
};

export default URIs;
