/* eslint-disable no-unused-vars */
export enum ProfileType {
  GENERAL = 'GENERAL',
  DEVELOPER = 'DEVELOPER',
}

export enum ProfileItemType {
  BASIC = 'BASIC',
  EDUCATION = 'EDUCATION',
  CAREER = 'CAREER',
  PROJECT = 'PROJECT',
  KNOWLEDGE_AND_SKILL = 'KNOWLEDGE_AND_SKILL',
  EXPERIENCE = 'EXPERIENCE',
  PRIZE = 'PRIZE',
  PREFERENTIAL = 'PREFERENTIAL',
  FILE = 'FILE',
  ADDITIONAL = 'ADDITIONAL',
}

export const ProfileItemTypeText: Record<string, string> = {
  BASIC: '기본 정보',
  EDUCATION: '학력',
  CAREER: '경력',
  PROJECT: '프로젝트',
  KNOWLEDGE_AND_SKILL: '지식 · 기술',
  EXPERIENCE: '경험',
  PRIZE: '수상 내역',
  PREFERENTIAL: '병역 · 취업 우대',
  FILE: '첨부 자료',
  ADDITIONAL: '추가정보',
};

export enum PositionItemType {
  NAME = 'NAME',
  BIRTH_DATE = 'BIRTH_DATE',
  MOBILE = 'MOBILE',
  EMAIL = 'EMAIL',
  PROFILE_IMAGE = 'PROFILE_IMAGE',
  MILITARY = 'MILITARY',
  DISABILITY = 'DISABILITY',
  VETERAN = 'VETERAN',

  // 학력
  EDUCATION = 'EDUCATION',
  EDUCATION_SCORE = 'EDUCATION_SCORE', // 학점
  RESEARCH = 'RESEARCH', // 연구 논문

  // 첨부자료
  PORTFOLIO = 'PORTFOLIO',
  CAREER_ATTACH_FILE = 'CAREER_ATTACH_FILE', // 경력 기술서
  URL = 'URL',

  // 경력
  CAREER = 'CAREER',
  PERFORMANCE = 'PERFORMANCE',

  // 프로젝트
  PROJECT = 'PROJECT',

  // 경험
  ACTIVITY = 'ACTIVITY',
  FOREIGN_EXPERIENCE = 'FOREIGN_EXPERIENCE',
  EXPERIENCE = 'EXPERIENCE',

  // 수상
  PRIZE = 'PRIZE',

  // 경험/지식,기술
  LICENSE = 'LICENSE',
  LANGUAGE = 'LANGUAGE',
  SKILL = 'SKILL',
}

export enum PositionItemTypeText {
  NAME = '이름',
  BIRTH_DATE = '생년월일',
  MOBILE = '휴대폰',
  EMAIL = '이메일',
  PROFILE_IMAGE = '사진',
  MILITARY = '병역',
  DISABILITY = '장애',
  VETERAN = '보훈',

  // 학력
  EDUCATION = '학력',
  EDUCATION_SCORE = '학점', // 학점
  RESEARCH = '연구 분야 · 논문', // 연구 논문

  // 첨부자료
  PORTFOLIO = '포트폴리오',
  CAREER_ATTACH_FILE = '경력 기술서', // 경력 기술서
  URL = 'URL',

  // 경력
  CAREER = '경력',
  PERFORMANCE = '주요 성과',

  // 프로젝트
  PROJECT = '프로젝트',

  // 경험
  ACTIVITY = '대내외 활동',
  FOREIGN_EXPERIENCE = '해외 경험',
  EXPERIENCE = '기타 경험',

  // 수상
  PRIZE = '수상 내역',

  // 경험/지식,기술
  LICENSE = '자격증',
  LANGUAGE = '공인 어학시험',
  SKILL = '기술 키워드',
}

export enum ResumeFormType {
  TEXT = 'TEXT',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTI_CHOICE = 'MULTI_CHOICE',
  DROPDOWN = 'DROPDOWN',
  ADDITION_ATTACH_FILE = 'ADDITION_ATTACH_FILE',
}

export enum ConditionRegisterType {
  LIST = 'LIST',
  JOBGROUP = 'JOBGROUP',
  LOCATION = 'LOCATION',
  SALARY = 'SALARY',
  WELFARE = 'WELFARE',
  WORKING_PREFERENCE = 'WORKING_PREFERENCE',
}

export enum ProfileAuditItemType {
  PROFILE_JOB_TITLE = 'PROFILE_JOB_TITLE', // 직군
  PROFILE_LOCATION = 'PROFILE_LOCATION', // 근무지역
  MATCHING_CONDITION_SALARY = 'MATCHING_CONDITION_SALARY', // 연봉
  PROFILE_EDUCATION_START_DATE = 'PROFILE_EDUCATION_START_DATE', // 학력 시작일
  PROFILE_EDUCATION_END_DATE = 'PROFILE_EDUCATION_END_DATE', // 학력 종료일
  PROFILE_EDUCATION_MAJOR = 'PROFILE_EDUCATION_MAJOR', // 학과
  PROFILE_CAREER_START_DATE = 'PROFILE_CAREER_START_DATE', // 경력 시작일
  PROFILE_CAREER_END_DATE = 'PROFILE_CAREER_END_DATE', // 경력 종료일
  PROFILE_CAREER_EMPLOYMENT_STATUS = 'PROFILE_CAREER_EMPLOYMENT_STATUS', // 경력 재직 상태
  PROFILE_CAREER_JOB_TITLE = 'PROFILE_CAREER_JOB_TITLE', // 경력 직군
  PROFILE_CAREER_EMPLOYMENT_TYPE = 'PROFILE_CAREER_EMPLOYMENT_TYPE', // 경력 고용형태
  PROFILE_PROJECT_START_DATE = 'PROFILE_PROJECT_START_DATE', // 프로젝트 시작일
  PROFILE_PROJECT_END_DATE = 'PROFILE_PROJECT_END_DATE', // 프로젝트 종료일
  PROFILE_ACTIVITY_START_DATE = 'PROFILE_ACTIVITY_START_DATE',
  PROFILE_ACTIVITY_END_DATE = 'PROFILE_ACTIVITY_END_DATE',
  PROFILE_FOREIGN_EXPERIENCE_START_DATE = 'PROFILE_FOREIGN_EXPERIENCE_START_DATE',
  PROFILE_FOREIGN_EXPERIENCE_END_DATE = 'PROFILE_FOREIGN_EXPERIENCE_END_DATE',
  PROFILE_PRIZE_DATE = 'PROFILE_PRIZE_DATE', // 수상일
}
