import IComponentProps from 'interfaces/props/IComponentProps';
import imgNoJoy from 'assets/_v2/alarm/icon_joy_no_alarm.svg';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import Icon from '__designkit__/icon/Icon';
import AccResultSampleModal from '../acc/AccResultSampleModal';

const Frame = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding-bottom: ${SpaceValue.XXXL}px;

  .close-btn {
    position: absolute;
    top: ${SpaceValue.L}px;
    right: ${SpaceValue.L}px;
  }
`;

const ContentsFrame = styled.div`
  width: 100%;
  padding: ${SpaceValue.L}px;

  .desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      margin-bottom: ${SpaceValue.L}px;
      padding-top: ${SpaceValue.XXL}px;
      width: 96px;
      height: 96px;
    }

    > :last-child {
      margin: ${SpaceValue.XS}px 0 ${SpaceValue.XL}px 0;
    }
  }
`;

interface IMatchApplyNoResultModalProps extends IComponentProps {
  onClickClose?: () => void;
}

const MatchApplyNoResultModal: FC<IMatchApplyNoResultModalProps> = ({ onClickClose = () => {} }) => {
  const history = useHistory();
  const [isOpenAccResultSampleModal, setIsOpenAccResultSampleModal] = useState(false);

  return (
    <Frame>
      <div className='close-btn' role='button' onClick={onClickClose}>
        <Icon name='close' size={32} />
      </div>
      <ContentsFrame>
        <V2Text
          className='title'
          element={V2TextOption.element.span}
          fontStyle={V2TextOption.fontStyle.title_3_b}
          color={V2TextOption.color.default}
          ellipsis
        >
          역량검사 결과표
        </V2Text>
        <div className='desc'>
          <img src={imgNoJoy} alt='no-joy' />
          <V2Text
            element={V2TextOption.element.span}
            fontStyle={V2TextOption.fontStyle.body_1_b}
            color={V2TextOption.color.subtle}
          >
            아직 역량검사 응시 전이에요.
          </V2Text>
          <V2Text
            element={V2TextOption.element.span}
            fontStyle={V2TextOption.fontStyle.body_1_sb}
            color={V2TextOption.color.subtlest}
          >
            정확도 높은 역량 측정을 위해 PC에서<br />응시를 권장하고 있어요
          </V2Text>
        </div>
      </ContentsFrame>
      <V2Button
        type={V2ButtonOption.type.Fill}
        size={V2ButtonOption.size.M}
        aProps={{ onClick: () => setIsOpenAccResultSampleModal(true) }}
        fill={V2ButtonOption.fillType.brand_strong_default}
        fontStyle={V2ButtonOption.fontStyle.body_1_sb}
        color={V2ButtonOption.color.inverse}
      >
        결과표 예시 보기
      </V2Button>
      <AccResultSampleModal isOpen={isOpenAccResultSampleModal} onClickClose={() => setIsOpenAccResultSampleModal(false)} />
    </Frame>
  );
};

export default MatchApplyNoResultModal;
