import React, { FC, useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import Portal from 'components/common/Portal';
import Icon from '__designkit__/icon/Icon';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import Shadows from '__designkit__/common/shadows';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { keyFrameFadeIn, keyFrameFadeOut } from 'consts/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import useToast from 'hooks/useToast';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';

export const TOAST_TYPE = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  INFO: 'INFO',
} as const;

type ToastType = typeof TOAST_TYPE[keyof typeof TOAST_TYPE];

const Wrapper = styled.div<{ position: 'middle' | 'bottom', bottom?: number }>`
  position: fixed;
  bottom: ${({ position, bottom }) => (bottom ? `${bottom}px` : position === 'bottom' ? '32px' : '50%')};
  transform: ${({ position }) => (position === 'bottom' ? 'none' : 'translateY(50%)')};
  width: 100%;
  padding: 0 32px;
  height: fit-content;
  z-index: 100000;
`;

const ToastFrame = styled.div<{ type: ToastType, justSubMessage: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10), 0px 2px 6px 0px rgba(0, 0, 0, 0.30);
  background: ${({ type }) => (type === 'SUCCESS' ? '#02C551' : type === 'ERROR' ? Colors.ERROR : '#838486')};
  color: ${Colors.WHITE_100};
  filter: ${Shadows.Shadow_200};
  opacity: 0;
  animation: fadein 500ms forwards;

  &.fadeout {
    animation: fadeout 500ms forwards;
  }
  
  & > div {
    display: flex;
    align-items: center;

    & > div:first-child {
      margin-top: ${({ justSubMessage }) => (justSubMessage ? 1 : 0)}px;
    }

    & > div.frame-message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      font: ${Fonts.B3_Bold};
      white-space: pre-line;

      span {
        font: ${({ justSubMessage }) => (justSubMessage ? Fonts.B3_Medium : Fonts.B4_Medium)};
        margin-top: 2px;
      }
    }
  }

  ${keyFrameFadeIn()};
  ${keyFrameFadeOut()};
`;

export interface IJDToastProps extends IComponentProps {
  isOpen: boolean;
  type: ToastType;
  message: string;
  subMessage?: string;
  duration?: number;
  position?: 'middle' | 'bottom';
  closeable?: boolean;
  justSubMessage?: boolean;
  bottom?: number;
  isIcon?:boolean;
  onDidDismiss?: () => void;
}

const JDToast:FC<IJDToastProps> = ({ isIcon = true, isOpen = false, type = 'INFO', message = '', subMessage = '', duration = 2000, position = 'bottom', closeable = false, justSubMessage = false, bottom = undefined, onDidDismiss = undefined }) => {
  const [open, setOpen] = useState<boolean>(isOpen);
  const [fadeout, setFadeout] = useState<boolean>(false);
  const refTimer = useRef<number | null>(null);
  const { setToastObject } = useToast();

  const onClose = useCallback(() => {
    setOpen(false);
    setFadeout(false);
    setToastObject({ isOpen: false, type: 'INFO', message: '', subMessage: '', duration: 2000, position: 'bottom', closeable: false, justSubMessage: false, bottom: undefined, onDidDismiss: undefined });
    if (onDidDismiss) onDidDismiss();
  }, [onDidDismiss, setToastObject]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (fadeout) {
      window.setTimeout(() => {
        onClose();
      }, 500);
    }
  }, [fadeout, onClose]);

  useEffect(() => {
    if (open && !closeable) {
      refTimer.current = window.setTimeout(() => {
        setFadeout(true);
      }, duration);
    }
    return () => {
      if (refTimer.current !== null) {
        window.clearTimeout(refTimer.current);
        refTimer.current = null;
      }
    };
  }, [open, closeable, duration, onClose]);

  return !open ? <></> : (
    <Portal>
      <Wrapper position={position} bottom={bottom}>
        <ToastFrame className={`${fadeout ? 'fadeout' : ''}`} type={type} justSubMessage={justSubMessage}>
          <div>
            {isIcon && <V2Icon name={type === 'SUCCESS' ? V2IconOption.name.checkCircleFilled : type === 'ERROR' ? V2IconOption.name.errorFilled : V2IconOption.name.circleInfo} size={V2IconOption.size.S} fill={V2IconOption.fill.inverse} />}
            <div className='frame-message'>
              { !justSubMessage && <V2Text fontStyle={V2TextOption.fontStyle.body_2_m} color={V2TextOption.color.inverse}>{message}</V2Text> }
              { subMessage && <span>{ subMessage }</span> }
            </div>
          </div>
          { closeable && <Icon name='close' size={16} color={Colors.WHITE_100} onClick={() => setFadeout(true)} /> }
        </ToastFrame>
      </Wrapper>
    </Portal>
  );
};

export default JDToast;
