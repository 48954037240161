import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import IconDumy from 'assets/_v2/profile/img_profile_base_info_dumy.png';
import RoutePaths from 'consts/RoutePaths';
import { IUser } from 'interfaces/_v2/profile/IProfileDetail';
import React, { FC } from 'react';
import styled from 'styled-components';

const FullFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap:24px; 
  margin-bottom: 32px;
`;
const Frame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .country-code {
    margin-right: 2px;
  }
`;

const LeftFrame = styled.div`
  & > div.user-name {
    display: flex;
    align-items: center;
    font: ${Fonts.H2_Bold};

    & > span.career-type {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      height: fit-content;
      padding: 5px 8px;
      margin-left: 8px;
      font: ${Fonts.B3_Medium};
      color: ${Colors.WHITE_100};
      background-color: ${Colors.CG_60};
      border-radius: 4px;
    }
  }

  & > p {
    font: ${Fonts.B2_Medium};
    color:${Colors.JOBDA_BLACK};  
  }

  & > p.birth {
    font: ${Fonts.B1_Medium};
  }

  & > div.divid-line {
    width: 28px;
    height: 1px;
    
    background-color: ${Colors.JOBDA_BLACK};
  }
`;

const RightFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  & > img {
    width: 96px;
    height: 96px;
    border-radius: 96px;
    object-fit: cover;
  }
`;

const ProfilePreviewBasic: FC<{ data: IUser}> = ({ data }) => {
  const { profileImage, name, birthDate, mobile, email, countryCode } = data;

  return (
    <FullFrame>
      <Frame>
        <LeftFrame>
          <div className='user-name'>
            <span>{name}</span>
          </div>
          <SpacingBlock size={4} vertical />
          <p className='birth'>{birthDate}</p>
          <SpacingBlock size={25} vertical />
          <div className='divid-line' />
          <SpacingBlock size={16} vertical />
          {mobile && <p><span className='country-code'>+{countryCode}</span> {mobile.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)}</p>}
          <SpacingBlock size={6} vertical />
          <p>{email}</p>
        </LeftFrame>
        <RightFrame>
          <img
            className='img-profile'
            src={(profileImage && profileImage.fileUrl) || IconDumy}
            alt='프로필 사진'
            onError={(e) => {
              e.currentTarget.src = IconDumy;
            }}
          />
        </RightFrame>

      </Frame>
      <V2Button
        size={V2ButtonOption.size.M}
        type={V2ButtonOption.type.OutLine}
        fontStyle={V2ButtonOption.fontStyle.body_1_sb}
        color={V2ButtonOption.color.subtle}
        aProps={{ href: RoutePaths.profile }}
      >
        프로필 수정
      </V2Button>
    </FullFrame>
  );
};

export default ProfilePreviewBasic;
