/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/media-has-caption */
import URIs from 'consts/URIs';
import { IAttachFile } from 'interfaces/_v2/profile/IProfileEducation';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import request from 'utils/request';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { ReactComponent as IconSpeaker } from './icon_speaker_light.svg';
import { ReactComponent as IconMute } from './icon_volume_mute_light.svg';
import IconPlay from './iconPlay.png';
import IconPaused from './iconPaused.png';

const MediaReviewWrapper = styled.div`
  position: relative;
  padding-top: 64px;
  width: 100%;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;

  .videoContainer {
    position: relative;
    width: 100%;
    max-width: 800px; /* 가로 비율 고정 */
    aspect-ratio: 16 / 9; /* 16:9 비율 */

  .video {
    width: 100%;
    height: 100%;
    pointer-events: none;
    object-fit: contain; /* 비율 유지 */
    transform: scaleX(-1);
    object-fit: cover;
  }

  .progressContainer {
    position: absolute;
    bottom: 32px;
    width: 100%;
    height: 4px;
    background: #9fa4ab;

    .progress {
      width: 0;
      height: 100%;
      background: #6cdd83;
    }

    .previewArea {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: -80px;
      color: #fff;

      .preview {
        border: 2px solid #fff;
        border-radius: 5px;
        transform: scaleX(-1);
      }
    }
  }

  .controllBar {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    height: 32px;
    padding: 12px 16px;
    background: var(--DIMMED, rgba(0, 0, 0, 0.6));

    .playSetting {
      .icon {
        width: 20px;
        height: 20px;

      }
    }

    .volumeArea {
      display: flex;
      align-items: center;
      .icon {
        width: 25px;
        height: 25px;
        color: #fff;
      }
      .volume {
        -webkit-appearance: none;

        width: 50px;
        opacity: 1;
        height: 3px;
        background: #fff;
        outline: none;
        cursor: pointer;
        color:#fff;
        &::-webkit-slider-thumb {
            -webkit-appearance: none;

            color:#fff;
          appearance: none;
          width: 15px;
          height: 15px;
          border-radius: 15px;
          background: #fff;
        }
      }
    }

    .playTime {
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.0866667px;
      color: #fff;
      pointer-events: none;
    }
  }
}
.desc-frame{
  display: flex;
  padding: ${SpaceValue.XXL}px ${SpaceValue.L}px;
  flex-direction: column;
}
.action-buttons {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    left: 0;
    width: 100%;
    padding: ${SpaceValue.L}px ${SpaceValue.L}px ${SpaceValue.XL}px ${SpaceValue.L}px;
    background-color: white;
    display: flex;
    gap:8px;
    justify-content: space-between;
  }
`;
interface IVideoRecordCheck{
    mediaBlobUrl: string | null;
    setMediaBlobUrl: React.Dispatch<React.SetStateAction<string | null>>;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    videoBlob: Blob | null;
    setVideoBlob: React.Dispatch<React.SetStateAction<Blob | null>>;
}
const VideoRecordCheck: React.FC<IVideoRecordCheck> = ({ setCurrentStep, videoBlob, setVideoBlob, setMediaBlobUrl }) => {
  const retryRecording = () => {
    setMediaBlobUrl(null);
    setVideoBlob(null);
    setCurrentStep(6);
  };

  const uploadVideo = async () => {
    if (!videoBlob) {
      alert('No video to upload!');
      return;
    }

    // Blob을 File 형식으로 변환
    const videoFile = new File([videoBlob], `recorded-video.mp4`, { type: videoBlob.type });

    const formData = new FormData();
    formData.append('file', videoFile);

    try {
      // API 요청
      const response = await request<IAttachFile>({
        method: 'post',
        url: URIs.post_v1_match_applicants_video,
        data: formData,
      });

      if (response.fileUid) {
        console.log('Video uploaded:', response.fileUid);
        setCurrentStep(8);
      } else {
        console.error('Upload failed:', response);
        alert('An error occurred while uploading the video.');
      }
    } catch (error) {
      console.error('Upload error:', error);
      alert('An error occurred while uploading the video.');
    }
  };
  const videoRef = useRef<HTMLVideoElement>(null);
  const frameId = useRef<number>(0);
  const prevVolumeValue = useRef<number>(0.5);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0); // 동영상 전체 시간
  const [videoWidth, setVideoWidth] = useState(0); // 비디오 요소의 너비
  const [volume, setVolume] = useState(0.5);

  // 재생 버튼 클릭 함수
  const handleClickPlayPaused = async () => {
    if (!videoRef.current) return;
    if (!playing) {
      setPlaying(true);
      videoRef.current.play();
    } else {
      setPlaying(false);
      videoRef.current.pause();
    }
  };

  // 재생된 시간 width
  const playTimeBarWidth = (() => {
    const percent = (currentTime / duration) * videoWidth;
    if (!isNaN(percent) && Number.isFinite(percent)) {
      return percent;
    }

    return 0;
  })();

  // 시간 변환 함수
  const secondsToMinSec = (sec: number): string => {
    const zeroFiled = (value: number) => (value < 10 ? `0${value}` : value);
    const tempSeconds = sec % 60;
    const minute = Math.floor(sec / 60);
    const plusSeconds = sec - 60 * minute - tempSeconds > 0.5 ? 1 : 0;
    const sum = plusSeconds + tempSeconds + minute * 60;

    return `${zeroFiled(Math.floor(sum / 60))}:${zeroFiled(sum % 60)}`;
  };

  // progressBar Click시 video 재생시간 업데이트
  const updateCurrentTime = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (videoRef.current) {
      const rect = e.currentTarget.getBoundingClientRect();
      const percent = (e.clientX - rect.left) / rect.width;
      videoRef.current.currentTime = percent * duration;
    }
  };

  // Volume Change
  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const newVolume = Number(e.target.value);
    setVolume(newVolume);
    if (videoRef.current) videoRef.current.volume = newVolume;
    prevVolumeValue.current = newVolume;
  };

  // Volume Icon Click
  const handleVolumeOnOff = () => {
    if (!videoRef.current) return;

    if (!volume) {
      const volume = prevVolumeValue.current ? prevVolumeValue.current : 0.5;
      videoRef.current.volume = volume;
      setVolume(prevVolumeValue.current ? prevVolumeValue.current : 0.5);
    } else {
      // 이전 volume값 저장
      prevVolumeValue.current = volume;
      videoRef.current.volume = 0;
      setVolume(0);
    }
  };

  // 동영상 전체시간 구하는 함수
  const addTimeUpdateHandler = async () => {
    const videoElement = document.createElement('video');
    if (typeof videoBlob === 'string') {
      videoElement.src = videoBlob;
    } else if (videoBlob !== null) videoElement.src = URL.createObjectURL(videoBlob as MediaSource | Blob);
    if (videoElement) {
      const handleLoaded = () => {
        // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
        if (videoElement.duration === Infinity) {
          videoElement.currentTime = Number.MAX_SAFE_INTEGER; // video 재생 헤드를 최대로 이동시킨다. 더 많은 비디오를 로드하여 지속 시간을 계산할 수 있도록 한다.
          videoElement.ontimeupdate = () => {
            videoElement.ontimeupdate = null; // 반복 실행을 방지하기 위해 이벤트 핸들러를 제거한다.
            setDuration(videoElement.duration);
            // video 메타데이터 로드 시
            videoElement.removeEventListener('loadedmetadata', handleLoaded);
          };
        } else {
          videoElement.removeEventListener('loadedmetadata', handleLoaded);
          setDuration(videoElement.duration);
        }
      };
      videoElement.addEventListener('loadedmetadata', handleLoaded);
    }
  };

  // video 현재 재생시간 업데이트 함수
  const timeUpdate = () => {
    if (frameId.current) cancelAnimationFrame(frameId.current);
    setCurrentTime(videoRef.current?.currentTime ?? 0);

    if (playing) frameId.current = requestAnimationFrame(timeUpdate);
    else frameId.current && cancelAnimationFrame(frameId.current);
  };

  // currentTime 업데이트
  useEffect(() => {
    if (!videoRef.current) return;
    videoRef.current.addEventListener('timeupdate', timeUpdate);
    setVideoWidth(videoRef.current.clientWidth);
    const handleResize = () => {
      if (videoRef.current) {
        setVideoWidth(videoRef.current.clientWidth);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      if (!videoRef.current) return;
      window.removeEventListener('resize', handleResize);
      videoRef.current.removeEventListener('timeupdate', timeUpdate);
    };
  }, [currentTime, videoRef.current, playing]);

  // videoRef 할당
  useEffect(() => {
    addTimeUpdateHandler();
    if (!videoRef.current || !videoBlob) return;

    if (typeof videoBlob === 'string') videoRef.current.src = videoBlob;
    else videoRef.current.src = URL.createObjectURL(videoBlob as MediaSource | Blob);
  }, [videoBlob]);

  if (videoBlob) {
    return (
      <MediaReviewWrapper>
        <div className='videoContainer' role='button' onClick={handleClickPlayPaused}>
          <video
            ref={videoRef}
            className='video'
            onEnded={() => setPlaying(false)}
            controls={false}
            preload='metadata'
          />

          <div className='progressContainer' role='button' onClick={updateCurrentTime}>
            <div className='progress' style={{ width: playTimeBarWidth }} />
          </div>

          <div className='controllBar'>
            <button className='playSetting' onClick={handleClickPlayPaused}>
              {
                    !playing
                      ? <img className='icon' src={IconPlay} alt='play' width={40} height={40} />
                      : <img className='icon' src={IconPaused} alt='paused' width={40} height={40} />
                }
            </button>
            <div
              className='volumeArea'
            >
              <button onClick={handleVolumeOnOff}>
                {volume ? (
                  <IconSpeaker className='icon' fill='white' />
                ) : (
                  <IconMute className='icon' fill='white' />
                )}
              </button>
              <input
                className='volume'
                type='range'
                min={0}
                max={1}
                step={0.01}
                value={volume}
                onChange={handleVolumeChange}
              />

            </div>
            <div className='playTime'>{`${secondsToMinSec(Math.floor(currentTime))} / 
          ${secondsToMinSec(Math.floor(duration))}`}
            </div>
          </div>
        </div>
        <div className='desc-frame'>
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_3_sb}
            color={V2TextOption.color.brand}
          >영상 가이드
          </V2Text>
          <SpacingBlock vertical size={4} />
          <V2Text
            fontStyle={V2TextOption.fontStyle.title_1_b}
            color={V2TextOption.color.default}
          >영상 확인
          </V2Text>
          <SpacingBlock vertical size={12} />

          <V2Text
            fontStyle={V2TextOption.fontStyle.body_1_m}
            color={V2TextOption.color.subtle}
          >영상 어쩌구 확인하고 어쩌구 다시 찍고 싶으면 재 촬영하세여 자기소개 영상은 실제 채용담당자가 보고 채용에 대한 의사결정을 진행하는 영상입니다. 용모와 답변태도는 아래 내용을 참고하시어, 점검 해주시길 바랍니다.
          </V2Text>
        </div>
        <div className='action-buttons'>
          <V2Button
            type={V2ButtonOption.type.OutLine}
            size={V2ButtonOption.size.L}
            buttonProps={{ onClick: retryRecording }}
            fill={V2ButtonOption.fillType.neutral_subtle_default}
            fontStyle={V2ButtonOption.fontStyle.body_1_sb}
            color={V2ButtonOption.color.subtle}
            styles={{ width: '100%' }}
          >
            재촬영
          </V2Button>
          <V2Button
            type={V2ButtonOption.type.Fill}
            size={V2ButtonOption.size.L}
            buttonProps={{ onClick: uploadVideo }}
            fill={V2ButtonOption.fillType.brand_strong_default}
            fontStyle={V2ButtonOption.fontStyle.body_1_sb}
            color={V2ButtonOption.color.inverse}
            styles={{ width: '100%' }}
          >
            다음
          </V2Button>
        </div>
      </MediaReviewWrapper>
    );
  }
  return (<></>);
};

export default VideoRecordCheck;
