import VideoRecordStep from 'components/_v2/videoRecord/VideoRecordStep';
import styled from 'styled-components';

const VideoRecordWrap = styled.div`
`;

const VideoRecord = () => {
  return (
    <VideoRecordWrap>
      <VideoRecordStep />
    </VideoRecordWrap>
  );
};

export default VideoRecord;
