import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { V2BackgroundColors, V2BackgroundColorsType } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { IconLogo } from 'consts/assets/icons/iconPages';
import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useUserInfo } from 'query/user/useUserInfoQuery';
import { useMatchHomeMatchingTutorialComplete } from 'query/match/useMatchQuery';
import useToast from 'hooks/useToast';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import { getCreatedDayTime } from 'utils/DateUtils';
import MainTutorialIndicator from './MainTutorialIndicator';

const dimOpacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
`;
const Dim = styled.div<{backgroundColor:V2BackgroundColorsType}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => V2BackgroundColors[props.backgroundColor]};
  opacity: 0.6;
  animation: ${dimOpacityAnimation} 0.3s ease-out;
  animation-duration: 0.4s;
  z-index: 10005;
`;

const Frame = styled.div`
    display: flex;
    flex-direction: column;

`;
const TitleFrame = styled.div`
    display: flex;
    align-items: center;
    gap:8px;
    .jda-icon{
        display: flex;
        width: 24px;
        height: 24px;
        padding: 0 3px;
        justify-content: center;
        align-items: center;
        border: 0.5px solid ${V2BorderColors.default};
        border-radius: 24px;
    }
    .name-text{
        font: ${Fonts.B2_Bold};
        color: ${Colors.CG_90};
    }
    .time-text{
        font: ${Fonts.B3_Medium};
        color: ${Colors.cA4A4A4};
    }
`;

const MessageFrame = styled.div<{step?:number}>`
    display: flex;
    flex-direction: column;
    padding:24px;
    width: 100%;
    border-radius: 2px 12px 12px 12px;
    background-color: ${Colors.CG_30};
    z-index:${(props) => (props.step === 6 ? 10006 : 1)} ;
  position:relative;
    .title-text{
        font :${Fonts.H5_Bold};
        color:${Colors.JOBDA_BLACK};
    }
    .content-text{
        font:${Fonts.B2_Medium_P};
        color:${Colors.CG_70};
    }
`;
interface IMainTutorialCompanyMessagePassed{
  setStep : React.Dispatch<React.SetStateAction<number>>;
  step:number;
  onClose : () => void;
}

const MainTutorialCompanyMessagePassed:FC<IMainTutorialCompanyMessagePassed> = ({ setStep, step, onClose }) => {
  const { data: userInfoData } = useUserInfo();
  const [backgroundColor, setBackgroundColor] = useState<V2BackgroundColorsType>(V2BackgroundColorsType.transparent);
  const [isIndicatorVisible, setIsIndicatorVisible] = useState<boolean>(false);
  const { setToastObject } = useToast();

  const saveSuccess = async () => {
    setStep(0);
    onClose();
  };
  const setError = async () => {
    setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '문제가 발생했습니다. 다시 시도해 주세요.' });
    setStep(0);
    onClose();
  };
  const { mutate: completeTutorial } = useMatchHomeMatchingTutorialComplete(saveSuccess, setError);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (step === 6) {
        setBackgroundColor(V2BackgroundColorsType.dimmer); // 배경색을 회색으로 설정
        setIsIndicatorVisible(true);
      }
    }, 500);

    return () => clearTimeout(timer); // 컴포넌트가 언마운트되면 타이머를 정리
  }, []);

  const handleNextClick = () => {
    completeTutorial();
  };
  const today = new Date();
  const todayISOString = today.toISOString();
  return (
    <>
      <Dim backgroundColor={backgroundColor} />

      <Frame>
        <TitleFrame>
          <div className='jda-icon'>
            <IconLogo className='logo' fill={Colors.JOBDA_BLACK} />
          </div>
          <div className='name-text'>잡다매칭 담당자</div>
          <div className='time-text'>{getCreatedDayTime(todayISOString)}</div>
        </TitleFrame>
        <SpacingBlock size={8} vertical />
        <MainTutorialIndicator
          titleText='튜토리얼 완료'
          descText='매칭 제안에 대한 모든 프로세스를 경험하셨습니다!
                    수락 이후 과정은 기업과 직접 진행되며
                    지원현황, 매칭현황 페이지에서 확인 가능합니다.'
          stepText='06'
          buttonText='완료'
          position='top'
          onClickNext={handleNextClick}
          visible={isIndicatorVisible}
        />
        <MessageFrame step={step}>
          <div className='title-text'>[잡다매칭] 매칭 튜토리얼 직무 채용
            - 채용결과 안내
          </div>
          <SpacingBlock size={16} vertical />
          <div className='content-text'>
            {
              `
              매칭 튜토리얼 포지션의 최종 합격을 축하드립니다,
            ${userInfoData?.name}님! 함께 성장할 수 있는 기회를 가지게 되어 기쁩니다.
              `
            }

          </div>
        </MessageFrame>

      </Frame>
    </>
  );
};
export default MainTutorialCompanyMessagePassed;
