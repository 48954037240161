import colors from '__designkit__/common/colors';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import { absoluteHorizonCenter, dimmer } from 'consts/style/mixins';
import { FC, useCallback, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useHistory } from 'react-router';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';

const StyledContent = styled.div<{nudge?:boolean, maxWidth?: string }>`
  background: ${colors.WHITE_100};
  position: absolute;
  width: 100%;
  max-width: ${(props) => props.maxWidth || 'none'};
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: env(safe-area-inset-top);
  border-radius: 12px 12px 0px 0px;
  padding:0px;
  padding-bottom: ${(props) => (props.nudge ? `0` : `calc(8px + env(safe-area-inset-bottom))`)}; 

  
  .drag-area {
    display: flex;
    padding:${SpaceValue.L}px 0px;
    .drag-bar{
      ${absoluteHorizonCenter()}
    width: 80px;
    height: 4px;
    border-radius: 2px;
    background: ${V2BackgroundColors.accent_gray_subtle};
    }
    
  }

`;

const JDUnderBaseModalDimFrame = styled.div`
  position: relative;
  ${dimmer()};
  z-index: 10005;
  opacity: 1;
  transition: opacity 0.25s;
  transition-delay: 0.25s;
  pointer-events: none;
  
  &.active {
    pointer-events: inherit;
    opacity: 1;
    ${StyledContent} {
      animation-duration: 0.25s;
      animation-fill-mode: forwards;
      /* animation-name: scaleUp; */
    }

  }

  @keyframes scaleUp {
    from {
      transform:translateY(50%);
    }
    to {
      transform:translateY(0%);
    }
  }

  @keyframes scaleDown {
    from {
      transform:translateY(0%);
    }
    to {
      transform:translateY(100%);
    }
  }
`;

interface IJDUnderBaseModal {
  isOpen: boolean;
  onClickClose: () => void;
  isDimClickClose?: boolean; // 딤 클릭시 닫기
  nudge?: boolean;
  className?: string;
  maxWidth?: string;
  nonDrag?: boolean;
}
interface DraggableData {
  node: HTMLElement;
  // lastX + deltaX === x
  x: number;
  y: number;
  deltaX: number;
  deltaY: number;
  lastX: number; lastY: number
}

const JDUnderBaseModal: FC<IJDUnderBaseModal> = ((props) => {
  const history = useHistory<{[key: string]: any}>();
  const [originalUrl] = useState<string>(history.location.pathname + history.location.hash);
  const { isOpen, children, onClickClose, isDimClickClose = true, nudge = false, className, nonDrag = false, maxWidth } = props;
  const [dragging, setDragging] = useState<boolean>(false);

  const pushModalState = useCallback(() => {
    window.history.pushState({ ...history.location.state }, '', history.location.pathname);
  }, [history]);

  useEffect(() => {
    if (!MobileStore.isMobile && isOpen) {
      pushModalState();
      window.addEventListener('popstate', pushModalState);
    }
    return () => {
      if (!MobileStore.isMobile && isOpen) {
        window.removeEventListener('popstate', pushModalState);
        if (originalUrl === history.location.pathname + history.location.hash) {
          window.history.go(-1);
        }
      }
    };
  }, [isOpen]);

  const stopDrag = (data:DraggableData) => {
    setDragging(false);
    if (data.y > 200) {
      if (onClickClose) onClickClose();
    }
  };
  const startDrag = () => {
    setDragging(true);
  };

  if (isOpen)
    return (

      <Portal>
        <DisableScroll />
        <JDUnderBaseModalDimFrame className={`${className} ${(isOpen && !dragging) ? 'active' : ''}`} onClick={() => { if (isDimClickClose && onClickClose) onClickClose(); }}>
          <Draggable
            onDrag={() => startDrag()}
            onStop={(e, data) => stopDrag(data)}
            bounds={{ top: 0 }}
            axis='y'
            position={{ x: 0, y: 0 }}
            handle='.drag-area'
          >
            <StyledContent
              nudge={nudge}
              maxWidth={maxWidth}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {!nonDrag && (
              <div role='button' className='drag-area'>
                <div className='drag-bar' />
              </div>
              )}
              { children}

            </StyledContent>

          </Draggable>
        </JDUnderBaseModalDimFrame>
      </Portal>

    );
  return <></>;
});

export default JDUnderBaseModal;
