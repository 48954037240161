import MainHeader from 'components/_v2/_common/mainHeader/MainHeader';
import JobsPreviewPostingInfo from 'components/_v2/jobs/preview/JobsPreviewPostingInfo';
import JobsPreviewPostingPositions from 'components/_v2/jobs/preview/JobsPreviewPostingPositions';
import PositionJdContent from 'components/_v2/positionJd/PositionJdContent';
import PositionJdEditRequest, { PositionTypeAll } from 'components/_v2/positionJd/PositionJdEditRequest';
import { Divider8Grey } from 'components/divider/Divider';
import Loading from 'components/Loading';
import { IJobPreviewRs } from 'interfaces/_v2/jobs/IJobPostingRs';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IsJsonString } from 'utils/_v2/validation';
import { iframePinchZoomUtil } from 'utils/_v2/iframePinchZoomUtils';
import AutoHeightIFrame from '../position/AutoHeightIframe';

const Frame = styled.div`

`;

const JobsDivider = styled(Divider8Grey)`
    margin: 32px 0;
`;

const JobsPreview: FC = () => {
  const [jobsDetail, setJobsDetail] = useState<IJobPreviewRs>();

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { data } = event;
      try {
        const parsedData = JSON.parse(data);
        setJobsDetail(parsedData);
      } catch (error) {
        console.error('Failed to parse data:', error);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  return (
    <>
      {jobsDetail
        ? (
          <>

            <MainHeader
              title={jobsDetail?.companyName || 'JOBDA'}
              backable={false}
              alarm={false}
              gnb={false}
              search={false}
              share={false}
              shareDescription={jobsDetail?.name}
            />
            <Frame>
              <JobsPreviewPostingInfo jobsDetail={jobsDetail} />
              <JobsDivider />
              <JobsPreviewPostingPositions jobsDetail={jobsDetail} />
              {jobsDetail?.content && IsJsonString(jobsDetail.content)
                ? (
                  <>
                    <JobsDivider />
                    <PositionJdContent value={JSON.parse(jobsDetail.content)} />
                  </>
                )
                : <AutoHeightIFrame srcDoc={iframePinchZoomUtil(jobsDetail.content)} />}
              <PositionJdEditRequest type={PositionTypeAll.JOBS} />
            </Frame>
          </>
        )
        : <Loading />}

    </>
  );
};

export default inject(injectStore.jobsModel)(observer(JobsPreview));
const data = {
  name: '대한해운/대한해운엘엔지 상시채용 인재 Pool 등록 공고',
  companyName: 'SM그룹',
  companyInfo: {
    ceoName: '유조혁/조유선',
    startingSalary: 100,
    averageSalary: 1000,
    numberOfEmployees: 176,
    businessSize: 'LARGE',
    establishDate: '1991-04-04',
    revenue: 1960821420000.00,
    profit: 1083948550000.00,
    lastModifiedDate: '2024-10-14 | null',
  },
  postStartDateTime: '2023-11-30T00:00:00',
  postEndDateTime: '2030-12-31T00:00:00',
  positions: [
    {
      positionName: '[인사-신입] 대한해운(주) 상시 인재 Pool 등록 공고',
      priority: 0,
      recruitmentType: 'NEW',
      locationCode: 10001,
    },
    {
      positionName: '[총무-신입] 대한해운(주) 상시 인재 Pool 등록 공고',
      priority: 1,
      recruitmentType: 'NEW',
      locationCode: 10001,
    },
    {
      positionName: '[자금-경력] 대한해운(주) 상시 인재 Pool 등록 공고',
      priority: 2,
      recruitmentType: 'NEW',
      locationCode: 10001,
    },
    {
      positionName: '[자금-신입] 대한해운(주) 상시 인재 Pool 등록 공고',
      priority: 3,
      recruitmentType: 'NEW',
      locationCode: 10001,
    },
    {
      positionName: '[회계-경력] 대한해운(주) 상시 인재 Pool 등록 공고',
      priority: 4,
      recruitmentType: 'NEW',
      locationCode: 10001,
    },
  ],
  representativeImageUrl: 'https://static.jobda.im/public/936afad3-7c1e-492a-a6dd-3c8286290b02.png',
};
