/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/media-has-caption */
import URIs from 'consts/URIs';
import { IAttachFile } from 'interfaces/_v2/profile/IProfileEducation';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import request from 'utils/request';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { ReactComponent as IconGuide } from './icon_guide.svg';

const FullscreenCameraWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100); /* 100vh 대신 동적 높이 */
  overflow: hidden;
  background-color: black;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scaleX(-1); /* 좌우 반전 */
  }
  .icon-guide {
    position: absolute;
    top: 50%;
    left: 50%;
    
    z-index: 10;
    pointer-events: none; /* 클릭이 비디오에 전달되도록 */
    width: 200px; /* 아이콘 크기 조절 */
    height: auto; /* 비율 유지 */
    opacity: 0.7; /* 약간 투명하게 표시 */
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  .text-guide {
    position: absolute;
    left:24px;
    top:50%;
    transform: translate(-50%, -50%) rotate(-90deg); /* 텍스트 회전 */
    z-index: 10;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    pointer-events: none; /* 클릭 방지 */
    opacity: 0.9;
  }
  .controls {
    position: absolute;
    bottom: calc(30px + env(safe-area-inset-bottom));
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
    z-index: 10;
    .record-button{
      width: 56px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: ${SpaceValue.XS}px;
      border-radius: 28px;
      border:2px solid #fff;
      .record-inner{
        width: 100%;
        height: 100%;
        border-radius: 9999px;
        background-color: #fff;
      }
    }
    .recording-button{
      width: 56px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: ${SpaceValue.XS}px;
      border-radius: 28px;
      border:2px solid #fff;
      .recording-inner{
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-color: #F1392c;
      }
    }
    button {
      padding: 10px 20px;
      font-size: 16px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .timer-progress {
  position: absolute;
  top: calc(22px + env(safe-area-inset-top));
  left: 0;
  width: 100%;
  display: flex;
  align-items: center; /* 세로 정렬 */
  justify-content: space-between; /* 좌우 숫자를 양쪽 끝으로 정렬 */
  padding: 0 16px; /* 좌우 여백 추가 */
  z-index: 10;

  .time {
    font-size: 14px;
    font-weight: bold;
    color: white;
    flex-shrink: 0; /* 숫자가 작아지지 않도록 설정 */
  }

  .progress-bar {
    position: relative;
    flex: 1; /* 남은 공간을 차지 */
    height: 4px; /* 프로그레스바 높이 */
    background-color: rgba(255, 255, 255, 0.5); /* 배경색 */
    margin: 0 8px; /* 좌우 숫자와 간격 */
    border-radius: 2px; /* 둥근 모서리 */

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0%; /* 초기 진행 상태 */
      background-color: #00b840;
      border-radius: 2px; /* 둥근 모서리 */
      transition: width 0.2s linear, background-color 0.3s ease;
    }
  }
}

`;
const setDynamicVH = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
interface IVideoRecorder{
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  mediaBlobUrl: string | null;
  setMediaBlobUrl: React.Dispatch<React.SetStateAction<string | null>>;
  videoBlob: Blob | null;
  setVideoBlob: React.Dispatch<React.SetStateAction<Blob | null>>;
}
const VideoRecorder: React.FC<IVideoRecorder> = ({ setCurrentStep, mediaBlobUrl, setMediaBlobUrl, videoBlob, setVideoBlob }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const recordedChunks = useRef<Blob[]>([]);
  const [recording, setRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState<number>(0);
  const recordingInterval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // 동적 vh 설정
    setDynamicVH();
    window.addEventListener('resize', setDynamicVH);

    return () => {
      window.removeEventListener('resize', setDynamicVH);
    };
  }, []);

  // 카메라 설정 및 프리뷰
  const setupPreview = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'user', width: 1280, height: 720 },
        audio: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      console.error('Failed to access camera:', error);
      alert('Camera access is required.');
    }
  };

  // 녹화 시작
  const startRecording = async () => {
    setMediaBlobUrl(null);
    setRecordingTime(0); // 타이머 초기화
    recordingInterval.current = setInterval(() => {
      setRecordingTime((prev) => prev + 1);
    }, 1000);

    const stream = videoRef.current?.srcObject as MediaStream;
    if (!stream) return;

    const mimeType = MediaRecorder.isTypeSupported('video/mp4')
      ? 'video/mp4'
      : 'video/webm';
    const mediaRecorder = new MediaRecorder(stream, { mimeType });

    mediaRecorderRef.current = mediaRecorder;

    recordedChunks.current = [];
    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunks.current.push(event.data);
      }
    };

    mediaRecorder.onstop = () => {
      const blob = new Blob(recordedChunks.current, { type: mimeType });
      const url = URL.createObjectURL(blob);
      setMediaBlobUrl(url);
      setVideoBlob(blob);

      if (recordingInterval.current) {
        clearInterval(recordingInterval.current);
      }
    };

    mediaRecorder.start(100);
    setRecording(true);
  };

  // 녹화 중지
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      if (recordingInterval.current) {
        clearInterval(recordingInterval.current);
      }
    }
  };
  useEffect(() => {
    if (mediaBlobUrl) {
      setCurrentStep(7);
    }
  }, [mediaBlobUrl]);
  useEffect(() => {
    setupPreview();

    return () => {
      if (videoRef.current?.srcObject) {
        const stream = videoRef.current.srcObject as MediaStream;
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };
  const progressPercentage = Math.min((recordingTime / 90) * 100, 100); // 90초 기준

  return (
    <FullscreenCameraWrapper>
      <div className='timer-progress'>
        <V2Text
          fontStyle={V2TextOption.fontStyle.body_3_m}
          color={V2TextOption.color.inverse}
        >
          {formatTime(recordingTime)}
        </V2Text>
        <div className='progress-bar'>
          <div
            className='progress'
            style={{
              width: `${progressPercentage}%`,
              backgroundColor: recordingTime >= 60 ? '#f1392c' : '#00b840',
            }}
          />
        </div>
        <V2Text
          fontStyle={V2TextOption.fontStyle.body_3_m}
          color={V2TextOption.color.inverse}
        >
          01:30
        </V2Text>
      </div>
      {
        !recording && (
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_2_m}
            color={V2TextOption.color.inverse}
            className='text-guide'
          >화면을 가로로 돌려주세요.
          </V2Text>

        )
      }

      <IconGuide className='icon-guide' />

      {/* 원본 비디오 */}
      <video ref={videoRef} muted playsInline />

      {/* 컨트롤 버튼 */}
      <div className='controls'>
        {!recording ? (
          <div className='record-button' role='button' onClick={startRecording}>
            <div className='record-inner' />
          </div>
        ) : (
          <div className='recording-button' role='button' onClick={stopRecording}>
            <div className='recording-inner' />
          </div>
        )}
      </div>
    </FullscreenCameraWrapper>
  );
};

export default VideoRecorder;
