import { setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { Suspense, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import smoothScroll from 'smoothscroll-polyfill';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@codetrix-studio/capacitor-google-auth';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import AppFrame from 'components/common/AppFrame';
import Loading from 'components/Loading';
import GlobalStyle from 'consts/style/GlobalStyle';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { Route, Router, Switch } from 'react-router-dom';
import browserHistory from 'utils/browserHistory';
import { RootRoutePaths, RoutePathsNeedLogin } from 'utils/RouteUtils';
/* Theme variables */
import MainMetaTag from 'components/_v2/_common/meta/MetaTag';
import AppUpdateModal from 'components/_v2/_common/modals/AppUpdateModal';
import OptionModalController from 'components/_v2/_common/modals/OptionModalController';
import TermDetailModal from 'components/_v2/_common/terms/TermDetailModal';
import JDToast from 'components/_v2/_common/toast/JDToast';
import MatchLogin from 'components/_v2/matchLogin/MatchLogin';
import AuthRoute from 'components/common/AuthRoute';
import CustomSplash from 'components/CustomSplash/CustomSplash';
import JDAHistoryRouteController from 'components/JDAHistoryRouteController';
import TermsModal from 'components/modals/TermsModal';
import { gapi } from 'gapi-script';
import IContextPageProps from 'interfaces/props/IContextPageProps';
import ILoginPageProps from 'interfaces/props/ILoginPageProps';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import { QueryClientProvider } from 'react-query';
import MobileStore from 'store/mobileStore';
import 'theme/variables.css';
import DeepLinkAppUrlListener from 'utils/_v2/DeepLinkAppUrlListener';
import { SSERegister } from 'utils/_v2/SSEUtil';
import AuthorizeUtil from 'utils/AuthorizeUtil';
import { queryClient } from 'utils/queryClient';
import { setupConfig } from '@ionic/core';
import ChattingModel from 'models/_v2/ChattingModel';
import ChattingAPI from 'api/chattingAPI';

setupIonicReact();

interface IAppProps extends IContextPageProps, ILoginPageProps, IComponentProps { }

smoothScroll.polyfill();
setupConfig({});

const App: React.FC<IAppProps> = ({ context = new Context(), login = new Login(),chattingModel = new ChattingModel() }) => {
  const { loading, initialized } = context;
  const [firstInit, setFirstInit] = useState<boolean>(true);
  const [isOpenAppUpdateModal, setIsOpenAppUpdateModal] = useState<boolean>(false);
  const [initialHeight] = useState<number>(window.innerHeight);

  const iosKeyboardEvent = (e: Event) => {
    if (e.target) {
      const visualViewport = e.target as VisualViewport;
      const keyboardHeight = initialHeight - visualViewport.height;
      context.setKeyBoardHeight(keyboardHeight);
    }
  };

  const checkAutoLogin = async () => {
    if (AuthorizeUtil.bearerAccessToken !== '') return;
    try {
      await AuthorizeUtil.updateAccessToken(true);
      await login.loadCurrentUser();
    } catch (e) {
      await AuthorizeUtil.removeIsAutoLogin();
      await AuthorizeUtil.removeAutoLoginInfo();
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        await MobileStore.init();
      } catch (e) {
        setIsOpenAppUpdateModal(true);
      } finally {
        MobileStore.showLogMobileStoreStatus();
      }

      await context.init();
      await AuthorizeUtil.init();
      await checkAutoLogin();
      gapi.load('client:auth2', () => {
        gapi.client.init({
          clientId: process.env.REACT_APP_GOOGLE_JS_KEY,
          plugin_name: 'chat',
        });
      });

      AuthorizeUtil.addEventExpired(() => {
        if (login.userInfo) {
          login.userInfo = null;
        }
      });

      if (AuthorizeUtil.bearerAccessToken && login.userInfo === null) await login.loadCurrentUser();

      if (MobileStore.isMobile) {
        try {
          if (login.userInfo) {
            try {
              if (!chattingModel.user) {
                const token = await ChattingAPI.getToken();
                const { user } = await chattingModel.client.loginWithToken({ userId: login.userInfo.id, loginToken: token, data: { isAnonymous: false } });
                chattingModel.setUser(user);
              }
              // FCM 토큰 업데이트 시도
              await AuthorizeUtil.updateBackendWithFCMToken();
            } catch (e) {
              setFirstInit(false);
              console.error(e,'here');

              // 실패해도 앱이 정상적으로 작동하도록 진행
            } finally {
              setFirstInit(false); // 에러 발생 여부와 관계없이 실행
            }
          }
          if (MobileStore.getIsLowerVersion()) {
            setIsOpenAppUpdateModal(true);
          } else {
            setFirstInit(false);
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        setFirstInit(false);
      }
    };
    if (firstInit) init();
  }, []);

  useEffect(() => {
    (async () => {
      if (MobileStore.currentPlatform === 'ios') {
        if (window.visualViewport) {
          window.visualViewport.addEventListener('resize', iosKeyboardEvent);
        }
      }
    })();
  }, []);
  // 만 15세 미만 체크해서 생년월일 입력하도록 유도

  return firstInit ? (
    <>
      <CustomSplash />
      { isOpenAppUpdateModal && <AppUpdateModal />}
    </>
  ) : (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <MainMetaTag />
        <AppFrame>
          <GlobalStyle {...({ platform: MobileStore.currentPlatform, preventScroll: context.preventScroll } as any)} />
          <Suspense fallback={<Loading />}>
            <Router history={browserHistory}>
              <IonReactRouter>
                <DeepLinkAppUrlListener />
                <JDAHistoryRouteController />
                <Switch>
                  {RoutePathsNeedLogin.map((route) => <AuthRoute {...route} />)}
                  {RootRoutePaths.map((route) => <Route {...route} />)}
                </Switch>
                {(loading > 0 || !initialized) && <Loading />}
                <OptionModalController />
                <TermsModal />
                <TermDetailModal />
                <MatchLogin />
              </IonReactRouter>
            </Router>
          </Suspense>
          <SSERegister />
          <JDToast {...context.toastObject} />
        </AppFrame>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default inject(injectStore.context, injectStore.login)(observer(App));
