import Icon from '__designkit__/icon/Icon';
import React, { FC } from 'react';
import styled from 'styled-components';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import Fonts from '__designkit__/common/fonts';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChecklistContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: ${SpaceValue.XL}px;
`;

const ChecklistItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border: 1px solid #E9EAEB;
  border-radius: 8px;
`;

const QuestionText = styled.span`
  font: ${Fonts.B2_Medium};
  color: #333436;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  appearance: none;
  background-color: #FFFFFF;
  border: 1px solid #DFE0E2;
  cursor: pointer;
  transition: all 0.2s;

  &:checked {
    background-color: #02C551;
    border-color: #02C551;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &:checked::after {
    content: '✔';
    color: white;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
  }
`;

const VideoRecordNotice1 = () => (
  <Frame>
    <V2Text
      fontStyle={V2FontStyleType.body_3_sb}
      color={V2TextColorsType.brand}
    >
      영상 가이드
    </V2Text>
    <SpacingBlock size={SpaceValue.XXXS} vertical />
    <V2Text
      fontStyle={V2FontStyleType.title_1_b}
      color={V2TextColorsType.default}
    >
      녹화시 유의사항
    </V2Text>
    <SpacingBlock size={SpaceValue.M} vertical />
    <V2Text
      fontStyle={V2FontStyleType.body_1_m}
      color={V2TextColorsType.subtle}
    >
      자기소개 영상은 실제 채용담당자가 보고 채용에 대한 의사결정을 진행하는 영상입니다. 용모와 답변태도는 아래 내용을 참고하시어, 점검 해주시길 바랍니다.
    </V2Text>
  </Frame>
);

const VideoRecordNotice2 = () => (
  <Frame>
    <V2Text
      fontStyle={V2FontStyleType.body_3_sb}
      color={V2TextColorsType.brand}
    >
      영상 가이드22
    </V2Text>
    <SpacingBlock size={SpaceValue.XXXS} vertical />
    <V2Text
      fontStyle={V2FontStyleType.title_1_b}
      color={V2TextColorsType.default}
    >
      녹화시 유의사항22
    </V2Text>
    <SpacingBlock size={SpaceValue.M} vertical />
    <V2Text
      fontStyle={V2FontStyleType.body_1_m}
      color={V2TextColorsType.subtle}
    >
      자기소개 영상은 실제 채용담당자가 보고 채용에 대한 의사결정을 진행하는 영상입니다. 용모와 답변태도는 아래 내용을 참고하시어, 점검 해주시길 바랍니다.
    </V2Text>
  </Frame>
);

const VideoRecordNotice3 = () => (
  <Frame>
    <V2Text
      fontStyle={V2FontStyleType.body_3_sb}
      color={V2TextColorsType.brand}
    >
      영상 가이드33
    </V2Text>
    <SpacingBlock size={SpaceValue.XXXS} vertical />
    <V2Text
      fontStyle={V2FontStyleType.title_1_b}
      color={V2TextColorsType.default}
    >
      녹화시 유의사항33
    </V2Text>
    <SpacingBlock size={SpaceValue.M} vertical />
    <V2Text
      fontStyle={V2FontStyleType.body_1_m}
      color={V2TextColorsType.subtle}
    >
      자기소개 영상은 실제 채용담당자가 보고 채용에 대한 의사결정을 진행하는 영상입니다. 용모와 답변태도는 아래 내용을 참고하시어, 점검 해주시길 바랍니다.
    </V2Text>
  </Frame>
);

const VideoRecordNotice4: React.FC = () => {
  const questions = [
    '깔끔한 배경(단색 벽지) 앞에 서 있나요?',
    '깔끔한 용모와 복장을 갖추었나요?',
    '스크립트를 암기하였나요?',
    '충분한 연습을 하였나요?',
  ];

  return (
    <Frame>
      <V2Text
        fontStyle={V2FontStyleType.body_3_sb}
        color={V2TextColorsType.brand}
      >
        영상 가이드
      </V2Text>
      <SpacingBlock size={SpaceValue.XXXS} vertical />
      <V2Text
        fontStyle={V2FontStyleType.title_1_b}
        color={V2TextColorsType.default}
      >
        자기점검 체크리스트
      </V2Text>
      <SpacingBlock size={SpaceValue.M} vertical />
      <V2Text
        fontStyle={V2FontStyleType.body_1_m}
        color={V2TextColorsType.subtle}
      >
        점검 체크 리스트를 확인하고, 촬영을 시작해주세요.
      </V2Text>
      <ChecklistContainer>
        {questions.map((question, index) => (
          <ChecklistItem key={index}>
            <QuestionText>{question}</QuestionText>
            <Checkbox />
          </ChecklistItem>
        ))}
      </ChecklistContainer>
    </Frame>
  );
};

export { VideoRecordNotice1, VideoRecordNotice2, VideoRecordNotice3, VideoRecordNotice4 };
