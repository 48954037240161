import axios from 'axios';
import config from 'config';
import URIs from 'consts/URIs';
import { IMatchingConfigConditionRs } from 'interfaces/_v2/matchApply/IMatchingConfigConditionRs';
import { ICheckVerificationCodeRq } from 'interfaces/_v2/profile/IBasicInfoRqRs';
import { IProfileTypeRs } from 'interfaces/_v2/profile/IProfileTypeRs';
import IAccountRs from 'interfaces/rqrs/IAccountRs';
import { IIdentificationRq, IVerificationSmsCheckRq } from 'interfaces/rqrs/IJoinRqRs';
import { ILoginRq, ILoginRs } from 'interfaces/rqrs/ILoginRqRs';
import ILoginUserInfoRs from 'interfaces/rqrs/ILoginUserInfoRs';
import { ISocialAuthLoginRs } from 'interfaces/rqrs/ISocialAuthLoginRs';
import ISuccessRs from 'interfaces/rqrs/ISuccessRs';
import IUserDefaultRs, { IUsersMobileRq } from 'interfaces/rqrs/IUserDefaultRs';
import IUserMarketingConsentRs from 'interfaces/rqrs/IUserMarketingConsentRs';
import { action, computed, observable } from 'mobx';
import { JOIN_ERROR } from 'models/_v2/JoinModel';
import MobileStore from 'store/mobileStore';
import AuthorizeUtil from 'utils/AuthorizeUtil';
import PushUtils from 'utils/PushUtils';
import { request } from 'utils/request';
import { CapacitorHttp } from '@capacitor/core';

interface IUserWorkLogRs {
  'accApplyCount': number,
  'jobApplicationCount': number,
  'matchStatusCount': number,
  'profileCompletionRate': number
}

export enum LoginErrorCode {
  U002 = 'U002',
  U003 = 'U003',
  B001 = 'B001',
}

export default class Login {
  @observable userInfo: ILoginUserInfoRs | null = null;
  @observable marketingConsentRs: IUserMarketingConsentRs | null = null;
  @observable userDefault: IUserDefaultRs | null = null; //* * 기본 정보 */
  @observable accountRs: IAccountRs | null = null; //* * 계정 정보 */
  @observable profileComplete: boolean = false;
  @observable identity: boolean = false; // 본인 인증 여부 조회
  @observable matchLoginYn: boolean = false; // ats 수동 인재 로그인 여부
  @observable publicKey: string = '';
  @observable positionId: number = 0;
  @observable isAutoLogin: boolean = false;
  @observable matchConfigStatus: IMatchingConfigConditionRs | null = null;
  @observable profileType: IProfileTypeRs | null = null;
  @observable userWorkLog: IUserWorkLogRs | null = null
  @observable jobflexType: string = '';
  @observable jobflexResumeDomain: string = '';
  @observable jobflexJobnoticeSn: number = 0;
  @observable jobflexJobnoticeTitle: string = '';
  @observable jobflexRedirectUrl: string = '';
  @observable jobflexAccessToken: string = '';

  // 계정 설정
  @observable isEmailCheck: boolean = false;
  @observable isEmailChanged: boolean = false;

  // auth
  @observable isAuthCall: number = 0;
  @observable isAuthSuccess: boolean = false;

  @action
  login = async (id: string, password: string) => {
    try {
      const loginRq: ILoginRq = { id, password };
      const loginRs = await request<ILoginRs>({
        method: 'post',
        url: URIs.post_login,
        data: loginRq,
      });
      if (loginRs) {
        try {
          await AuthorizeUtil.setAccessToken(loginRs.accessToken);
          await AuthorizeUtil.setRefreshToken(loginRs.refreshToken);
        } catch (e) {
          console.error('Failed to set access token:', e);
        }
      }
    } catch (e) {
      const err = e as { response: any };
      if (err.response.data.errorCode === LoginErrorCode.U002) throw LoginErrorCode.U002;
      if (err.response.data.errorCode === LoginErrorCode.U003) throw LoginErrorCode.U003;
      throw e;
    }
  };

  @action
  logout = async () => {
    let res = null;
    if (!MobileStore.isMobile) {
      res = await request<boolean>({
        method: 'post',
        url: URIs.post_logout,
      });
    } else {
      try {
        this.removeDeviceFcmToken(PushUtils.FCM_TOKEN);
      } catch (e) {
        console.error(e);
      }
      if (MobileStore.currentPlatform === 'android') {
        res = await request<boolean>({
          method: 'post',
          url: URIs.post_logout,
        });
      } else {
        res = (await CapacitorHttp.post({
          url: config.host + URIs.post_logout,
          headers: { 'Content-Type': 'application/json' },
          data: {}, // 빈 객체를 데이터로 전달
        })).data;
      }
    }
    if (res) {
      AuthorizeUtil.removeAccessToken();
      AuthorizeUtil.removeIsAutoLogin();
      if (MobileStore.isMobile)AuthorizeUtil.removeAutoLoginInfo();
      this.userInfo = null;
      this.userDefault = null;
    }
  };

  @action
  getKakaoToken = async (data:string) => {
    try {
      const response = await axios.post('https://kauth.kakao.com/oauth/token', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  postKakaoAuth = async (accessToken: string) => {
    try {
      const loginRs = await request<ISocialAuthLoginRs>({
        method: 'post',
        url: '/v2/oauth2/kakao/login',
        data: { accessToken },
      });
      if (loginRs) {
        try {
          await AuthorizeUtil.setAccessToken(loginRs.accessToken);
          await AuthorizeUtil.setRefreshToken(loginRs.refreshToken);
        } catch (e) {
          console.error('Failed to set access token:', e);
        }
      }
      return loginRs;
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  @action
  postGoogleAuth = async (accessToken: string) => {
    try {
      const loginRs = await request<ISocialAuthLoginRs>({
        method: 'post',
        url: '/v2/oauth2/google/login',
        data: { accessToken },
      });
      if (loginRs) {
        try {
          await AuthorizeUtil.setAccessToken(loginRs.accessToken);
          await AuthorizeUtil.setRefreshToken(loginRs.refreshToken);
        } catch (e) {
          console.error('Failed to set access token:', e);
        }
      }
      return loginRs;
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  @action
  postAppleAuth = async (code: string) => {
    try {
      const platform = MobileStore.currentPlatform === 'ios' ? 'IOS' : 'WEB';
      const loginRs = await request<ISocialAuthLoginRs>({
        method: 'post',
        url: URIs.post_apple_login,
        data: { code, platform },
      });
      if (loginRs) {
        try {
          await AuthorizeUtil.setAccessToken(loginRs.accessToken);
          await AuthorizeUtil.setRefreshToken(loginRs.refreshToken);
        } catch (e) {
          console.error('Failed to set access token:', e);
        }
      }
      return loginRs;
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  @action
  loadCurrentUser = async () => {
    try {
      this.userInfo = await request<ILoginUserInfoRs>({
        method: 'get',
        url: URIs.get_login_user_info,
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadMarketingConsent = async () => {
    this.marketingConsentRs = await request<IUserMarketingConsentRs>({
      method: 'get',
      url: URIs.get_users_marketing_consent,
    });
  }

  @computed
  get marketingConsentYn() {
    return this.marketingConsentRs?.marketingConsentYn || this.marketingConsentRs?.emailYn || this.marketingConsentRs?.smsYn; // 하나라도 동의되어있을경우 마케팅수신동의는 되어있는 상태로 보고자합니다!
  }

  @action
  loadUserDefault = async () => {
    this.userDefault = await request<IUserDefaultRs>({
      method: 'get',
      url: URIs.get_users_default,
    });
  }

  @action
  editUsersMobile = async (rq:IUsersMobileRq) => {
    try {
      const res = await request({
        method: 'put',
        url: URIs.put_users_mobile,
        data: rq,
      });
      return res;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  duplicationCheck = async (email: string): Promise<boolean> => (await request<{ isDuplicate: boolean }>({
    method: 'get',
    url: URIs.get_user_duplication_check_email,
    params: { email },
  })).isDuplicate;

  @action
  updateUserDefault = async (email: string, mobile: string | null, birthDate: string, name: string): Promise<boolean> => request<boolean>({
    method: 'put',
    url: URIs.put_users_default,
    data:
      { name, email, mobile, birthDate },
  })

  @action
  requestModifyMobileVerification = async (mobile: string): Promise<boolean> => (await request<ISuccessRs>({
    method: 'post',
    url: URIs.post_verification_user_sms_send,
    data: { mobile },
  })).success;

  @action
  verifyMobile = async (mobile: string, token: string): Promise<boolean> => (await request<ISuccessRs>({
    method: 'post',
    url: URIs.post_verification_user_sms_check,
    data: { mobile, token },
  })).success;

  @action
  updateNotificationSettings = async (type: 'EMAIL' | 'SMS', useYn: boolean): Promise<boolean> => {
    try {
      const success = await request<boolean>({
        method: 'put',
        url: URIs.put_users_notification_settings,
        data: { type, useYn },
      });
      if (!success)
        throw new Error('failed');
      if (this.marketingConsentRs) {
        switch (type) {
          case 'EMAIL':
            this.marketingConsentRs.emailYn = useYn;
            break;
          case 'SMS':
            this.marketingConsentRs.smsYn = useYn;
            break;
          default:
        }
        this.marketingConsentRs.marketingConsentYn = this.marketingConsentRs.emailYn || this.marketingConsentRs.smsYn;
      }
      return success;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  getMatchConfigStatus = async () => {
    try {
      this.matchConfigStatus = await request<IMatchingConfigConditionRs>({
        method: 'get',
        url: URIs.get_match_config_status,
      });
    } catch (e) {
      console.error(e);
    }
  }

  // 매칭 설정 상태 변경
  @action
  changeMatchConfigStatus = async (useYn: boolean, reason?:string, sourcePage?: string) => {
    try {
      await request<boolean>({
        method: 'put',
        url: URIs.put_match_config_status,
        data: { useYn, reason, sourcePage },
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  @action
  loadProfileType = async () => {
    try {
      this.profileType = (await request({
        method: 'get',
        url: URIs.get_match_profiles_type,
      }));
    } catch (error) {
      console.error(error);
    }
  }

  @action
  loadAccountRs = async () => {
    this.accountRs = await request({
      method: 'get',
      url: URIs.get_users_account,
    });
  }

  @action
  deleteUser = async () => {
    try {
      const res = (await request<{ success: boolean }>({
        method: 'delete',
        url: URIs.delete_users,
      })).success;
      return res;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  updatePassword = async (currentPassword: string, newPassword: string): Promise<boolean> => (await request<{ success: boolean }>({
    method: 'put',
    url: URIs.put_users_password,
    data: {
      currentPassword,
      newPassword,
    },
  })).success;

  @action
  updatePasswordExpiration = async () => {
    try {
      const res = (await request({
        method: 'put',
        url: URIs.put_users_password_expiration_refresh,
      }));
      return res;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadIdentity = async () => {
    try {
      this.identity = await request({
        method: 'get',
        url: URIs.get_identity,
      });
    } catch (e) {
      console.error(e);
    }
  }

  // App  기록
  @action
  updateAppRuntime = async (token: string) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_users_device_fcm_app_runtime,
        data: { token },
      });
    } catch (e) {
      console.error(e);
    }
  };

  @action
  addDeviceFcmToken = async (token: string) => {
    try {
      await request({
        method: 'post',
        url: URIs.post_users_device_fcm_token,
        data: { token },
      });
    } catch (e) {
      console.error(e);
    }
  };

  @action
  removeDeviceFcmToken = async (token: string) => {
    try {
      await request({
        method: 'delete',
        url: URIs.delete_users_device_fcm_token,
        data: { token },
      });
    } catch (e) {
      console.error(e);
    }
  };

  @action
  postMatchApplicantsAccount = async (publicKey: string, positionId: number) => {
    try {
      await request({
        method: 'post',
        url: URIs.post_match_applicants_account_link,
        data: {
          publicKey,
          positionId,
        },
      });
      this.matchLoginYn = false;
    } catch (e) {
      console.error(e);
      throw (e);
    }
  }

  @action
  getResumeExists = async (publicKey: string, positionId: number): Promise<boolean> => (await request<{ resumeWritingYn: boolean }>({
    method: 'get',
    url: URIs.get_resume_exists,
    params: { publicKey, positionId },
  })).resumeWritingYn;

  @action
  saveMatchLoginInfo = (publicKey: string, positionId: number) => {
    this.publicKey = publicKey;
    this.positionId = positionId;
    if (this.userInfo) {
      this.matchLoginYn = true;
    }
  };

  @action
  validateUserEmail = async (email?: string) => {
    const res = await request<{ isDuplicate: boolean }>({
      method: 'get',
      url: URIs.get_register_duplication_check_email,
      params: { email },
    });
    if (res.isDuplicate) return false;
    return true;
  }

  @action
  validateUserMobile = async (mobile: string) => {
    const res = await request<boolean>({
      method: 'get',
      url: URIs.get_register_duplication_check_mobile,
      params: { mobile },
    });
    return res;
  }

  @action
  sendSMS = async (mobile?:string) => {
    if (!mobile) return;
    await request({
      method: 'post',
      url: URIs.post_verification_user_sms_send,
      data: { mobile },
    });
    this.isAuthCall++;
  }

  @action
  checkSMS = async (smsCheckRq: Partial<IVerificationSmsCheckRq>) => {
    const res = await request < { success: boolean }>({
      method: 'post',
      url: URIs.post_verification_user_sms_check,
      data: smsCheckRq,
    });
    if (res.success) this.isAuthSuccess = true;
    else {
      this.isAuthSuccess = false;
      throw JOIN_ERROR.WRONG_TOKEN;
    }
  }

  @action
  putSaveIdentity = async (data: IIdentificationRq) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_v2_identity,
        data,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  updateBirthDate = async (birthDate: string) => {
    await request({
      method: 'put',
      url: URIs.put_users_birthdate,
      data: { birthDate },
    });
  }

  @action
  updateLongTermAccount = async (tmpData?: ICheckVerificationCodeRq) => {
    try {
      const data = tmpData || {};
      await request({
        method: 'post',
        url: URIs.post_verification_user_long_inactive_check,
        data,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  getUserWorkLog = async () => {
    this.userWorkLog = await request<IUserWorkLogRs>({
      method: 'get',
      url: URIs.get_user_work_log,
    });
  };
}
