/* eslint-disable array-callback-return */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import ProfileAdditionInfoModal from 'components/_v2/positionJd/ProfileAdditionInfoModal';
import ProfilePreviewModal from 'components/_v2/profile/preview/ProfilePreviewModal';
import {
  PositionItemTypeText,
  ProfileItemType,
  ProfileItemTypeText,
  ResumeFormType,
} from 'consts/_v2/profile/ProfileType';
import { AccLiteStatusType } from 'interfaces/_v2/positionJd/IPositionDetailRs';
import {
  IResumeAdditionInfoApplyDto,
  IResumeAdditionInfoApplyRq,
  IResumeAdditionInfoTemp,
  IResumePreviewAdditionInfo,
  IResumePreviewAdditionInfoDto,
} from 'interfaces/_v2/positionJd/IResumeAdditionInfoRqRs';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import PositionJdModel from 'models/_v2/PositionJdModel';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import RoutePaths from 'consts/RoutePaths';
import { useHistory } from 'react-router';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { toJS } from 'mobx';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
  position: relative;

  .title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${SpaceValue.XS}px;
    margin-bottom: ${SpaceValue.XL}px;
  }
  .desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font: ${Fonts.B2_Medium_P};
    color: ${colors.CG_70};
    background-color: ${colors.CG_30};
    padding: 20px;
    margin-bottom: 24px;
    border-radius: 8px;
    width: 100%;
    .sub-desc {
      display: flex;
      align-items: center;
      font: ${Fonts.B3_Medium};
      color: ${colors.CG_90};

      span {
        margin-left: 4px;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
`;
const SubBoxComponent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${SpaceValue.L}px;
  padding: ${SpaceValue.M}px ${SpaceValue.L}px;
  width: 140px;
  height: 70px;
  border-radius: 8px;
  background-color: ${V2BackgroundColors.surface_default};
  border: 1px solid ${V2BorderColors.danger};
  gap: ${SpaceValue.XS}px;

  &:active {
    background: rgba(51, 52, 54, 0.08);
  }

  .title-name {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;
const BoxComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${SpaceValue.XL}px 0;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${V2BorderColors.default};
  background: ${colors.WHITE_100};
  overflow: hidden;
  margin-bottom: 12px;

  &:active {
    background: rgba(51, 52, 54, 0.08);
  }

  &.profile-box {
    &:active {
      background: ${colors.WHITE_100};
    }
  }

  .apply-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 ${SpaceValue.XL}px;
  }

  .apply-list {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;

    ::-webkit-scrollbar {
      display: none;
    }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    .scroll-area {
      display: flex;
      gap: ${SpaceValue.XS}px;
      width: fit-content;
      margin: 0 ${SpaceValue.XL}px;
    }

    .subtitle-text {
      display: flex;
      gap: ${SpaceValue.XS}px;
    }
  }

  .box-name {
    display: flex;
    align-items: center;
    font: ${Fonts.B1_Bold};
    color: ${colors.JOBDA_BLACK};
  }
  .disabled-box-name {
    display: flex;
    align-items: center;
    font: ${Fonts.B1_Bold};
    color: ${colors.CG_50};
    .subtitle {
      margin-left: 8px;
      font: ${Fonts.B3_Medium};
      color: ${colors.CG_50};
    }
  }
  .badge {
    display: flex;
    align-items: center;
    gap: ${SpaceValue.XXXS}px;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    font: ${Fonts.B3_Bold};
    color: ${colors.WHITE_100};
    border-radius: 4px;
    background-color: ${colors.JOBDA_BLACK};
  }
`;

const SubmitButtonFrame = styled.div`
  width: 100%;
  position: fixed;
  bottom: calc(0px + env(safe-area-inset-bottom));
  display: flex;
  gap: ${SpaceValue.XS}px;
  padding: 16px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.WHITE_100};

  button {
    width: 100%;
  }
  .preview-btn {
    margin-right: 8px;
  }
`;
export interface IProfileApplyModalProps extends IProfileProps {
  handleClose: () => void;
  handleSuccess: (additionInfo: IResumeAdditionInfoApplyRq) => void;
  positionJdModel?: PositionJdModel;
}

const defaultValues: Partial<IResumeAdditionInfoTemp> = { additionInfo: [] };

const ProfileApplyModal: FC<IProfileApplyModalProps> = ({
  handleClose,
  handleSuccess,
  profileModel = new ProfileModel(),
  positionJdModel = new PositionJdModel(),
}) => {
  const [isPreview, setIsPreview] = useState(false);
  const [transAdditionInfo, setTransAdditionInfo] =
    useState<IResumeAdditionInfoApplyRq>({ additionInfo: [] });
  const [previewAdditionInfo, setPreviewAdditionInfo] =
    useState<IResumePreviewAdditionInfo>({ additionInfo: [] });
  const [additionAbled, setAdditionAbled] = useState<boolean>(false);
  const additionInfoSchema = yup.object();
  const [profileCheck, setProfileCheck] = useState<boolean>(false);
  const history = useHistory();
  const useFormed = useForm<IResumeAdditionInfoTemp>({
    mode: 'all',
    resolver: yupResolver(additionInfoSchema),
    defaultValues,
  });
  const { getValues } = useFormed;

  const [isOpenAdditionInfoModal, setIsOpenAdditionInfoModal] = useState(false);

  const viewPreview = async () => {
    await profileModel.loadProfileDetail();
    setIsPreview(true);
  };

  const handleApply = async () => {
    handleSuccess(transAdditionInfo);
  };
  const transformAdditionInfo = async () => {
    const additionInfo: IResumeAdditionInfoApplyDto[] = [];
    const previewAdditionInfoTemp: IResumePreviewAdditionInfoDto[] = [];
    const originalInfo = positionJdModel.profileAdditionalInfo;
    if (originalInfo) {
      originalInfo.forEach((info, idx) => {
        let transformedFiles;
        if (info && info.answer && info.answer.files) {
          transformedFiles = info.answer.files.map((file) => ({
            fileUid: file.uid,
            fileName: file.name,
            fileSize: 0,
            fileUrl: '',
          }));
        }

        additionInfo.push({
          positionAdditionInfoSn: originalInfo[idx].additionInfoSn,
          question: originalInfo[idx].question,
          type: originalInfo[idx].type,
          answer: info?.answer?.answer ? info.answer.answer : '',
          choices: info.answer?.choices || [],
          files: info.answer?.files,
        });

        previewAdditionInfoTemp.push({
          question: originalInfo[idx].question,
          type: originalInfo[idx].type,
          answer: info?.answer?.answer ? info.answer.answer : '',
          choices: info.answer?.choices || [],
          files: transformedFiles || [],
        });
      });
    }
    const additionInfoRq: IResumeAdditionInfoApplyRq = { additionInfo };
    const additionPreview: IResumePreviewAdditionInfo = {
      additionInfo: previewAdditionInfoTemp,
    };

    setTransAdditionInfo(additionInfoRq);
    setPreviewAdditionInfo(additionPreview); // file 형식이 다름
  };

  useEffect(() => {
    const init = async () => {
      await positionJdModel.getRequirementVerification(
        positionJdModel.positionSn,
      );
      await positionJdModel.getPositionAccStatus(positionJdModel.positionSn);
    };
    init();
  }, [isOpenAdditionInfoModal]);

  useEffect(() => {
    const checkAdditionInfo = async () => {
      if (positionJdModel.profileAdditionalInfo) {
        await positionJdModel.getProfileAdditionInfo(
          positionJdModel.positionSn,
        );
        await positionJdModel.getRequirementVerification(
          positionJdModel.positionSn,
        );
        await transformAdditionInfo();
        const check = checkRequiredInfo();
        setAdditionAbled(check);
      }
      const check = checkRequiredInfo();
      setAdditionAbled(check);
    };
    checkAdditionInfo();
  }, [isOpenAdditionInfoModal]);
  const checkRequiredInfo = (): boolean => {
    if (positionJdModel.profileRequiredItems) {
      setProfileCheck(true);
    } else {
      setProfileCheck(false);
    }
    const additionalInfo = positionJdModel.profileAdditionalInfo;

    if (!additionalInfo) return false;

    return additionalInfo.every((info) => {
      if (info.type !== ResumeFormType.ADDITION_ATTACH_FILE && !info.answer) {
        return false;
      }
      if (info.required) {
        const hasAnswer = !!info.answer?.answer;
        const hasFiles = info.answer?.files && info.answer.files.length > 0;
        const hasSelectedChoice =
          info.answer?.choices &&
          info.answer.choices.some((choice) => choice.selectYn);
        if (!hasAnswer && !hasFiles && !hasSelectedChoice) {
          return false;
        }
      }
      return true;
    });
  };

  const incompleteCount = positionJdModel.profileRequiredItems
    ? Object.keys(positionJdModel.profileRequiredItems).reduce((count, key) => {
        return (
          count + (positionJdModel.profileRequiredItems?.[key]?.length || 0)
        );
      }, 0)
    : 0;

  const closeModal = () => {
    handleClose();
  };

  return (
    <JDFullModal closeCustom handleClose={closeModal}>
      <FormProvider {...useFormed}>
        <Frame>
          <div className="title">
            <V2Text
              element={V2TextOption.element.span}
              fontStyle={V2TextOption.fontStyle.title_2_b}
              color={V2TextOption.color.default}
            >
              지원하기
            </V2Text>
            <V2Text
              element={V2TextOption.element.span}
              fontStyle={V2TextOption.fontStyle.body_2_m}
              color={V2TextOption.color.subtle}
            >
              포지션마다 요구하는 프로필 항목이 다를 수 있습니다.
              <br />각 항목을 확인하고 필요한 정보를 작성하거나 첨부해 주세요.
            </V2Text>
          </div>
          <BoxComponent className="profile-box">
            <div className="apply-title">
              <V2Text
                element={V2TextOption.element.span}
                fontStyle={V2TextOption.fontStyle.body_1_b}
                color={V2TextOption.color.default}
              >
                내 프로필
              </V2Text>
              {positionJdModel.profileRequiredItems ? (
                <div className="badge">
                  <V2Icon
                    name={V2IconOption.name.errorFilled}
                    size={V2IconOption.size.S}
                    fill={V2IconOption.fill.accent_red}
                  />
                  <V2Text
                    element={V2TextOption.element.span}
                    fontStyle={V2TextOption.fontStyle.body_2_sb}
                    color={V2TextOption.color.danger}
                  >
                    {incompleteCount > 0
                      ? `${incompleteCount}개 미완료`
                      : '미완료'}
                  </V2Text>
                </div>
              ) : (
                <div className="badge">
                  <V2Icon
                    name={V2IconOption.name.checkCircleFilled}
                    size={V2IconOption.size.S}
                    fill={V2IconOption.fill.primary}
                  />
                  <V2Text
                    element={V2TextOption.element.span}
                    fontStyle={V2TextOption.fontStyle.body_2_sb}
                    color={V2TextOption.color.success}
                  >
                    완료
                  </V2Text>
                </div>
              )}
            </div>
            <div className="apply-list">
              <div className="scroll-area">
                {positionJdModel.profileRequiredItems &&
                  Object.keys(positionJdModel.profileRequiredItems).map(
                    (key) =>
                      positionJdModel.profileRequiredItems &&
                      positionJdModel.profileRequiredItems[key].length > 0 && (
                        <div className="subtitle-text">
                          {positionJdModel.profileRequiredItems[key].map(
                            (item, idx, array) => (
                              <SubBoxComponent
                                key={key}
                                onClick={() =>
                                  history.replace(
                                    RoutePaths.profile_anchor(key),
                                  )
                                }
                              >
                                <div className="title-name">
                                  <V2Text
                                    element={V2TextOption.element.span}
                                    fontStyle={V2TextOption.fontStyle.body_2_b}
                                    color={V2TextOption.color.default}
                                  >
                                    {PositionItemTypeText[item]}
                                  </V2Text>
                                  <V2Text
                                    element={V2TextOption.element.span}
                                    fontStyle={
                                      V2TextOption.fontStyle.caption_1_m
                                    }
                                    color={V2TextOption.color.subtlest}
                                  >
                                    {ProfileItemTypeText[key]}
                                  </V2Text>
                                </div>
                              </SubBoxComponent>
                            ),
                          )}
                        </div>
                      ),
                  )}
              </div>
            </div>
          </BoxComponent>
          {positionJdModel.profileAdditionalInfo && (
            <BoxComponent onClick={() => setIsOpenAdditionInfoModal(true)}>
              <div className="apply-title">
                <V2Text
                  element={V2TextOption.element.span}
                  fontStyle={V2TextOption.fontStyle.body_1_b}
                  color={V2TextOption.color.default}
                >
                  추가 정보
                </V2Text>
                {!additionAbled ? (
                  <div className="badge">
                    <V2Icon
                      name={V2IconOption.name.errorFilled}
                      size={V2IconOption.size.S}
                      fill={V2IconOption.fill.accent_red}
                    />
                    <V2Text
                      element={V2TextOption.element.span}
                      fontStyle={V2TextOption.fontStyle.body_2_sb}
                      color={V2TextOption.color.danger}
                    >
                      미완료
                    </V2Text>
                  </div>
                ) : (
                  <div className="badge">
                    <V2Icon
                      name={V2IconOption.name.checkCircleFilled}
                      size={V2IconOption.size.S}
                      fill={V2IconOption.fill.primary}
                    />
                    <V2Text
                      element={V2TextOption.element.span}
                      fontStyle={V2TextOption.fontStyle.body_2_sb}
                      color={V2TextOption.color.success}
                    >
                      완료
                    </V2Text>
                  </div>
                )}
              </div>
            </BoxComponent>
          )}
          {positionJdModel.positionAccStatus?.accApplyRequiredYn && (
            <>
              <BoxComponent>
                <div className="apply-title">
                  <V2Text
                    element={V2TextOption.element.span}
                    fontStyle={V2TextOption.fontStyle.body_1_b}
                    color={V2TextOption.color.disabled}
                  >
                    역량검사
                  </V2Text>
                  {positionJdModel.positionAccStatus?.accLiteStatus ===
                  AccLiteStatusType.COMPLETED ? (
                    <div className="badge">
                      <V2Icon
                        name={V2IconOption.name.checkCircleFilled}
                        size={V2IconOption.size.S}
                        fill={V2IconOption.fill.primary}
                      />
                      <V2Text
                        element={V2TextOption.element.span}
                        fontStyle={V2TextOption.fontStyle.body_2_sb}
                        color={V2TextOption.color.success}
                      >
                        완료
                      </V2Text>
                    </div>
                  ) : positionJdModel.positionAccStatus?.accLiteStatus ===
                    AccLiteStatusType.IN_PROGRESS ? (
                    <div className="badge">
                      <V2Icon
                        name={V2IconOption.name.checkCircleFilled}
                        size={V2IconOption.size.S}
                        fill={V2IconOption.fill.primary}
                      />
                      <V2Text
                        element={V2TextOption.element.span}
                        fontStyle={V2TextOption.fontStyle.body_2_sb}
                        color={V2TextOption.color.success}
                      >
                        응시 중
                      </V2Text>
                    </div>
                  ) : (
                    <div className="badge">
                      <V2Icon
                        name={V2IconOption.name.errorFilled}
                        size={V2IconOption.size.S}
                        fill={V2IconOption.fill.accent_red}
                      />
                      <V2Text
                        element={V2TextOption.element.span}
                        fontStyle={V2TextOption.fontStyle.body_2_sb}
                        color={V2TextOption.color.danger}
                      >
                        미완료
                      </V2Text>
                    </div>
                  )}
                </div>
              </BoxComponent>
              <V2Text
                element={V2TextOption.element.span}
                fontStyle={V2TextOption.fontStyle.body_3_m}
                color={V2TextOption.color.subtlest}
              >
                응시는 PC 환경에서 가능합니다.
              </V2Text>
            </>
          )}
        </Frame>
        <SubmitButtonFrame>
          <V2Button
            type={V2ButtonOption.type.OutLine}
            size={V2ButtonOption.size.L}
            fontStyle={V2ButtonOption.fontStyle.body_1_sb}
            color={V2ButtonOption.color.subtle}
            buttonProps={{ onClick: viewPreview }}
          >
            미리보기
          </V2Button>
          <V2Button
            type={V2ButtonOption.type.Fill}
            size={V2ButtonOption.size.L}
            fontStyle={V2ButtonOption.fontStyle.body_1_sb}
            color={V2ButtonOption.color.inverse}
            fill={V2ButtonOption.fillType.brand_strong_default}
            buttonProps={{
              onClick: handleApply,
              disabled:
                (!!positionJdModel.profileAdditionalInfo && !additionAbled) ||
                profileCheck ||
                (positionJdModel.positionAccStatus?.accApplyRequiredYn &&
                  positionJdModel.positionAccStatus?.accLiteStatus !==
                    AccLiteStatusType.COMPLETED),
            }}
          >
            제출하기
          </V2Button>
        </SubmitButtonFrame>
        {isPreview && profileModel.previewProfile && (
          <ProfilePreviewModal
            data={profileModel.previewProfile}
            additionInfo={previewAdditionInfo}
            handleClose={() => setIsPreview(false)}
          />
        )}
        {isOpenAdditionInfoModal && (
          <ProfileAdditionInfoModal
            useFormed={useFormed}
            handleClose={() => setIsOpenAdditionInfoModal(false)}
          />
        )}
      </FormProvider>
    </JDFullModal>
  );
};

export default inject(
  injectStore.profileModel,
  injectStore.positionJdModel,
)(observer(ProfileApplyModal));
