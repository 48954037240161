import IndicatorLottie from '__designkit__/assets/lottie/tutorial_indicate_lottie.json';
import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { V2BackgroundColors, V2BackgroundColorsType } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { ReactComponent as IconRadio } from 'assets/_v2/_common/icon_radio.svg';
import { ReactComponent as IconRadioClicked } from 'assets/_v2/_common/icon_radio_clicked.svg';
import { IconLogo } from 'consts/assets/icons/iconPages';
import Lottie from 'lottie-react';
import { useUserInfo } from 'query/user/useUserInfoQuery';
import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { formatDateWithDayOfWeek, getCreatedDayTime } from 'utils/DateUtils';
import MainTutorialIndicator from './MainTutorialIndicator';
import MainTutorialWaiting from './MainTutorialWaiting';

const dimOpacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
`;
const Dim = styled.div<{backgroundColor:V2BackgroundColorsType}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => V2BackgroundColors[props.backgroundColor]};
  opacity: 0.6;
  animation: ${dimOpacityAnimation} 0.3s ease-out;
  animation-duration: 0.4s;
  z-index: 10004;
`;

const Frame = styled.div`
    display: flex;
    flex-direction: column;

`;
const TitleFrame = styled.div`
    display: flex;
    align-items: center;
    gap:8px;
    .jda-icon{
        display: flex;
        width: 24px;
        height: 24px;
        padding: 0 3px;
        justify-content: center;
        align-items: center;
        border: 0.5px solid ${V2BorderColors.default};
        border-radius: 24px;
    }
    .name-text{
        font: ${Fonts.B2_Bold};
        color: ${Colors.CG_90};
    }
    .time-text{
        font: ${Fonts.B3_Medium};
        color: ${Colors.cA4A4A4};
    }
`;

const MessageFrame = styled.div`
    display: flex;
    flex-direction: column;
    padding:24px;
    width: 100%;
    border-radius: 2px 12px 12px 12px;
    background-color: ${Colors.CG_30};
  position:relative;
    .title-text{
        white-space: pre;
        font :${Fonts.H5_Bold};
        color:${Colors.JOBDA_BLACK};
    }
    .content-text{
        font:${Fonts.B2_Medium_P};
        color:${Colors.CG_70};
    }
    .sub-text-frame{
        display: flex;
        margin-top: 16px;
        .sub-text{
            font:${Fonts.B2_Bold_P};
        color:${Colors.CG_90};
        }
        .sub-time{
          margin-left: 12px;
            color:${Colors.CG_70};
            font:${Fonts.B2_Medium_P};
        }
    }
    .info-text{
      color:${Colors.CG_60};
      font:${Fonts.B3_Medium_P};
      margin-top: 4px;
    }
`;

const BtnFrame = styled.div<{step?:number}>`
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  position:relative;
  .accept-btn{
    z-index: ${(props) => (props.step === 5 ? 10006 : 1)};
  }
  .refuse-btn{
    border:1px solid ${Colors.CG_60};
  }
  .lottie-indicator {
        position: absolute;
        top:22px;
    left: 50%; 
    transform: translate(-50%, -50%); 
        width: 50px;
        height: 50px;
        z-index: 10006;
      }
`;
const ClickTotalFrame = styled.div`
display: flex;
flex-direction: column;
position:relative;
  .lottie-indicator {
        position: absolute;
        top:2px;
    right: 6px; 
        width: 50px;
        height: 50px;
        z-index: 10006;
      }`;
const ClickFrame = styled.div<{step?:number}>`
  display: flex;
  padding:16px 20px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  border:1px solid ${Colors.CG_50};
  border-radius: 8px;
  z-index:${(props) => (props.step === 4 ? 10005 : 1)};
  background-color: ${Colors.CG_30};
  .title-text{
    font:${Fonts.B2_Bold};
    color:${Colors.CG_90};
    width: 215px;
    white-space: pre-line;
  }
  .radio{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${Colors.WHITE_100};
    border: 1.6px solid ${Colors.CG_50};
  }
  &:not(:last-child){
    margin-bottom: 8px;
  
  }
`;

const ClickedFrame = styled.div<{step?:number}>`
  display: flex;
  padding:16px 20px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  border:1px solid ${Colors.JOBDA_BLACK};
  border-radius: 8px;
  z-index:1;
  background-color: ${Colors.WHITE_100};
  .title-text{
    font:${Fonts.B2_Bold};
    color:${Colors.CG_90};
    width: 215px;
    white-space: pre-line;
  }
  .radio{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${Colors.WHITE_100};
    border: 1.6px solid ${Colors.CG_50};
  }
  &:not(:last-child){
    margin-bottom: 8px;
  
  }
`;

interface IMainTutorialCompanyMessageInterview{
  setStep : React.Dispatch<React.SetStateAction<number>>;
  step:number;
}

const MainTutorialCompanyMessageInterview:FC<IMainTutorialCompanyMessageInterview> = ({ setStep, step }) => {
  const { data: userInfoData } = useUserInfo();
  const [backgroundColor, setBackgroundColor] = useState<V2BackgroundColorsType>(V2BackgroundColorsType.transparent);
  const [isIndicator3rdVisible, setIsIndicator3rdVisible] = useState<boolean>(false);
  const [isIndicator4thVisible, setIsIndicator4thVisible] = useState<boolean>(false);
  const [isWaitingVisible, setIsWaitingVisible] = useState<boolean>(false); // Waiting 상태 추가
  useEffect(() => {
    const timer = setTimeout(() => {
      if (step === 4) {
        setBackgroundColor(V2BackgroundColorsType.dimmer); // 배경색을 회색으로 설정
        setIsIndicator3rdVisible(true);
      }
    }, 500);

    return () => clearTimeout(timer); // 컴포넌트가 언마운트되면 타이머를 정리
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (step === 5) {
        setBackgroundColor(V2BackgroundColorsType.dimmer); // 배경색을 회색으로 설정
        setIsIndicator4thVisible(true);
      }
    }, 500);

    return () => clearTimeout(timer); // 컴포넌트가 언마운트되면 타이머를 정리
  }, [step]);

  const handleClickToStep4 = () => {
    if (step === 4) {
      setBackgroundColor(V2BackgroundColorsType.transparent);
      setIsIndicator3rdVisible(false);
      setStep(5);
    }
  };

  const handleClickToStep5 = () => {
    if (step === 5) {
      setIsIndicator4thVisible(false); // 인디케이터 숨김
      setIsWaitingVisible(true); // Waiting 컴포넌트 표시

      // 5초 후에 다음 스텝으로 이동
      setTimeout(() => {
        setIsWaitingVisible(false); // Waiting 컴포넌트 숨김
        setStep(6); // 다음 스텝으로 이동
      }, 6500);
    }
  };

  const today = new Date();
  const todayISOString = today.toISOString();

  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  const tomorrowISOString = tomorrow.toISOString();

  const dayAfterTomorrow = new Date();
  dayAfterTomorrow.setDate(today.getDate() + 2);
  const dayAfterTomorrowISOString = dayAfterTomorrow.toISOString();
  return (
    <>
      <Dim backgroundColor={backgroundColor} />

      <Frame>
        <TitleFrame>
          <div className='jda-icon'>
            <IconLogo className='logo' fill={Colors.JOBDA_BLACK} />
          </div>
          <div className='name-text'>잡다매칭 담당자</div>
          <div className='time-text'>{getCreatedDayTime(todayISOString)}</div>
        </TitleFrame>
        <SpacingBlock size={8} vertical />
        <MessageFrame>
          <div className='title-text'>[잡다매칭] 매칭 튜토리얼 직무 채용
            - 면접 제안
          </div>
          <SpacingBlock size={16} vertical />
          <div className='content-text'>
            {
              `
            안녕하세요. ${userInfoData?.name}님, 잡다 매칭입니다.
            ${userInfoData?.name}님께서 지니신 역량과 기술, 지식이 저희 팀에 필요한 인재라고 판단하여 입사 제안을 드립니다. 이번 면접이 저희와 함께 성장할 수 있는 소중한 기회가
            되기를 진심으로 기원합니다. 면접 제안 드리는 포지션은 ‘매칭 튜토리얼’입니다.

            면접을 수락해주시면, 상세한 일정을 안내해 드리도록 하겠습니다. 제안드리는 연봉의 경우 최소 3000만원에서 최대 4000만원 수준입니다.
              `
            }

          </div>
          <div className='sub-text-frame'>
            <div className='sub-text'>면접 시간</div>
          </div>
          <SpacingBlock size={8} vertical />
          {
            step === 4 && (
            <ClickTotalFrame>
              <MainTutorialIndicator titleText='면접 일정 선택하기' descText='원하는 날짜를 선택해 면접을 예약하세요.' stepText='04' buttonText='다음' position='top' onClickNext={handleClickToStep4} visible={isIndicator3rdVisible} />
              {
                step === 4 && (
                  <div role='button' onClick={() => handleClickToStep4()}>
                    <Lottie animationData={IndicatorLottie} className='lottie-indicator' />
                  </div>
                )
              }
              <ClickFrame step={step} key={0} role='button' onClick={() => handleClickToStep4()}>
                <div className='title-text'>{ formatDateWithDayOfWeek(tomorrowISOString)}</div>
                <IconRadio className='radio' />
              </ClickFrame>
              <ClickFrame step={step} key={1} role='button'>
                <div className='title-text'>{formatDateWithDayOfWeek(dayAfterTomorrowISOString)}</div>
                <IconRadio className='radio' />
              </ClickFrame>
            </ClickTotalFrame>
            )
          }
          {
            step >= 5 && (
            <ClickTotalFrame>
              <ClickedFrame step={step} key={0} role='button'>
                <div className='title-text'>{formatDateWithDayOfWeek(tomorrowISOString)}</div>
                <IconRadioClicked className='radio' />
              </ClickedFrame>
              <ClickFrame step={step} key={1} role='button'>
                <div className='title-text'>{formatDateWithDayOfWeek(dayAfterTomorrowISOString)}</div>
                <IconRadio className='radio' />
              </ClickFrame>
            </ClickTotalFrame>
            )
          }

          <div className='sub-text-frame'>
            <div className='sub-text'>응답 기한</div>
            <div className='sub-time'>{formatDateWithDayOfWeek(todayISOString)}</div>
          </div>
          <div className='info-text'>정해진 유효기간까지 제출하지 않을 경우 제출이 불가능합니다.</div>
          <SpacingBlock size={40} vertical />
          {/* MainTutorialIndicator */}
          <MainTutorialIndicator
            titleText='면접 수락하기'
            descText='면접을 수락하고, 기업과의 첫 만남을 시작해보세요'
            stepText='05'
            buttonText='다음'
            position='top'
            onClickNext={handleClickToStep5}
            visible={isIndicator4thVisible}
          />
          {/* MainTutorialWaiting */}
          {isWaitingVisible && (
          <MainTutorialWaiting
            titleText='합격까지 거의 다 왔어요!'
            descText={`면접 제안을 수락하셨네요! 수락 이후 과정은
기업과 직접 진행되고 잡다 홈페이지의 지원현황과 매칭 현황 페이지에서 확인할 수 있습니다.`}
            stepText='05'
            position='top'
            visible={isWaitingVisible}
          />
          )}
          <BtnFrame step={step}>
            <Button
              className='primary-btn'
              buttonMode={ButtonMode.PRIMARY}
              size='large'
              label='수락하기'
            />
            <SpacingBlock size={8} vertical />
            <Button
              className='refuse-btn'
              textColor={Colors.CG_70}
              backgroundColor='transparent'
              buttonMode={ButtonMode.CUSTOM}
              size='large'
              label='거절하기'
            />
          </BtnFrame>
        </MessageFrame>
      </Frame>
    </>
  );
};
export default MainTutorialCompanyMessageInterview;
