import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import DisableScroll from 'components/common/DisableScroll';
import { useMatchHomeMatchingTutorialAlarm } from 'query/match/useMatchQuery';
import MainTutorialMain from './MainTutorialMain';
import MainTutorialNotification from './MainTutorialNotification';
import MainTutorialMessage from './MainTutorialMessage';
import MainTutorialAlarm from './MainTutorialAlarm';

const Frame = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: 'transparent';
`;

const ContentsFrame = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
`;

const BtnFrame = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 16px;
`;
interface IMainTutorialModalSecond{
  onClose:()=>void;
  isOpen?:boolean;
  step:number;
    setStep:React.Dispatch<React.SetStateAction<number>>;
}

const MainTutorialModalSecond:FC<IMainTutorialModalSecond> = ({ isOpen, onClose, step, setStep }) => {
  const contentsRef = useRef<HTMLDivElement>(null);
  // 스크롤 방지 핸들러
  const preventScroll = useCallback((e: TouchEvent | WheelEvent) => {
    if (contentsRef.current && e.target instanceof HTMLElement && contentsRef.current.contains(e.target)) {
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    // Wheel 및 Touch 이벤트 리스너 추가
    window.addEventListener('wheel', preventScroll, { passive: false });
    window.addEventListener('touchmove', preventScroll, { passive: false });

    return () => {
      // 이벤트 리스너 제거
      window.removeEventListener('wheel', preventScroll);
      window.removeEventListener('touchmove', preventScroll);
    };
  }, [preventScroll]);

  if (!isOpen) return null;
  return (
    <JDFullModal>
      <Frame>
        <ContentsFrame ref={contentsRef}>
          {step === 2 && <MainTutorialNotification setStep={setStep} />}
          {step >= 3 && <MainTutorialMessage onClose={onClose} step={step} setStep={setStep} />}
        </ContentsFrame>
      </Frame>
    </JDFullModal>
  );
};
export default MainTutorialModalSecond;
