import {
  SignInWithApple,
  SignInWithAppleOptions,
  SignInWithAppleResponse,
} from '@capacitor-community/apple-sign-in';
import IconApple from 'assets/icon/icon_login_social_apple.svg';
import { OpenIdProvider } from 'interfaces/rqrs/ISocialAuthLoginRs';
import { LanguageType } from 'pages/_v2/jobflex/JobflexLogin';
import { FC } from 'react';
import MobileStore from 'store/mobileStore';
import getEnvURI from 'utils/EnvUtil';

interface IAppleLoginButton{
    onSuccess:any;
    onFail: any;
    languageType?:LanguageType

}

const AppleLogin: FC<IAppleLoginButton> = ({ onSuccess, onFail, languageType = LanguageType.KOR }) => {
  const onClickMobileAppleLogin = async () => {
    try {
      const options: SignInWithAppleOptions = {
        clientId: 'io.ionic.jobdaIdentifier',
        // clientId: 'io.ionic.jobda',
        redirectURI: `${getEnvURI()}/oauth`,
        scopes: 'email name',
      };

      SignInWithApple.authorize(options)
        .then((result: SignInWithAppleResponse) => {
          onSuccess(result.response.authorizationCode, OpenIdProvider.APPLE);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      onFail(e);
    }
  };

  return (
    <>{
          MobileStore.currentPlatform !== 'android'
            && (
              <button
                className='social-login-btn apple'
                onClick={(e) => {
                  e.preventDefault();
                  onClickMobileAppleLogin();
                }}
              >
                <img alt='애플 로그인' src={IconApple} />
                <span>{languageType === LanguageType.KOR ? 'Apple로 로그인' : 'Apple'}</span>
              </button>
            )
        }
    </>
  );
};

export default AppleLogin;
