import colors from '__designkit__/common/colors';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { ChangeEvent, FC } from 'react';
import styled from 'styled-components/macro';

export const StyledSlider = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  border: 2px solid;
  border-radius: 40px;
  background: ${colors.WHITE_100};
  transition: 0.25s;
`;

export const StyledText = styled.button<{ lenght?: number }>`
  min-width: 40px;
  height: 20px;
  transition: 0.25s;
  pointer-events: none;
  span {
    ${(props) => props.lenght && props.lenght > 0 && `width:${25 * props.lenght}px`};
    vertical-align: middle;
    color: ${colors.WHITE_100};
    ${fonts.NOTO_13_400}
    ${lineHeights.LINEHEIGHT_1_50}
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:center;
  }
`;

const ToggleSwitchFrame = styled.label`
  position: relative;
  display: inline-block;
  height: 20px;
  width: 40px;
  border-radius: 40px;
  overflow: hidden;
  cursor: pointer;
  
  .switch-content {
    display: inline-block;
  }
      
  input {
    display:none;
    opacity: 0;
    width: 0;
    height: 0;
    &:disabled {
      ~ .switch-content {
        opacity: 0.5;
      }
    }
    &:checked {
      ~ .switch-content {
        ${StyledSlider} { 
          left: calc(100% - 20px);
          border-color: ${colors.G_100};
        }
        ${StyledText} {
          background: ${colors.G_100};
          padding: 0 32px 0 12px;
        }
      }
    }
    &:not(:checked) {
      ~ .switch-content {
        ${StyledSlider} { 
          left: 0;
          border-color: ${colors.CG_60};
        }
        ${StyledText} {
          background: ${colors.cDBDDE0};
          padding: 0 12px 0 32px;
        }      
      }
    }
  }
  
  &:hover {
    input:not(:disabled) {
      &:checked {
        ~ .switch-content {
          ${StyledText} {
            background: ${colors.c3ED160};
          }
        }
      }
      &:not(:checked) {
        ~ .switch-content {
          ${StyledText} {
            background: ${colors.cCACCCF};
          }
        }
      }
    }
  }
`;

interface ICheckBoxProps extends IComponentProps {
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  onTitle?: string;
  offTitle?: string;
  hidden?:boolean
}

const ToggleSwitch: FC<ICheckBoxProps> = (props: ICheckBoxProps) => {
  const { checked = false, hidden = false, onChange, onTitle, offTitle, disabled, className, ...rest } = props;

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange)onChange(e);
  };

  return (
    <ToggleSwitchFrame hidden={hidden} className={className}>
      <input
        type='checkbox'
        onChange={onChangeHandler}
        checked={checked}
        disabled={disabled}
        {...rest}
      />
      <div className='switch-content'>
        <StyledText lenght={onTitle ? onTitle.length : 0}>
          <span>{ checked ? onTitle : offTitle }</span>
        </StyledText>
        <StyledSlider />
      </div>
    </ToggleSwitchFrame>
  );
};

export default ToggleSwitch;
