import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { IconArrowBack, IconLogo } from 'consts/assets/icons/iconPages';
import NoticeDialog, { DialogType } from 'components/modals/NoticeDialog';
import VideoRecordIntro from './VideoRecordIntro';
import VideoRecordEnding from './VideoRecordEnding';
import VideoRecordCheck from './VideoRecordCheck';
import VideoRecorder from './VideoRecorder';
import { VideoRecordNotice1, VideoRecordNotice2, VideoRecordNotice3, VideoRecordNotice4 } from './VideoRecordNotice';
import ImageUploader from './VideoRecordPhotos';

const Frame = styled.div`
  position: relative;

  .btn-back {
    position: absolute;
    display: block;
    top: 12px;
    left: 12px;
    width: 40px;
    height: 40px;
    z-index: 9;

    &:disabled {
      display: none;
    }
  }
  
  .step-content {
    height: 100vh;
    overflow-y: auto;

    .desc {
      padding: 96px ${SpaceValue.L}px 88px ${SpaceValue.L}px;
    }
  }

  .action-buttons {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    left: 0;
    width: 100%;
    padding: ${SpaceValue.L}px ${SpaceValue.L}px ${SpaceValue.XL}px ${SpaceValue.L}px;
    background-color: white;
  }
`;

const Header = styled.div<{ isTransparent: boolean }>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: ${(props) => (props.isTransparent ? 'transparent' : 'white')};
  z-index: 1;
`;

const VideoRecordStep = () => {
  const totalSteps = 10; // 총 10단계
  const [currentStep, setCurrentStep] = useState(0);
  const [mediaBlobUrl, setMediaBlobUrl] = useState<string | null>(null);
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const [isVideoMoveOutModal, setIsVideoMoveOutModal] = useState(false);
  const stepButtonNames = [
    '시작',
    '모든 내용을 확인했어요',
    '모든 내용을 확인했어요',
    '모든 내용을 확인했어요',
    '촬영 시작',
    '제출하기',
    '제출하기',
    '확인',
    '촬영 완료',
    '촬영 완료',
  ];

  const handleNextStep = () => {
    if (currentStep < totalSteps - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      if (currentStep === 7) {
        setIsVideoMoveOutModal(true);
      }
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  return (
    <Frame>
      {/* 로고 헤더 */}
      {
        currentStep !== 6 && (
          <>
            <Header isTransparent={currentStep === 0 || currentStep === 9}>
              <IconLogo className='logo' />
            </Header>
            <button
              className='btn-back'
              disabled={currentStep === 0}
            >
              <IconArrowBack onClick={handlePreviousStep} />
            </button>
          </>
        )
      }

      <div className='step-content'>
        {/* 각 단계의 콘텐츠 */}
        {currentStep === 0 && <VideoRecordIntro />}
        {currentStep === 1 && <div className='desc'><VideoRecordNotice1 /></div>}
        {currentStep === 2 && <div className='desc'><VideoRecordNotice2 /></div>}
        {currentStep === 3 && <div className='desc'><VideoRecordNotice3 /></div>}
        {currentStep === 4 && <div className='desc'><VideoRecordNotice4 /></div>}
        {currentStep === 5 && <div className='desc'>6단계: 음성 녹음 체크</div>}
        {currentStep === 6 && <VideoRecorder videoBlob={videoBlob} setVideoBlob={setVideoBlob} setCurrentStep={setCurrentStep} mediaBlobUrl={mediaBlobUrl} setMediaBlobUrl={setMediaBlobUrl} />}
        {currentStep === 7 && <VideoRecordCheck setMediaBlobUrl={setMediaBlobUrl} videoBlob={videoBlob} setVideoBlob={setVideoBlob} setCurrentStep={setCurrentStep} mediaBlobUrl={mediaBlobUrl} />}

        {currentStep === 8 && <div className='desc'>9단계: 사진 저장</div>}
        {currentStep === 9 && <VideoRecordEnding />}
      </div>

      {
  currentStep !== 6 && currentStep !== 7 && (
    <div className='action-buttons'>
      <V2Button
        type={V2ButtonOption.type.Fill}
        size={V2ButtonOption.size.L}
        buttonProps={{ onClick: handleNextStep, disabled: currentStep === totalSteps - 1 }}
        fill={V2ButtonOption.fillType.brand_strong_default}
        fontStyle={V2ButtonOption.fontStyle.body_1_sb}
        color={V2ButtonOption.color.inverse}
        styles={{ width: '100%' }}
      >
        {stepButtonNames[currentStep]}
      </V2Button>
    </div>
  )
}
      <NoticeDialog
        dialogType={DialogType.ALERT}
        title='저장 어쩌구 안대영'
        content='작성 중인 추가 정보는 저장되지 않습니다.'
        isOpen={isVideoMoveOutModal}
        onOk={() => {
          setMediaBlobUrl(null);
          setVideoBlob(null);
          setIsVideoMoveOutModal(false);
          setCurrentStep(6);
        }}
        onCancel={() => setIsVideoMoveOutModal(false)}
        firstButtonText='나가기'
        secondButtonText='취소'
      />
    </Frame>
  );
};

export default inject(injectStore.login, injectStore.profileModel, injectStore.context)(observer(VideoRecordStep));
