import Icon from '__designkit__/icon/Icon';
import React, { FC } from 'react';
import styled from 'styled-components';
import ImgBgIntro from 'assets/_v2/profile/bg_intro.png';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import ImgCamera from 'assets/_v2/profile/img_videoRecord.png';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 64px;
  height: calc(100vh - 90px);
  background-image: url(${ImgBgIntro});
  background-size: cover;
  background-position: center;

  img {
    width: 240px;
    height: 240px;
  }
`;

const ContentsFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const VideoRecordIntro = () => (
  <Frame>
    <ContentsFrame>
      <img src={ImgCamera} alt='자기소개 영상 이미지' />
      <V2Text fontStyle={V2FontStyleType.body_2_b} color={V2TextColorsType.brand}>매칭 튜토리얼</V2Text>
      <SpacingBlock size={SpaceValue.XS} vertical />
      <V2Text fontStyle={V2FontStyleType.title_1_b} color={V2TextColorsType.default}>매칭 경험 체험하기</V2Text>
      <SpacingBlock size={SpaceValue.L} vertical />
      <V2Text fontStyle={V2FontStyleType.body_1_m} color={V2TextColorsType.subtle}>
        매칭 제안을 통해 입사지원을
        <br />
        경험할 수 있는 튜토리얼이에요.
        <br />
        지금 바로 입사 지원 제안을 경험해 보세요!
      </V2Text>
    </ContentsFrame>
  </Frame>
);

export default VideoRecordIntro;
