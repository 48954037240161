import React from 'react';
import styled, { css } from 'styled-components';
import Colors from '__designkit__/common/colors';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2FontStyle, V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextColors, V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { SpaceType, SpaceValue } from '__pc__/constant/v2Design/SpaceType';

const PopOverWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const PopOverContent = styled.div<{ position: 'top' | 'bottom' }>`
  position: absolute;
  left: 50%; 
  transform: translateX(-50%); 
  background-color: ${Colors.WHITE_100};
  color: ${Colors.JOBDA_BLACK};
  border: 1px solid ${Colors.CG_40};
  border-radius: 8px;
  padding: ${SpaceValue.XL}px;
  width: 328px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  z-index: 100006;

  .title-frame{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  ${(props) => (props.position === 'top'
    ? css`
          bottom: 100%;
          margin-bottom: 30px;
          &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            border-width: 12px;
            border-bottom-width: 24px;
            border-style: solid;
            border-color: ${Colors.WHITE_100} transparent transparent transparent;
          }
        `
    : css`
          top: 100%;
          margin-top: 30px;
          &::after {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            border-width: 12px;
            border-bottom-width: 24px;
            border-style: solid;
            border-color: transparent transparent ${Colors.WHITE_100} transparent;
          }
        `)}
`;

interface MainTutorialIndicatorProps {
  position?: 'top' | 'bottom';
  onClickNext: () => void;
  visible:boolean
  buttonText:string;
  titleText:string;
  descText:string;
  stepText:string;
}

const MainTutorialIndicator: React.FC<MainTutorialIndicatorProps> = ({
  position = 'top', // 기본값을 'top'으로 설정
  onClickNext,
  visible,
  buttonText,
  titleText,
  descText,
  stepText,
}) => {
  if (!visible) return null;
  return (
    <PopOverWrapper>
      <PopOverContent position={position}>
        <div className='title-frame'>
          <V2Text element={V2TextOption.element.div} fontStyle={V2FontStyleType.body_1_b} color={V2TextColorsType.default}>{titleText}</V2Text>
          <V2Text element={V2TextOption.element.div} fontStyle={V2FontStyleType.body_3_sb} color={V2TextColorsType.subtlest}>{stepText}/06</V2Text>
        </div>
        <V2Text fontStyle={V2FontStyleType.body_2_m} color={V2TextColorsType.subtle}>
          {descText}
        </V2Text>
        <SpacingBlock size={SpaceValue.XL as number} vertical />
        <V2Button
          type={V2ButtonOption.type.Fill}
          size={V2ButtonOption.size.L}
          buttonProps={{ onClick: onClickNext }}
          fill={V2ButtonOption.fillType.brand_strong_default}
          fontStyle={V2ButtonOption.fontStyle.body_1_m}
          color={V2ButtonOption.color.inverse}
          styles={{ width: '100%' }}
        >
          {buttonText}
        </V2Button>
      </PopOverContent>
    </PopOverWrapper>
  );
};

export default MainTutorialIndicator;
