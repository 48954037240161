import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import { IconClose } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import { useLocation } from 'react-router';

export interface IJDFullScreenModalProps extends IComponentProps {
  isOpen?: boolean;
  onClose?: () => void;
  buttonType?: 'type1' | 'type2' | 'none';
  customZIndex?:boolean;
}

// const TIMEOUT_MILLISECOND = 500;

const JDAFullScreenModalFrame = styled.section<{customZIndex?:boolean}>`
  display:block;
  position:fixed;
  z-index:10005;
  z-index:${(props) => (props.customZIndex ? 10010 : 10005)};
  width:100%;
  height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  background:${colors.WHITE_100};
  top:0;
  left:0;
  overflow-y: auto;
  animation-name: open;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;

  .btn-close {
    position: absolute;
    right:16px;
    top:16px;
    background:none;
  }
  &.closing {
    opacity:0;
  }
  @keyframes open {
    0% {
      transform:translateY(100%);
      opacity:1;
    }
    100% {
      transform:translateY(0%);
      opacity:1;
    }
  }

  &.overflow-hidden {
    overflow-y: hidden;
  }

`;
const GetOutChip = styled.div`
  width: 50px;
  height: 28px;
  background: ${colors.CG_90};
  padding: 8px 12px;
  border-radius: 50px;
  span{
    display: flex;
    font: ${Fonts.B4_Bold};
    color:${colors.WHITE_100}
  }
`;
const JDFullScreenBaseModal: FC<IJDFullScreenModalProps> = ({ customZIndex = false, isOpen, onClose, buttonType = 'type1', className, children, ...props }) => {
  const [closing, setClosing] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    if (isOpen)
      setClosing(false);
  }, [isOpen]);

  const handleCloseDrawer = () => {
    onClose && onClose();
  };
  const preventGoBack = () => {
    window.history.pushState(null, '', document.location.href);
    onClose && onClose();
  };
  useEffect(() => {
    if (isOpen) {
      window.history.pushState(null, '', location.pathname + location.search);
      window.addEventListener('popstate', preventGoBack);
      return () => {
        window.removeEventListener('popstate', preventGoBack);
        handleCloseDrawer();
      };
    }
  }, [isOpen]);

  return (
    !isOpen ? <></>
      : (
        <Portal>
          <DisableScroll />
          <JDAFullScreenModalFrame customZIndex={customZIndex} {...props} className={`${className ?? ''} ${closing ? 'closing' : ''}`}>
            {children}
            {buttonType !== 'none'
              ? (
                <button
                  className='btn-close'
                  title='닫기'
                  onClick={async () => {
                    setClosing(true);
                    onClose && onClose();
                  }}
                >
                  {buttonType === 'type1'
                    ? <IconClose />
                    : (
                      <GetOutChip>
                        <span>나가기</span>
                      </GetOutChip>
                    )}
                </button>
              )
              : <></>}
          </JDAFullScreenModalFrame>
        </Portal>
      )
  );
};

export default JDFullScreenBaseModal;
