import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IconDumy from 'assets/_v2/profile/img_profile_base_info_dumy.png';
import JDALink from 'components/JDALink';
import { MatchApplyStatusTabType } from 'components/_v2/matchApply/matchApplyStatus/MatchApplyStatusBoard';
import MatchServiceConditionInductionModal from 'components/_v2/profile/match/MatchServiceConditionInductionModal';
import MatchServiceToggle from 'components/_v2/profile/match/MatchServiceToggle';
import RoutePaths from 'consts/RoutePaths';
import { LoggingType } from 'consts/_v2/LoggingType';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import MatchApplyStatusModel from 'models/_v2/MatchApplyStatusModel';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import ProfileBasicInfoModel from 'models/_v2/profile/ProfileBasicInfoModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled, { keyframes } from 'styled-components';
import useAccResults from 'query/acc/useAccResultQuery';
import { set } from 'js-cookie';
import PositionListModel from 'models/_v2/PositionListModel';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { usePositionProfileMatching } from 'query/position/usePositionQuery';
import { JobSearchStatus, JobSearchStatusTypeText } from 'consts/MatchType';
import { V2FontStyleDirect, V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextColors } from '__pc__/constant/v2Design/Colors/V2TextColors';
import V2Badge from '__pc__/components/common/v2Design/badge';
import { V2BadgeOption } from '__pc__/constant/v2Design/V2BadgeType';
import { useMatchHomeDashboard } from 'query/match/useMatchQuery';
import { PhsAccType } from 'consts/MatchingMessageType';
import MatchJobSearchStatusModal from '../matchApply/MatchJobSearchStatusModal';
import MatchingBanner from './MatchingBanner';
import MatchApplySubmitModal from '../matchApply/MatchApplySubmitModal';

// 스켈레톤 애니메이션
const shine = keyframes`
  0% { background-position: -40px; }
  100% { background-position: 100%; }
`;

const SkeletonWrapper = styled.div`
  animation: ${shine} 1s linear infinite;
  background-image: linear-gradient(90deg, ${colors.CG_30} 0px, ${colors.JOBDA_WHITE} 40px, ${colors.CG_30} 80px);
  background-size: 200% 100%;
`;

const SkeletonProfile = styled(SkeletonWrapper)`
  flex: none;
  width: 64px;
  height: 64px;
  border-radius: 32px;
`;

const SkeletonText = styled(SkeletonWrapper)`
  height: 20px;
  width: 100%;
  border-radius: 4px;
`;

const SkeletonLargeText = styled(SkeletonWrapper)`
  height: 66px;
  width: 100%;
  border-radius: 4px;
`;

const SkeletonDashboard = styled(SkeletonWrapper)`
  margin-top: 8px;
  height: 124px;
  width: 100%;
  border-radius: 4px;
`;

const SkeletonCount = styled(SkeletonWrapper)`
  height: 30px;
  width: 30px;
  border-radius: 15px;
`;

const SkeletonBanner = styled(SkeletonWrapper)`
  height: 100px;
  width: 100%;
  border-radius: 8px;
`;

const Frame = styled.div`
  padding: 24px 16px 12px 16px;

  .my-state {
    display: flex;
    align-items: center;
    gap: ${SpaceValue.M}px;
    padding: 0 8px;

    .profile {
      flex: none;

      .img-profile {
        width: 64px;
        height: 64px;
        border-radius: 32px;
        border: 1px solid ${V2BorderColors.default};
      }
    }

    .profile-state {
      display: flex;
      flex-direction: column;
      gap: ${SpaceValue.XXXS}px;
      width: 100%;

      .state-inner {
        position: relative;
        display: flex;
        gap: ${SpaceValue.L}px;
        
        .matching-setting {
          position: relative;
          display: flex;
          align-items: center;

          &::after {
            content: '';
            position: absolute;
            top: 9px;
            left: -8px;
            width: 1px;
            height: 8px;
            background-color: ${colors.CG_40}; 
          }

          .matching-on {
            font: ${Fonts.B1_Bold};
            color: ${colors.G_150};
          }

          .matching-off {
            display: flex;
            align-items: center;
            gap: ${SpaceValue.XXS}px;
          }
        }
      }

      .message {
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_70};
      }
    }
  }

  /* 매칭 상태 대시보드 */
  .dashBoardBox {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: ${SpaceValue.XL}px;
    margin-top: ${SpaceValue.XS}px;
    padding: ${SpaceValue.L}px;
    height: 124px;
    border-radius: ${RadiusValue.S}px;
    border: 1px solid ${V2BorderColors.default};

    .progressBar {
      position: relative;
      width: 100%;
      height: 8px;
      background-color: #f7f7f7;
      margin-top: 10px;
      border-radius: 5px;
      overflow: hidden;

      /* gap 공간 */
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: 33%;
        width: 6px;
        height: 8px;
        background-color: ${V2BackgroundColors.surface_default};
      }

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        right: 33%;
        width: 6px;
        height: 8px;
        background-color: ${V2BackgroundColors.surface_default};
        z-index: 1;
      }

      .progress {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: ${RadiusValue.CIRCULAR}px;
        transition: width 3s ease;
      }
    }

    /* 회원 상태 */
    .userInfoState {
      display: flex;
      gap: 24px;

      .state {
        position: relative;
        display: flex;
        align-items: center;
        flex: none;
        gap: ${SpaceValue.XS}px;

        :nth-child(2) {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 8px;
            left: -12px;
            display: inline-block;
            width: 1px;
            height: 8px;
            background-color: ${V2BorderColors.default};
          }
        }

        .badge {
          cursor: pointer;
        }
      }

      .accaResult {
        position: absolute;
        top: 12px;
        right: 24px;
      }
    }
  }


  /* 북마크/매칭제안/지원현황 */
  .state-count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0 24px 0;
    padding: 0 12px;
    height: 74px;
    border-radius: 8px;
    border: 1px solid ${V2BorderColors.default};
    background-color: ${V2BackgroundColors.surface_alternative};

    .state-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      width: 33%;

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 13px;
        right: 0;
        width: 1px;
        height: 14px;
        background-color: ${colors.CG_40};
      }

      .count {
        font: ${V2FontStyleDirect.body_1_b};
        color: ${V2TextColors.default};

        &.new-count {
          position: relative;
          
          &::after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: -4px;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background-color: ${colors.ERROR};
          }
        }
      }

      .title {
        font: ${V2FontStyleDirect.caption_1_m};
        color: ${V2TextColors.subtlest};
      }
    }
  }
`;

export interface IMyProfileProps {
  login?: Login;
  matchApplyStatusModel?:MatchApplyStatusModel
  matchHomeDashboardModel?:MatchHomeDashboardModel;
  matchApplyModel?: MatchApplyModel
  profileBasicInfoModel?: ProfileBasicInfoModel;
  positionListModel?:PositionListModel;

}

const MyDashboardComponent: FC<IMyProfileProps> = ({ profileBasicInfoModel = new ProfileBasicInfoModel(), positionListModel = new PositionListModel(), login = new Login(), matchApplyStatusModel = new MatchApplyStatusModel(), matchHomeDashboardModel = new MatchHomeDashboardModel(), matchApplyModel = new MatchApplyModel() }) => {
  const [applyCnt, setApplyCnt] = useState<number>(0);
  const history = useHistory();
  const [afterToggle, setAfterToggle] = useState<boolean>(false);
  const [isOpenConditionInductionModal, setIsOpenConditionInductionModal] = useState<boolean>(false);
  const { data: accResults } = useAccResults(!!login.userInfo);
  const { matchingConfigYn } = login.matchConfigStatus || {};
  const [message, setMessage] = useState('');
  const [messageLink, setMessageLink] = useState('');
  const bookmarks = positionListModel.searchBookmarks?.bookmarks;
  const { applicationCount } = matchHomeDashboardModel;
  const [profileCompletePercent, setProfileCompletePercent] = useState<number>(0);
  const { data: positionProfileMatching } = usePositionProfileMatching(11, true);
  const { data: dashboardData, refetch } = useMatchHomeDashboard();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpenJobStateModal, setIsOpenJobStateModal] = useState<boolean>(false);
  const [isOpenSubmitModal, setIsOpenSubmitModal] = useState(false);

  useEffect(() => {
    const init = async () => {
      await profileBasicInfoModel.loadBaseInfo();
      await matchApplyStatusModel.loadMatchStatus();
      await positionListModel.loadMatchBookmarks();
      await login.getMatchConfigStatus();
      await matchHomeDashboardModel.loadApplicationCount();
    };
    if (login.userInfo) {
      init();
    }
  }, [login.userInfo]);

  useEffect(() => {
    setApplyCnt(applicationCount?.applicationCompleteCount || 0);
  }, [applicationCount]);
  const tabs = [
    // { type: MatchApplyStatusTabType.RECOMMEND, count: matchApplyStatusModel.matchStatus?.recommendCount || 0, new: matchApplyStatusModel.matchStatus?.newRecommendYn, label: '추천됨' },
    { type: MatchApplyStatusTabType.MATCH_OFFER, count: dashboardData?.matchingProposalCount, new: matchApplyStatusModel.matchStatus?.newOfferYn, label: '매칭 제안' },
    // { type: MatchApplyStatusTabType.MATCH_INTERVIEW_OFFER, count: matchApplyStatusModel.matchStatus?.interviewOfferCount, new: matchApplyStatusModel.matchStatus?.newInterviewOfferOpenYn, label: '면접 제안' },
  ];

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      if (positionProfileMatching) {
        if (login.userInfo) {
          setProfileCompletePercent(dashboardData?.profilePercent || 0);
        }
      }
    };
    init();
  }, [dashboardData]);

  const handleTabClick = (tab: MatchApplyStatusTabType) => {
    history.push(RoutePaths.match_apply_status_tab(tab));
  };
  useEffect(() => {
    if (afterToggle) {
      setIsOpenConditionInductionModal(true);
      setAfterToggle(false);
    }
  }, [afterToggle]);

  const compare = (dateResult: Date, dateBasic: Date) => {
    const diffTime = Math.abs(dateBasic.getTime() - dateResult.getTime());
    const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
    return diffMonths;
  };

  const applicationDate = new Date(accResults?.resultRecords?.[0]?.applicationDate ?? '');
  const now = new Date();

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let tmpProgress = 0;
    if (dashboardData && login && login.matchConfigStatus && accResults) {
      if (dashboardData.matchingProposalCount > 0) {
        tmpProgress = 90;
      } else if (dashboardData?.profilePercent < 30) {
        tmpProgress = 30;
      } else if (dashboardData?.profilePercent >= 30 && dashboardData?.profilePercent < 60 && accResults.resultRecords.length > 0) {
        tmpProgress = 60;
      }
      if (login.matchConfigStatus.matchingConfigYn === false) {
        tmpProgress = 0;
      }
      if (tmpProgress >= 0 && tmpProgress <= 30) {
        setProgress(30);
      } else if (tmpProgress >= 31 && tmpProgress <= 60) {
        setProgress(60);
      } else if (tmpProgress >= 61 && tmpProgress <= 100) {
        setProgress(90);
      }
    }
  }, [login, login.matchConfigStatus, dashboardData, accResults]);

  const progressBarStyle = {
    width: `${progress}%`,
    background: 'linear-gradient(90deg, #2ca4fb 0%, #02c551 100%)',
  };

  useEffect(() => {
    if (accResults && matchingConfigYn !== undefined) {
      if (!accResults?.resultRecords?.length) {
        setMessage('역량검사 응시하고 잡다에서 취업 준비를 시작하세요!');
        setMessageLink(RoutePaths.acca_test);
      } else if (!matchingConfigYn) {
        setMessage('매칭 ON을 하면 입사지원 제안을 받을 수 있어요!');
        setMessageLink(RoutePaths.match_apply);
      } else if (compare(applicationDate, now) >= 6) {
        setMessage('역량검사로 당신의 가능성을 한번 더 확인해 보세요!');
        setMessageLink(RoutePaths.acca_test);
      } else {
        setMessage('프로필을 상세하게 작성하면 매칭 확률이 높아져요!');
        setMessageLink(RoutePaths.profile);
      }
    }
  }, [accResults, matchingConfigYn]);

  if (!accResults || matchingConfigYn === undefined) {
    return (
      <Frame>
        <>
          {/* 스켈레톤 프로필 이미지/상태 */}
          <div className='my-state'>
            <SkeletonProfile />
            <div className='profile-state'>
              <SkeletonLargeText />
            </div>
          </div>

          {/* 스켈레톤 대시보드 */}
          <div className='dashBoard'>
            <SkeletonDashboard />
          </div>

          {/* 스켈레톤 현황 카운트 */}
          <div className='state-count'>
            <div className='state-item'>
              <SkeletonCount />
              <SkeletonText />
            </div>
            <div className='state-item'>
              <SkeletonCount />
              <SkeletonText />
            </div>
            <div className='state-item'>
              <SkeletonCount />
              <SkeletonText />
            </div>
          </div>

          {/* 배너 */}
          <SkeletonBanner />
        </>
      </Frame>
    );
  }

  return (
    <>
      <Frame>
        { !login.userInfo ? (
          <>
          </>
        ) : (
          <div className='my-state'>
            {/* 프로필 이미지 */}
            <div
              className='profile'
              role='button'
              onClick={() => history.push(RoutePaths.profile)}
            >
              <img
                className='img-profile'
                src={profileBasicInfoModel.baseInfo?.profileImageUrl || IconDumy}
                alt='프로필 사진'
                onError={(e) => {
                  e.currentTarget.src = IconDumy;
                }}
              />
            </div>

            {/* 잡다 현황 */}
            <div className='profile-state'>
              <div className='state-inner'>
                <V2Text
                  element={V2TextOption.element.div}
                  fontStyle={V2TextOption.fontStyle.body_1_b}
                  color={V2TextOption.color.strong}
                >
                  {login.userInfo?.name}님
                </V2Text>
                <div className='matching-setting'>
                  {matchingConfigYn ? (
                    <V2Text
                      element={V2TextOption.element.div}
                      fontStyle={V2TextOption.fontStyle.body_1_sb}
                      color={V2TextOption.color.brand}
                    >
                      매칭 ON
                    </V2Text>
                  ) : (
                    <div className='matching-off'>
                      <V2Text
                        element={V2TextOption.element.div}
                        fontStyle={V2TextOption.fontStyle.body_1_sb}
                        color={V2TextOption.color.disabled}
                      >
                        매칭 OFF
                      </V2Text>
                      <MatchServiceToggle sourcePage={LoggingType.PROFILE} setAfterToggle={setAfterToggle} />
                    </div>
                  )}
                </div>
              </div>
              <a href={messageLink} className='message'>
                <V2Text
                  element={V2TextOption.element.div}
                  fontStyle={V2TextOption.fontStyle.body_2_m}
                  color={V2TextOption.color.subtlest}
                >
                  {message}
                </V2Text>
              </a>
            </div>
          </div>
        )}

        {/* 매칭 상태 */}
        {
          dashboardData && (
            <div className='dashBoardBox'>
              {/* 상태 */}
              <div className='matchingState'>
                <V2Text
                  element={V2TextOption.element.div}
                  fontStyle={V2TextOption.fontStyle.body_2_b}
                  color={V2TextOption.color.default}
                >
                  매칭 상태
                </V2Text>
                <div className='progressBar'>
                  <div className='progress' style={progressBarStyle} />
                </div>
              </div>

              {/* 회원 상태 */}
              <div className='userInfoState'>
                <div className='state'>
                  <V2Text
                    element={V2TextOption.element.div}
                    fontStyle={V2TextOption.fontStyle.caption_1_sb}
                    color={V2TextOption.color.subtle}
                  >
                    프로필 완성도
                  </V2Text>
                  <JDALink to={RoutePaths.profile} className='badge'>
                    <V2Badge
                      size={V2BadgeOption.size.L}
                      type={V2BadgeOption.type.Fill}
                      fontStyle={V2BadgeOption.fontStyle.caption_1_sb}
                      color={dashboardData?.profilePercent <= 30 ? V2BadgeOption.color.subtlest : V2BadgeOption.color.accent_green_default}
                      backgroundColor={dashboardData?.profilePercent <= 30 ? V2BadgeOption.backgroundColor.accent_gray_subtle : V2BadgeOption.backgroundColor.accent_green_subtle}
                    >
                      { dashboardData?.profilePercent }%
                    </V2Badge>
                  </JDALink>
                </div>

                {matchingConfigYn ? (
                  <div className='state'>
                    <V2Text
                      element={V2TextOption.element.div}
                      fontStyle={V2TextOption.fontStyle.caption_1_sb}
                      color={V2TextOption.color.subtle}
                    >
                      구직 상태
                    </V2Text>
                    <div className='badge' role='button' onClick={() => setIsOpenJobStateModal(true)}>
                      {
                        dashboardData.jobSearchStatus === JobSearchStatus.NOT_SELECTED || dashboardData.jobSearchStatus === JobSearchStatus.NOT_LOOKING_FOR_JOB
                          ? (
                            <V2Badge
                              size={V2BadgeOption.size.L}
                              type={V2BadgeOption.type.Fill}
                              fontStyle={V2BadgeOption.fontStyle.caption_1_sb}
                              color={V2BadgeOption.color.subtle}
                              backgroundColor={V2BadgeOption.backgroundColor.accent_gray_subtle}
                            >
                              {dashboardData?.jobSearchStatus && JobSearchStatusTypeText[dashboardData.jobSearchStatus] }
                            </V2Badge>
                          )
                          : (
                            <V2Badge
                              size={V2BadgeOption.size.L}
                              type={V2BadgeOption.type.Fill}
                              fontStyle={V2BadgeOption.fontStyle.caption_1_sb}
                              color={V2BadgeOption.color.accent_skyblue_default}
                              backgroundColor={V2BadgeOption.backgroundColor.accent_skyblue_subtle}
                            >
                              {dashboardData?.jobSearchStatus && JobSearchStatusTypeText[dashboardData.jobSearchStatus] }
                            </V2Badge>
                          )
                      }

                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {/* 역량검사 결과표 선택 */}
                {/* 버튼 디자인키트 아이콘 노출 확인 필요
              + 결과표 설정 링크 이동 개발 필요 */}
                {
                !login.matchConfigStatus?.generalMatchingConfigAvailableYn && login.matchConfigStatus?.developerMatchingConfigAvailableYn
                  ? <></>
                  : (
                    <V2Button
                      className='accaResult'
                      size={V2ButtonOption.size.XS}
                      type={V2ButtonOption.type.Text}
                      fontStyle={V2TextOption.fontStyle.body_3_sb}
                      buttonProps={{ onClick: () => setIsOpenSubmitModal(true), disabled: login.matchConfigStatus?.matchingConfigYn === false }}
                      color={V2TextOption.color.default}
                      iconName='arrow-right'
                      iconPosition={V2ButtonOption.iconPosition.right}
                    >
                      역량검사 결과표
                    </V2Button>
                  )
              }

              </div>
            </div>
          )
        }

        {/* 현황 카운트 */}
        <div className='state-count'>
          <div className='state-item' role='button' onClick={() => history.push(RoutePaths.mypage_bookmark)}>
            <div className='count'>{dashboardData?.bookmarkCount}</div>
            <div className='title'>북마크</div>
          </div>
          {
          tabs.map((tabItem, index) => (
            <div key={`${tabItem.label}-${index}`} className='state-item' role='button' onClick={() => handleTabClick(tabItem.type)}>
              <div className={`count ${tabItem.new ? 'new-count' : ''}`}>{tabItem.count}</div>
              <div className='title'>{tabItem.label}</div>
            </div>
          ))
        }
          <div className='state-item' role='button' onClick={() => history.push(RoutePaths.mypage_apply)}>
            <div className='count'>{dashboardData?.applicationStatusCount}</div>
            <div className='title'>지원현황</div>
          </div>
        </div>

        {/* 배너 */}
        <MatchingBanner />

        <MatchServiceConditionInductionModal isOpen={isOpenConditionInductionModal} onClickClose={() => setIsOpenConditionInductionModal(false)} />

        {/* 구직상태 모달 */}
        {login.matchConfigStatus?.jobSearchStatus && (
        <MatchJobSearchStatusModal
          isOpen={isOpenJobStateModal}
          onClickClose={() => setIsOpenJobStateModal(false)}
        />
        )}
        <MatchApplySubmitModal
          submitType={PhsAccType.ACC}
          isOpen={isOpenSubmitModal}
          onClickClose={async () => {
            await matchApplyStatusModel.loadMatchStatus();
            await positionListModel.loadMatchBookmarks();
            await login.getMatchConfigStatus();
            await matchHomeDashboardModel.loadApplicationCount();
            setIsOpenSubmitModal(false);
          }}

        />
      </Frame>
    </>
  );
};

export default inject(injectStore.matchApplyStatusModel, injectStore.login, injectStore.matchHomeDashboardModel, injectStore.matchApplyModel, injectStore.profileBasicInfoModel, injectStore.positionListModel)(observer(MyDashboardComponent));
