import Logo from '__designkit__/Logo';
import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import LongTermUserVerification from 'components/_v2/verification/LongTermUserVerification';
import Verification from 'components/_v2/verification/Verification';
import { Divider1G50 } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import SupportMail from 'consts/_v2/GlobalText';
import { UserAuthorityType } from 'consts/_v2/UserAuthorityType';
import useToast from 'hooks/useToast';
import useVerification from 'hooks/useVerification';
import { IIdentificationRq } from 'interfaces/rqrs/IJoinRqRs';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import AuthorizeUtil from 'utils/AuthorizeUtil';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  border-top: 8px solid ${colors.CG_90};
  background-color: ${colors.WHITE_100};

`;

const HeaderFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 24px 16px;
  margin-bottom: 16px;
  
`;

const ViewFrame = styled.div<{ currentStep: number }>`
    padding: 0 28px;
    display: ${(props) => (props.currentStep === 0 ? '' : 'none')};
`;

const ContentFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 0 4px;

  & h1 {
    font: ${Fonts.H4_Bold};
    color: ${colors.JOBDA_BLACK};
  }

    .content {
        font: ${Fonts.B2_Medium_P};
        color: ${colors.CG_70};

        b {
            font: ${Fonts.B2_Bold_P};
        }
    }

    a {
      text-decoration: underline;
    }
`;

const BtnFrame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 8px 4px;
  margin-bottom: 8px;
`;

const UpdateAccountPage = ({ login = new Login() }) => {
  const history = useHistory();
  const verify = useVerification();
  const { setToastObject } = useToast();
  const useformed = useForm({
    mode: 'onChange',
    defaultValues: {
      countryCode: '82',
      mobile: '',
      token: '',
    },
  });

  const [currentStep, setCurrentStep] = useState(0);
  const onClickSaveBtn = () => {
    setCurrentStep(1); // verification
  };

  useEffect(() => {
    const init = async () => {
      await login.loadCurrentUser();
      if (!login.userInfo) history.replace(RoutePaths.root);
      if (!login.userInfo?.authorities.includes(UserAuthorityType.LONG_TERM_INACTIVE_VERIFICATION_REQUIRED)) history.replace(RoutePaths.root);
    };
    init();
  }, [history, login.userInfo]);

  useEffect(() => {
    findIdIpin();
  }, [verify]);

  const findIdIpin = async () => {
    if (verify) {
      let identificationSuccess = false;
      try {
        const data: IIdentificationRq = {
          token: verify.identificationToken,
          name: verify.name,
          mobile: verify.telephoneNumber ? verify.telephoneNumber : (login.userDefault?.mobile || null),
          birthDate: verify.birthday,
        };
        await login.putSaveIdentity(data);
        identificationSuccess = true; // 성공 시 플래그 설정
      } catch (e: any) {
        identificationSuccess = false; // 실패 시 플래그 해제
        if (e.response.data.errorCode === 'B901') {
          setToastObject({ isOpen: true, message: '본인인증 세션이 만료되었습니다.', type: 'ERROR' });
        } else if (e.response.data.errorCode === 'B902') {
          setToastObject({ isOpen: true, message: '동일한 인증정보를 가지는 다른 계정이 존재합니다.', type: 'ERROR' });
        } else {
          setToastObject({ isOpen: true, message: '본인인증에 실패했습니다.', type: 'ERROR' });
        }
      }

      if (identificationSuccess) {
        try {
          await login.updateLongTermAccount();
          await AuthorizeUtil.updateAccessToken(true);
          history.push(RoutePaths.root);
        } catch (e: any) {
          const err = e as { response: any };
          setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
        }
      }
    }
  };

  return (
    <FormProvider {...useformed}>
      <Frame>
        <HeaderFrame>
          <Logo color='black' size={80} />
          <SpacingBlock size={24} vertical />
          <Divider1G50 />
        </HeaderFrame>
        <ViewFrame currentStep={currentStep}>
          <ContentFrame>
            <h1>안녕하세요!<br />JOBDA에 오랜만에 방문하셨네요.</h1>
            <SpacingBlock size={16} vertical />
            <div className='content'>

              먼저, 잡다를 이용해 주셔서 감사합니다.<br />
              개정된 개인정보 보호법에 따라 잡다의 운영정책도 아래와 같이 변경되어 안내드립니다.<br />
              <SpacingBlock size={20} vertical />
              <b>[내용]</b><br />
              개인정보보호법 개정(제39조의 6, 개인정보 유효기간제 폐지)에 따라 잡다 휴면계정 정책 폐지<br />
              &bull;  「개인정보 처리방침」 개인정보 유효기간 정책 삭제<br />
              &bull;  「서비스 이용약관」제18 조(계약해제, 해지, 휴면 등) 휴면 정책 삭제<br />
              &bull;  「서비스 이용약관」제19 조(이용제한 등) 장기 미이용자에 대한 정책 삭제<br />
              <SpacingBlock size={20} vertical />
              <b>[변경일자]</b><br />
              2024년 1월 31일<br />
              <SpacingBlock size={20} vertical />
              <b>[안내 사항]</b><br />
              ① 마케팅 수신 동의 내역은 동일하게 유지되며, 변경을 원하시는 경우 ‘계정 설정 &gt; 알림 관리’에서 변경하실 수 있습니다.<br />
              ② 궁금하신 사항이 있으신 경우 <a className='email' href={`mailto:${SupportMail}`}>대표 메일</a>로 문의하시면 안내 드리겠습니다.<br />
              ③ 오랜만에 방문한 회원분들의 개인정보를 안전히 지키기 위해 ‘본인인증’ 후 서비스를 제공하고 있습니다.<br />
              * 내 정보에 등록된 내용이 오래전 연락처인 경우에는 <a className='email' href={`mailto:${SupportMail}`}>대표 메일</a>로 문의해 주세요.<br />
            </div>
            <SpacingBlock size={24} vertical />
          </ContentFrame>
          <BtnFrame>
            <Button label='본인 인증하고 잡다 시작하기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickSaveBtn} />
          </BtnFrame>
        </ViewFrame>
        <Verification
          handlePassVerification={() => setCurrentStep(2)}
          returnUrl={RoutePaths.update_account}
          className={currentStep === 1 ? '' : 'none'}
          content={`${login.userInfo?.name}님의 본인 확인을 위해 본인 인증이 필요해요.`}
          mobileText='휴대폰 본인인증'
          ipinText='아이핀 인증'
        />
        <LongTermUserVerification hide={currentStep !== 2} />
      </Frame>

    </FormProvider>
  );
};

export default inject(injectStore.login)(observer(UpdateAccountPage));
