/* eslint-disable react/no-unescaped-entities */
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import { FAQMetaTag } from 'components/_v2/_common/meta/MetaTag';
import { Divider12G } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import SupportMail from 'consts/_v2/GlobalText';
import { ScrollHiddenMixin } from 'consts/_v2/_common/style/mixins';
import React, { FC, MouseEvent, useState } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

const Frame = styled.div`
  width: 100%;
`;

const StyledContentFrame = styled.div``;

const HeaderFrame = styled.div`
  padding: 16px 10px 0px 16px;
  .title-text {
    font: ${Fonts.H4_Bold};
    color: ${Colors.JOBDA_BLACK};
  }
  .detail-text {
    font: ${Fonts.B2_Medium_P};
    color: ${Colors.JOBDA_BLACK};
  }
  .link-text {
    font: ${Fonts.B2_Medium_P};
    color: ${Colors.B_100};
    text-decoration: underline;
  }
  .info-text {
    font: ${Fonts.B3_Medium_P};
    color: ${Colors.CG_60};
  }
`;
const StyledFaqChip = styled.div<{ selected: boolean }>`
  padding: 0 16px;
  background-color: ${(props) => (props.selected ? `${Colors.JOBDA_BLACK}` : `${Colors.WHITE_100}`)};
  border: ${(props) => (props.selected
    ? `1px solid ${Colors.JOBDA_BLACK}`
    : `1px solid ${Colors.CG_50}`)};
  border-radius: 48px;
  margin-right: 8px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-direction: row;
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
  .curation-text {
    color: ${(props) => (props.selected ? `${Colors.WHITE_100};` : `${Colors.CG_80};`)};
    ${(props) => (props.selected
    ? css`
            font: ${Fonts.B3_Bold};
          `
    : css`
            font: ${Fonts.B3_Medium};
          `)}
  }
`;
const StyledFaqTab = styled.div`
  width: 100%;
  overflow: hidden;
  margin-bottom: 16px;
  .curation-frame {
    padding: 0px 16px 0px 16px;
    width: 100%;
    display: inline-flex;
    overflow-x: scroll;
    height: 36px;
    ${ScrollHiddenMixin()}
  }
`;

const FAQList = styled.div`
  & div.item {
    & div.title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: fit-content;
      padding: 7px 12px 7px 20px;
      border-bottom: 1px solid ${Colors.CG_40};
      font: ${Fonts.B2_Medium};
      color: ${Colors.JOBDA_BLACK};

      & div:last-child {
        transition: 0.4s;
      }

      & div:last-child.active {
        transform: rotateX(180deg);
      }
    }

    & div.content {
      width: 100%;
      height: fit-content;
      padding: 20px;
      background-color: ${Colors.JOBDA_WHITE};
      height: 0;
      overflow: hidden;
      transition: height 1s ease-out;
      will-change: height;

      &.active {
        height: 100%;
      }
      .inside-toggle{
        padding-left: 16px;
        font: ${Fonts.B3_Medium_P};
        color: ${Colors.CG_70};
        white-space: pre-line;
      }
      em {
        display: block;
        font: ${Fonts.B3_Medium_P};
        color: ${Colors.JOBDA_BLACK};
      }
      span {
        font: ${Fonts.B3_Medium_P};
        color: ${Colors.CG_70};
        white-space: pre-line;
      }
      a {
        text-decoration: underline;
        color: ${Colors.B_100};
        font: ${Fonts.B3_Medium_P};
      }
      button {
        color: ${Colors.B_100};
        font: ${Fonts.B3_Medium_P};
      }
      .open-button {
        display: flex;
        color: ${Colors.B_100};
        font: ${Fonts.B3_Medium_P};
        align-items: center;
      }
      .spacing {
        width: 1px;
        height: 16px;
      }

      &.none {
        display: none;
      }
    }
  }
`;
export enum FAQ_TYPE {
  ALL = 'ALL',
  USER = 'USER',
  MATCHING = 'MATCHING',
  APPLY = 'APPLY',
  ACCA = 'ACCA',
  PACC = 'PACC',
  PARTNERSHIP = 'PARTNERSHIP',
}

const FaqMenuList: { title: string; type: FAQ_TYPE }[] = [
  {
    title: '전체',
    type: FAQ_TYPE.ALL,
  },
  {
    title: '회원가입, 로그인, 회원탈퇴',
    type: FAQ_TYPE.USER,
  },
  {
    title: '잡다매칭',
    type: FAQ_TYPE.MATCHING,
  },
  {
    title: '입사 지원',
    type: FAQ_TYPE.APPLY,
  },
  {
    title: '역량검사',
    type: FAQ_TYPE.ACCA,
  },
  {
    title: '개발자 검사',
    type: FAQ_TYPE.PACC,
  },
  {
    title: '제휴 서비스',
    type: FAQ_TYPE.PARTNERSHIP,
  },
];

const JobdaFaq: FC = () => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [activeList, setActiveList] = useState<FAQ_TYPE>(FAQ_TYPE.ALL);
  const [activeFAQ, setActiveFAQ] = useState<number | null>(null); // 활성화 FAQ 아이템
  const [activeTerm, setActiveTerm] = useState<number | null>(null);
  const history = useHistory();
  const onClickFAQItem = (e: MouseEvent) => {
    const sn = Number(e.currentTarget.getAttribute('data-faq-sn'));
    setActiveFAQ(activeFAQ === sn ? null : sn);
  };
  const handleClickActive = (e: MouseEvent) => {
    const sn = Number(e.currentTarget.getAttribute('data-term-sn'));
    setActiveTerm(activeTerm === sn ? null : sn);
  };

  return (
    <Frame>
      <FAQMetaTag />

      <HeaderFrame>
        <div className='title-text'>잡다 고객센터</div>
        <SpacingBlock size={16} vertical />
        <div className='detail-text'>
          기타 문의 사항, 개선 사항은 아래 메일 주소로 문의해 주세요.
        </div>
        <a className='link-text' href={`mailto:${SupportMail}`}>
          {SupportMail}
        </a>
        <SpacingBlock size={16} vertical />
        <div className='info-text'>
          문의 메일 운영 시간 : 평일 9:00am - 17:30pm
        </div>
      </HeaderFrame>
      <SpacingBlock size={24} vertical />
      <Divider12G />
      <SpacingBlock size={24} vertical />

      <StyledFaqTab>
        <div className='curation-frame'>
          {FaqMenuList.map((menu, index) => (
            <StyledFaqChip
              key={`${menu.type}-menu`}
              selected={index === activeTabIndex}
              onClick={() => {
                setActiveFAQ(0);
                setActiveTabIndex(index);
                setActiveList(menu.type);
              }}
            >
              <div className='curation-text'>{menu.title}</div>
            </StyledFaqChip>
          ))}
        </div>
      </StyledFaqTab>

      <StyledContentFrame>
        <FAQList>
          {(activeList === FAQ_TYPE.USER || activeList === FAQ_TYPE.ALL) && (
            <>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={1}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 회원가입 중에 오류가 발생했어요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 1 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 1 ? 'active' : 'none'}`}
                >

                  <em>1) 아래 '개인정보 수집 및 이용 동의'를 확인해 주세요.</em>
                  <button className='open-button' data-term-sn={1} onClick={handleClickActive}>
                    {activeTerm === 1 ? (
                      <Icon size={24} name='up-s-filled' />
                    ) : (
                      <Icon size={24} name='down-s-filled' />
                    )}
                    개인정보 수집 및 이용 동의
                  </button>
                  {activeTerm === 1 && (
                    <div className='inside-toggle'>
                      {`◎ 개인정보 수집 및 이용 동의 <필수>

                          개인정보 수집항목 및 이용목적
                          
                          ● 1-1. 수집항목
                          
                          ○ (필수) 성명, Email, 휴대폰 번호
                          
                          ● 1-2. 수집 및 이용 목적 상담 및 문의 처리 개인정보의 보유 및 이용기간
                          
                          ○ 회사는 법령에 따른 개인정보 보유∙이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유∙이용기간 내에서 개인정보를 처리하며, 개인정보의 처리 목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
                          
                          ○ 개인정보 처리 및 보유 기간은 다음과 같으며, 아래 각 항목에 대하여 정보주체가 명시적으로 파기 요청을 하는 경우 지체없이 파기합니다. 상담이력은 처리 후 12개월 간 보관하며, 이후 해당 정보는 지체없이 파기 동의 거부 시 불이익 이용자는 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있습니다. 다만, 필수항목의 수집 및 이용에 대해 동의하지 않을 경우에는 상담 및 문의 관련 서비스를 받으실 수 없습니다.
                        `}
                    </div>
                  )}
                  <div className='spacing' />
                  <em>
                    2) <a href={`mailto:${SupportMail}`}>{SupportMail}</a>으로
                    아래 정보를 공유해 주세요.
                  </em>
                  <div className='spacing' />
                  <span>
                    {`• 문의 유형 : 회원가입 오류
                  • 성명 : 
                  • Email :
                  • 휴대폰 번호 : 
                  • 오류 상세 내용 :
                  • 동의 확인 : 개인정보 수집 및 이용에 동의합니다.`}
                  </span>
                </div>
              </div>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={2}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 아이디, 비밀번호를 잊어버렸어요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 2 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 2 ? 'active' : 'none'}`}
                >

                  <em>로그인 창에 있는 ‘아이디·비밀번호 찾기’ 기능을 이용해 주세요.<br />
                    원하시는 정보를 찾지 못하는 경우 아래 절차를 통해 요청해 주세요.
                  </em>
                  <SpacingBlock vertical size={10} />
                  <em>1) 아래 '개인정보 수집 및 이용 동의'를 확인해 주세요.</em>
                  <button className='open-button' data-term-sn={2} onClick={handleClickActive}>
                    {activeTerm === 2 ? (
                      <Icon size={24} name='up-s-filled' />
                    ) : (
                      <Icon size={24} name='down-s-filled' />
                    )}
                    개인정보 수집 및 이용 동의
                  </button>
                  {activeTerm === 2 && (
                    <div className='inside-toggle'>
                      {`◎ 개인정보 수집 및 이용 동의 <필수>

                          개인정보 수집항목 및 이용목적
                          
                          ● 1-1. 수집항목
                          
                          ○ (필수) 성명, 생년월일, 성별, 휴대폰 번호
                          
                          ● 1-2. 수집 및 이용 목적 상담 및 문의 처리 개인정보의 보유 및 이용기간
                          
                          ○ 회사는 법령에 따른 개인정보 보유∙이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유∙이용기간 내에서 개인정보를 처리하며, 개인정보의 처리 목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
                          
                          ○ 개인정보 처리 및 보유 기간은 다음과 같으며, 아래 각 항목에 대하여 정보주체가 명시적으로 파기 요청을 하는 경우 지체없이 파기합니다. 상담이력은 처리 후 12개월 간 보관하며, 이후 해당 정보는 지체없이 파기 동의 거부 시 불이익 이용자는 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있습니다. 다만, 필수항목의 수집 및 이용에 대해 동의하지 않을 경우에는 상담 및 문의 관련 서비스를 받으실 수 없습니다.
                        `}
                    </div>
                  )}
                  <div className='spacing' />
                  <em>
                    2) <a href={`mailto:${SupportMail}`}>{SupportMail}</a>으로
                    아래 정보를 공유해 주세요.
                  </em>
                  <div className='spacing' />
                  <span>
                    {`• 문의 유형 : 아이디 / 비밀번호 찾기 중 택 1
                  • 성명 : 
                  • 생년월일 : 
                  • 성별 : 
                  • 휴대폰 번호 : 
                  • 동의 확인 : 개인정보 수집 및 이용에 동의합니다.`}
                  </span>
                </div>
              </div>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={3}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 회원 탈퇴를 하고 싶어요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 3 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 3 ? 'active' : 'none'}`}
                >
                  <em>회원 탈퇴를 희망하신다면 아래 절차를 진행해 주세요.</em>
                  <div className='spacing' />
                  <em>
                    1)
                    <button
                      onClick={() => history.push(RoutePaths.mypage_account)}
                    >
                      계정 설정 페이지
                    </button>
                    로 이동
                  </em>
                  <em>2) 주의사항 확인 및 계정 삭제 진행</em>
                </div>
              </div>
            </>
          )}
          {(activeList === FAQ_TYPE.MATCHING
            || activeList === FAQ_TYPE.ALL) && (
            <>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={4}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 잡다매칭이 궁금해요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 4 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 4 ? 'active' : 'none'}`}
                >
                  <em>
                    '잡다매칭'은 지원자의 프로필, 역량검사 정보를 분석하여 최적의
                    기업과 연결하는 서비스입니다.
                  </em>
                  <em>JOBDA의 인재가 되어 국내 우수의 기업들을 만나보세요!</em>
                  <div className='spacing' />
                  <button
                    onClick={() => history.push(RoutePaths.match_introduce)}
                  >
                    잡다매칭 바로가기
                  </button>
                </div>
              </div>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={5}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 더 많은 매칭 제안을 받고 싶어요. / 매칭 제안이 오지 않아요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 5 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 5 ? 'active' : 'none'}`}
                >
                  <em>
                    <button onClick={() => history.push(RoutePaths.profile)}>

                      내 프로필
                    </button>
                    을 구체적으로 적으면, 더욱 많은 제안을 받을 수 있어요.
                  </em>
                  <em>
                    기업 인사 담당자들은 특히 프로젝트, 지식 · 기술, 경험을
                    중요하게 생각해요.
                  </em>
                </div>
              </div>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={6}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 제가 원하는 매칭 제안이 아니에요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 6 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 6 ? 'active' : 'none'}`}
                >
                  <em>
                    <button onClick={() => history.push(RoutePaths.profile)}>

                      내 프로필
                    </button>
                    의 선호정보(희망 직군 · 직무, 희망 근무지역 등)를 정확히
                    입력해주시면, 지원자님께 딱 맞는 제안을 할 수 있어요.
                  </em>
                  <em>
                    특정 기업의 제안을 받고 싶지 않다면,&nbsp;
                    <button
                      onClick={() => history.push(RoutePaths.match_apply)}
                    >
                      {` 매칭 현황`}
                    </button>
                    에서 해당 기업이 지원자님의 정보를 볼 수 없도록 제한할 수
                    있어요.
                  </em>
                </div>
              </div>
            </>
          )}
          {(activeList === FAQ_TYPE.APPLY || activeList === FAQ_TYPE.ALL) && (
            <>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={17}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 취업활동 증명서는 어떻게 발급 받나요?
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 17 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 17 ? 'active' : 'none'}`}
                >
                  <em>
                    지원현황 페이지에서 발급할 수 있습니다.<br />
                    지원현황 페이지 {`>`} 우측 상단 ‘취업활동 증명서’ 클릭 {`>`} 다운로드 버튼을 클릭하여 발급받을 수 있습니다.
                  </em>
                  <div className='spacing' />
                  <em>
                    ※ 작성 중인 지원서, 숨긴 포지션은 취업활동 증명서 내에서 확인이 불가능합니다.
                  </em>
                </div>
              </div>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={18}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. [홈페이지 지원] 지원했던 공고에 지원 취소하고 싶어요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 18 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 18 ? 'active' : 'none'}`}
                >
                  <em>
                    공고가 접수 중인 상태면 지원서 삭제를 통한 지원 취소가 가능합니다.
                    <br />
                    공고가 마감인 상태일 경우, 지원 취소를 원하시면 기업 인사담당자님께 ‘지원서 삭제’ 요청을
                    진행해 주셔야 합니다.
                    <br />
                    <br />
                    ※ 접수 중 상태일 경우:
                    <br />
                    {`기업 채용사이트 > MY > 지원서 수정 > 공고 확인 > 지원서 수정하기 클릭 > 인적 사항 기입 > 지원서 삭제 클릭`}
                    <br />
                    <br />
                    ※ 마감 상태일 경우(인사담당자님 문의 경로) :
                    <br />
                    {`기업 채용사이트 > MY > 1:1 문의하기 > 지원서 삭제 요청 문의`}
                  </em>
                </div>
              </div>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={19}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. [홈페이지 지원] 이미 제출한 지원서를 수정하고 싶어요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 19 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 19 ? 'active' : 'none'}`}
                >
                  <em>
                    이미 제출 완료한 지원서의 경우 수정이 불가능합니다.
                    <br />
                    인사담당자님께 지원서 수정이 필요하여, 지원서 제출 상태를 [미완료]로 변경 요청해 주셔야
                    합니다.
                    <br />
                    <br />
                    ※ 인사담당자님 문의 경로 :
                    <br />
                    {`기업 채용사이트 > MY > 1:1 문의하기 > 지원서 수정 요청 문의`}
                  </em>
                </div>
              </div>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={20}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. [홈페이지 지원] 전형이 종료된 공고는 확인할 수 없나요?
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 20 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 20 ? 'active' : 'none'}`}
                >
                  <em>
                    채용사이트 공고 목록 및 마이페이지 목록에서 찾으시고자 하는 공고명이 보이지 않는다면, 확인이 불가능합니다.
                  </em>
                </div>
              </div>
            </>
          )}
          {(activeList === FAQ_TYPE.ACCA || activeList === FAQ_TYPE.ALL) && (
            <>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={7}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 역량검사가 궁금해요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 7 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 7 ? 'active' : 'none'}`}
                >
                  <em>
                    역량검사는 세계 최초의 신경과학 기반 알고리즘과 데이터 과학
                    기술이 융합된 솔루션입니다.
                  </em>
                  <em>
                    현재 대기업 포함 800개 이상의 기업이 역량검사를 활용하여
                    채용을 진행하고 있습니다.
                  </em>
                  <em>JOBDA에서 역량검사를 응시하고 결과표도 확인해보세요!</em>
                  <div className='spacing' />
                  <button onClick={() => history.push(RoutePaths.acca_test)}>
                    역랑검사 바로가기
                  </button>
                </div>
              </div>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={8}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 역량검사를 어떻게 준비할지 알고 싶어요
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 8 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 8 ? 'active' : 'none'}`}
                >
                  <em>
                    역량검사가 생소한 구직자분들을 위하여 사전 준비 가이드를
                    제공하고 있습니다.
                  </em>
                  <em>
                    가이드를 확인하고 여러분의 역량을 100%로 발휘해보세요!
                  </em>
                  <div className='spacing' />
                  <button
                    onClick={() => {
                      window.location.href = 'https://m.jobda.im/info/328';
                    }}
                  >
                    응시가이드 바로가기
                  </button>
                </div>
              </div>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={9}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 역량검사 응시 중에 오류가 발생했어요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 9 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 9 ? 'active' : 'none'}`}
                >
                  <em>
                    역량검사 진행 중, 오류가 발생하셨다면 아래 절차를
                    진행해 주세요.
                  </em>
                  <em>1) 아래 '개인정보 수집 및 이용 동의'를 확인해 주세요.</em>
                  <button className='open-button' data-term-sn={9} onClick={handleClickActive}>
                    {activeTerm === 9 ? (
                      <Icon size={24} name='up-s-filled' />
                    ) : (
                      <Icon size={24} name='down-s-filled' />
                    )}
                    개인정보 수집 및 이용 동의
                  </button>
                  {activeTerm === 9 && (
                    <div className='inside-toggle'>
                      {`◎ 개인정보 수집 및 이용 동의 <필수>

                          개인정보 수집항목 및 이용목적
                          
                          ● 1-1. 수집항목
                          
                          ○ (필수) 성명, Email, 휴대폰 번호, JOBDA 아이디, 응시 시간
                          
                          ● 1-2. 수집 및 이용 목적 상담 및 문의 처리 개인정보의 보유 및 이용기간
                          
                          ○ 회사는 법령에 따른 개인정보 보유∙이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유∙이용기간 내에서 개인정보를 처리하며, 개인정보의 처리 목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
                          
                          ○ 개인정보 처리 및 보유 기간은 다음과 같으며, 아래 각 항목에 대하여 정보주체가 명시적으로 파기 요청을 하는 경우 지체없이 파기합니다. 상담이력은 처리 후 12개월 간 보관하며, 이후 해당 정보는 지체없이 파기 동의 거부 시 불이익 이용자는 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있습니다. 다만, 필수항목의 수집 및 이용에 대해 동의하지 않을 경우에는 상담 및 문의 관련 서비스를 받으실 수 없습니다.
                        `}
                    </div>
                  )}
                  <div className='spacing' />
                  <em>
                    2) <a href={`mailto:${SupportMail}`}>{SupportMail}</a>으로
                    아래 정보를 공유해 주세요.
                  </em>
                  <div className='spacing' />
                  <span>
                    {`◎ 필수 항목

• 성명 : 
• Email :
• 휴대폰 번호 : 
• JOBDA 아이디 :
• 응시시간 : (예시. 2023.01.01 12:00)
• 오류 설명 :
• 동의 확인 : 개인정보 수집 및 이용에 동의합니다

◎ 선택 항목 (특정 지원기업 존재 시 기입)

• 지원기업 :
• 응시링크 :
• 응시코드 :`}
                  </span>
                </div>
              </div>
            </>
          )}
          {(activeList === FAQ_TYPE.PACC || activeList === FAQ_TYPE.ALL) && (
            <>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={10}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 개발자 검사가 궁금해요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 10 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 10 ? 'active' : 'none'}`}
                >
                  <em>
                    개발자 검사는 새로운 패러다임의 개발자 채용 검사 도구입니다.
                  </em>
                  <em>
                    많은 기업이 JOBDA 개발자 검사를 채용에 활용하고 있습니다.
                  </em>
                  <em>
                    JOBDA에서 개발자 검사를 응시하고 결과표도 확인해보세요!
                  </em>
                  <div className='spacing' />
                  <button onClick={() => history.push(RoutePaths.phs_test)}>
                    개발자검사 바로가기
                  </button>
                </div>
              </div>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={11}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 개발자 검사를 어떻게 준비할지 알고 싶어요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 11 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 11 ? 'active' : 'none'}`}
                >
                  <em>
                    개발자 검사가 생소한 구직자분들을 위하여 사전 준비 가이드를
                    제공하고 있습니다.
                  </em>
                  <em>
                    가이드를 확인하고 여러분의 역량을 100%로 발휘해보세요!
                  </em>
                  <div className='spacing' />
                  <button
                    onClick={() => {
                      window.location.href = 'https://m.jobda.im/info/361';
                    }}
                  >
                    응시가이드 바로가기
                  </button>
                </div>
              </div>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={12}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 개발자 검사 응시 중에 오류가 발생했어요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 12 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 12 ? 'active' : 'none'}`}
                >
                  <em>
                    개발자 검사 진행 중, 오류가 발생하셨다면 아래 절차를 진행해
                    주세요.
                  </em>
                  <em>1) 아래 '개인정보 수집 및 이용 동의'를 확인해 주세요.</em>
                  <button className='open-button' data-term-sn={12} onClick={handleClickActive}>
                    {activeTerm === 12 ? (
                      <Icon size={24} name='up-s-filled' />
                    ) : (
                      <Icon size={24} name='down-s-filled' />
                    )}
                    개인정보 수집 및 이용 동의
                  </button>
                  {activeTerm === 12 && (
                    <div className='inside-toggle'>
                      {`◎ 개인정보 수집 및 이용 동의 <필수>

                          개인정보 수집항목 및 이용목적
                          
                          ● 1-1. 수집항목
                          
                          ○ (필수) 성명, Email, 휴대폰 번호, JOBDA 아이디, 응시 시간
                          
                          ● 1-2. 수집 및 이용 목적 상담 및 문의 처리 개인정보의 보유 및 이용기간
                          
                          ○ 회사는 법령에 따른 개인정보 보유∙이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유∙이용기간 내에서 개인정보를 처리하며, 개인정보의 처리 목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
                          
                          ○ 개인정보 처리 및 보유 기간은 다음과 같으며, 아래 각 항목에 대하여 정보주체가 명시적으로 파기 요청을 하는 경우 지체없이 파기합니다. 상담이력은 처리 후 12개월 간 보관하며, 이후 해당 정보는 지체없이 파기 동의 거부 시 불이익 이용자는 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있습니다. 다만, 필수항목의 수집 및 이용에 대해 동의하지 않을 경우에는 상담 및 문의 관련 서비스를 받으실 수 없습니다.
                        `}
                    </div>
                  )}
                  <div className='spacing' />
                  <em>
                    2) <a href={`mailto:${SupportMail}`}>{SupportMail}</a>으로
                    아래 정보를 공유해 주세요.
                  </em>
                  <div className='spacing' />
                  <span>
                    {`◎ 필수 항목

• 성명 : 
• Email :
• 휴대폰 번호 : 
• JOBDA 아이디 :
• 응시시간 : (예시. 2023.01.01 12:00)
• 오류 설명 :
• 동의 확인 : 개인정보 수집 및 이용에 동의합니다

◎ 선택 항목 (특정 지원기업 존재 시 기입)

• 지원기업 :
• 응시링크 :
• 응시코드 :`}
                  </span>
                </div>
              </div>
            </>
          )}

          {(activeList === FAQ_TYPE.PARTNERSHIP
            || activeList === FAQ_TYPE.ALL) && (
            <>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={13}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 제휴 서비스와 관련해서 오류가 발생했어요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 13 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 13 ? 'active' : 'none'}`}
                >
                  <em>
                    제휴서비스와 관련하여 오류가 발생하셨다면 아래 절차를 진행해
                    주세요.
                  </em>
                  <em>1) 아래 '개인정보 수집 및 이용 동의'를 확인해 주세요.</em>
                  <button className='open-button' data-term-sn={13} onClick={handleClickActive}>
                    {activeTerm === 13 ? (
                      <Icon size={24} name='up-s-filled' />
                    ) : (
                      <Icon size={24} name='down-s-filled' />
                    )}
                    개인정보 수집 및 이용 동의
                  </button>
                  {activeTerm === 13 && (
                    <div className='inside-toggle'>
                      {`◎ 개인정보 수집 및 이용 동의 <필수>

                          개인정보 수집항목 및 이용목적
                          
                          ● 1-1. 수집항목
                          
                          ○ (필수) 성명, 휴대폰 번호, JOBDA 아이디
                          
                          ● 1-2. 수집 및 이용 목적 상담 및 문의 처리 개인정보의 보유 및 이용기간
                          
                          ○ 회사는 법령에 따른 개인정보 보유∙이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유∙이용기간 내에서 개인정보를 처리하며, 개인정보의 처리 목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
                          
                          ○ 개인정보 처리 및 보유 기간은 다음과 같으며, 아래 각 항목에 대하여 정보주체가 명시적으로 파기 요청을 하는 경우 지체없이 파기합니다. 상담이력은 처리 후 12개월 간 보관하며, 이후 해당 정보는 지체없이 파기 동의 거부 시 불이익 이용자는 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있습니다. 다만, 필수항목의 수집 및 이용에 대해 동의하지 않을 경우에는 상담 및 문의 관련 서비스를 받으실 수 없습니다.
                        `}
                    </div>
                  )}
                  <div className='spacing' />
                  <em>
                    2) <a href={`mailto:${SupportMail}`}>{SupportMail}</a>으로
                    아래 정보를 공유해 주세요.
                  </em>
                  <div className='spacing' />
                  <span>
                    {`• 문의 유형 : 제휴 서비스
                  • 성명 :
                  • 휴대폰 번호 : 
                  • JOBDA 아이디 : 
                  • 오류 설명:
                  • 동의 확인 : 개인정보 수집 및 이용에 동의합니다`}
                  </span>
                </div>
              </div>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={14}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 제휴 해택이 궁금해요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 14 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 14 ? 'active' : 'none'}`}
                >
                  <em>
                    JOBDA는 제휴서비스 신청자에게 아래 혜택들을 제공합니다.
                  </em>
                  <em>1) 역량검사 응시 및 심층 분석 결과 열람</em>
                  <em>2) 면접 성공을 돕는 ‘기출 면접 연습’</em>
                  <div className='spacing' />
                  <button
                    onClick={() => history.push(RoutePaths.mypage_partnership)}
                  >
                    제휴서비스 신청 바로가기
                  </button>
                </div>
              </div>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={15}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 제휴 대학·기관을 확인하고 싶어요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 15 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 15 ? 'active' : 'none'}`}
                >
                  <em>
                    1)&nbsp;
                    <button
                      onClick={() => history.push(RoutePaths.mypage_partnership)}
                    >
                      [제휴서비스 신청]
                    </button>
                    페이지로 이동
                  </em>
                  <em>2) 제휴 리스트 확인하기 클릭</em>
                </div>
              </div>
              <div className='item'>
                <div
                  className='title'
                  data-faq-sn={16}
                  onClick={onClickFAQItem}
                  role='button'
                >
                  Q. 제휴 서비스를 신청하고 싶어요.
                  <Icon
                    name='arrow-bottom'
                    size={40}
                    className={activeFAQ === 16 ? 'active' : ''}
                  />
                </div>
                <div
                  className={`content ${activeFAQ === 16 ? 'active' : 'none'}`}
                >
                  <em>현재 잡다는 대학, 혹은 기관만 제휴 신청이 가능합니다.</em>
                  <em>
                    <a href={`mailto:${SupportMail}`}>{SupportMail}</a>으로 메일
                    부탁 드립니다.
                  </em>
                </div>
              </div>
            </>
          )}
        </FAQList>
      </StyledContentFrame>
    </Frame>
  );
};

export default JobdaFaq;
