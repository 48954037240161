import Icon from '__designkit__/icon/Icon';
import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import { IconClose20 } from 'consts/assets/icons/iconPages';
import { absoluteCenterCenter, dimmer } from 'consts/style/mixins';
import shadows from 'consts/style/shadows';
import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';

export interface IJDBaseModal {
  isOpen: boolean;
  onClickClose: () => void;
  children?: React.ReactNode;
  dimmed?: boolean; // 배경 딤처리
  isDimCloseBtn?:boolean; // 딤에 흰색 닫기 버튼
  isDimClickClose?: boolean // 딤 클릭시 닫기
  isBackContentClickable?: boolean; // 모달 뒤에 컨텐츠 클릭 가능할 수 있게 처리;
  onDidDismiss?: () => void;
  onDidPresent?: () => void;
  onWillDismiss?: () => void;
  onWillPresent?: () => void;
  onSuccess?: () => void;
  underFullModalZIndex?: boolean;
}

const StyledContent = styled.div<{ position?: 'center' | 'top-center' }>`
  ${(props) => {
    if (props.position === 'center') {
      return ``;
    }
    return `${absoluteCenterCenter()}`;
  }}

  ${shadows.xlarge};
`;

const JDBaseModalDimFrame = styled.div<{dimmed?: boolean, isBackContentClickable?:boolean, underFullModalZIndex?:boolean}>`
  position: relative;
  ${dimmer()};
  z-index: ${(props) => (props.underFullModalZIndex ? 10005 : 10006)};
  opacity: 0;
  pointer-events: none;
  animation-duration: 0.25s;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
  ${(props) => !props.dimmed
   && css`
     background: transparent;
  `}
  ${(props) => props.isBackContentClickable && css`
    width: fit-content;
    height:fit-content;
    position: absolute;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `}
  
  ${StyledContent} {
    ::after {
      animation-duration: 0.25s;
      animation-name: scaleDown;
      animation-fill-mode: forwards;
    }
  }
  
  .close-btn {
    z-index:1;
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    background: none;
  }
  
  &.active {
    pointer-events: inherit;
    animation-name: fadeIn;
    ${StyledContent} {
      animation-name: scaleUp;
    }
  }
  
  @keyframes scaleUp {
    from {
      transform: translate(-50%, -50%) scale(0);    
    }
    to {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  
  @keyframes scaleDown {
    from {
      transform: translate(-50%, -50%) scale(1);
    }
    to {
      transform: translate(-50%, -50%) scale(0);    
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const JDBaseModal: FC<IJDBaseModal & { position?: 'center' | 'top-center' }> = ((props) => {
  const { position, children, isOpen, isDimCloseBtn = false, isDimClickClose = false, onClickClose, onDidDismiss, onDidPresent, onWillDismiss, onWillPresent, dimmed = true, onSuccess, isBackContentClickable = false, underFullModalZIndex = false } = props;
  const [isOpenState, setIsOpenState] = useState<boolean>(isOpen);

  useEffect(() => {
    if (isOpen) {
      if (isOpenState) {
        onDidPresent && onDidPresent();
      } else {
        onWillPresent && onWillPresent();
      }
    } else if (isOpenState) {
      onWillDismiss && onWillDismiss();
    } else {
      onDidDismiss && onDidDismiss();
    }
  }, [isOpen, isOpenState, onWillPresent, onDidPresent, onWillDismiss, onDidDismiss]);

  const animationEndHandler = () => {
    setIsOpenState(isOpen);
  };

  if (isOpen || isOpenState)
    return (
      <Portal>
        {isOpen && <DisableScroll />}
        <JDBaseModalDimFrame underFullModalZIndex={underFullModalZIndex} dimmed={dimmed} isBackContentClickable={isBackContentClickable} className={isOpen ? 'active' : ''} onAnimationEnd={animationEndHandler} onClick={() => { if (isDimClickClose && onClickClose) onClickClose(); }}>
          <StyledContent
            position={position}
            onClick={(e) => e.stopPropagation()} // styledContent 클릭시 모달이 닫히는 것을 방지
          >
            {isDimCloseBtn && <button className='close-btn' onClick={onClickClose}><Icon size={28} name='close' /></button>}
            {children}
          </StyledContent>
        </JDBaseModalDimFrame>
      </Portal>
    );
  return <></>;
});

export default JDBaseModal;
