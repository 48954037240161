import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Icon from '__designkit__/icon/Icon';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { JDAFullScreenModalFrame } from 'components/JDAFullScreenModal';
import { absoluteHorizonCenter } from 'consts/style/mixins';
import { Divider1G40 } from 'components/divider/Divider';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import confetti from 'canvas-confetti';
import MainTutorialCompanyMessageSuggest from './MainTutorialCompanyMessageSuggest';
import MainTutorialUserMessageSuggestAccept from './MainTutorialUserMessageSuggestAccept';
import MainTutorialCompanyMessageInterview from './MainTutorialCompanyMessageInterview';
import MainTutorialCompanyMessagePassed from './MainTutorialCompanyMessagePassed';

const JDModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 56px;
  background: ${colors.WHITE_100};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index:1002;
  padding: calc(env(safe-area-inset-top) + 18px) 12px 18px 8px;
  border-bottom: 1px solid ${colors.CG_40};
  align-items: center;
  .icon-back {
    width: 32px;
    height: 32px;
  }

  .btn-close-position {
    display: flex;
    align-items: center;
    height: 26px;
    padding: 6px 8px;
    font: ${Fonts.B3_Medium};
    color: ${colors.CG_80};
    background-color: ${colors.WHITE_100};
    border: 1px solid ${colors.CG_40};
    border-radius: 4px;

    &[aria-disabled=true] {
      opacity: 0.5;
    }
  }
`;
const Frame = styled(JDAFullScreenModalFrame)`
 

`;
const GNBFrame = styled.div`
      ${absoluteHorizonCenter()};

    padding: 10px 0px;
    .gnb-title-frame{
      display: flex;
      gap:2px;
      .gnb-title {
      color: ${colors.CG_80};
      font: ${Fonts.B1_Bold};
      max-width: 128px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space:pre-line;
    }

    }
  
    .gnb-company {
      display: flex;
      align-items: center;
      margin-top: 2px;
      background: none;

      > span {
        font: ${Fonts.B3_Medium};
        color: ${colors.CG_80};
        max-width: 104px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space:pre-line;
      }

      .down_icon {
        margin-left: 1px;
      }
    }
`;

const ContentFrame = styled.div< { NoticeYn?: boolean } >`
    width: 100%;
    padding: 16px 16px 8px 16px;
    padding-bottom: ${(props) => (props.NoticeYn ? '120px' : '120px')};
`;

const DateDividerFrame = styled.div`
    .date-text {
      width: fit-content;
      position: relative;
      padding: 4px 16px;
      top: -12px;
      left: 50%;
      transform: translate(-50%, 0);
      font: ${Fonts.B3_Medium};
      color: ${colors.CG_60};
      background: ${colors.WHITE_100};
    }
`;

interface IMainTutorialMessage {
    setStep : React.Dispatch<React.SetStateAction<number>>;
    step:number;
    onClose : () => void;
}

const MainTutorialMessage:FC<IMainTutorialMessage> = ({ setStep, step, onClose }) => {
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const FrameRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (step === 6) {
      confetti({
        particleCount: 120,
        angle: 90,
        spread: 60,
        decay: 0.95,
        origin: { x: 0.5, y: 0.8 },
        zIndex: 100000,
      });
    }
  }, [step]);

  useEffect(() => {
    if (lastMessageRef.current && FrameRef.current) {
      FrameRef.current.scrollTo({ top: lastMessageRef.current.offsetTop - 50, behavior: 'smooth' });
    }
  }, [step, lastMessageRef]);
  return (
    <Frame className='match-dashboard-message-modal' ref={FrameRef}>
      <JDModalHeader>
        <Icon name='large-arrow-left' className='icon-back' size={32} />
        <GNBFrame>
          <button>
            <div className='gnb-title-frame'>
              <p className='gnb-title'>매칭 튜토리얼</p>
            </div>
          </button>
        </GNBFrame>
        <button
          className='btn-close-position'
          title='포지션 정보'
        >
          포지션 정보
        </button>
      </JDModalHeader>
      <ContentFrame>
        <DateDividerFrame>
          <Divider1G40 />
          <div className='date-text'>2000.00.00 (토)</div>
        </DateDividerFrame>
        <MainTutorialCompanyMessageSuggest step={step} setStep={setStep} />
        {
  step > 3 && (
    <>
      <MainTutorialUserMessageSuggestAccept />
      <div key='company-interview' ref={lastMessageRef}>
        <SpacingBlock size={16} vertical />
        <MainTutorialCompanyMessageInterview step={step} setStep={setStep} />
      </div>

    </>
  )
}
        {
  step === 6 && (
    <>
      <MainTutorialUserMessageSuggestAccept interview />
      <div key='company-passed' ref={lastMessageRef}>
        <SpacingBlock size={16} vertical />

        <MainTutorialCompanyMessagePassed onClose={onClose} step={step} setStep={setStep} />
      </div>
    </>
  )
}

      </ContentFrame>
    </Frame>
  );
};
export default MainTutorialMessage;
