import IconKakao from 'assets/icon/icon_login_social_kakao.svg';
import { Capacitor3KakaoLogin } from 'capacitor3-kakao-login';
import { OpenIdProvider } from 'interfaces/rqrs/ISocialAuthLoginRs';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import { LanguageType } from 'pages/_v2/jobflex/JobflexLogin';
import { FC, useCallback } from 'react';
import KaKaoLogin from 'react-kakao-login';
import MobileStore from 'store/mobileStore';

interface IKaKaoLoginButton{
  onSuccess:any;
  onFail: any;
  context?:Context
  languageType?:LanguageType
}

const KaKaoLoginButton: FC<IKaKaoLoginButton> = ({ onSuccess, onFail, context = new Context(), languageType = LanguageType.KOR }) => {
  const getKakaoLoginUrl = () => {
    const url = 'https://kauth.kakao.com/oauth/authorize'
      + `?client_id=${process.env.REACT_APP_KAKAO_JS_KEY}`
      + `&redirect_uri=${window.location.origin}/oauth2/callback/kakao`
      + `&response_type=code`;
    return url;
  };

  const kakaoLoginUrl = getKakaoLoginUrl();

  const onClickMobileKaKaoLogin = useCallback(async () => {
    try {
      const { value } = await Capacitor3KakaoLogin.kakaoLogin();
      onSuccess(value, OpenIdProvider.KAKAO);
    } catch (e) {
      onFail(e);
    }
  }, []);

  return (
    <>{
      !MobileStore.isMobile
        ? (
          // <KaKaoLogin
          //   token={process.env.REACT_APP_KAKAO_JS_KEY as string}
          //   useLoginForm
          //   onSuccess={async (e: any) => {
          //     const { access_token } = e.response;
          //     onSuccess(access_token, OpenIdProvider.KAKAO);
          //   }}
          //   onFail={onFail}
          //   render={({ onClick }) => (
          //     <button
          //       className='social-login-btn'
          //       onClick={(e) => {
          //         e.preventDefault();
          //         onClick();
          //       }}
          //     >
          //       <img alt='카카오 로그인' src={IconKakao} />
          //       <span>카카오 로그인</span>
          //     </button>
          //   )}
          // />
          <a
            href={kakaoLoginUrl}
            className='social-login-btn'
          >
            <img alt='카카오 로그인' src={IconKakao} />
            <span>{languageType === LanguageType.KOR ? '카카오 로그인' : 'Kakao'}</span>
          </a>
        )
        : (
          <button
            className='social-login-btn'
            onClick={(e) => {
              e.preventDefault();
              onClickMobileKaKaoLogin();
            }}
          >
            <img alt='카카오 로그인' src={IconKakao} />
            <span>{languageType === LanguageType.KOR ? '카카오 로그인' : 'Kakao'}</span>
          </button>
        )
    }
    </>
  );
};

export default inject(injectStore.context)(observer(KaKaoLoginButton));
