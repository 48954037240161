import { PushNotifications } from '@capacitor/push-notifications';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import NoticeUpdateResultModal, { onOffType } from 'components/NoticeUpdateResultModal';
import ToggleSwitch from 'components/ToggleSwitch';
import NoticeTemplateModal, { NoticeType } from 'components/_v2/_common/modals/NoticeTemplateModal';
import PushNoticeModal from 'components/_v2/_common/modals/PushNoticeModal';
import useToast from 'hooks/useToast';
import ILoginPageProps from 'interfaces/props/ILoginPageProps';
import { DateTime } from 'luxon';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';

import { registerPlugin } from '@capacitor/core';
import NoticeDialog, { DialogType } from 'components/modals/NoticeDialog';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { Divider12Grey, Divider8Grey } from 'components/divider/Divider';
import { DATE_FORMAT_YY_MM_DD_DOT } from '../../../utils/DateUtils';

export interface AndroidPostNotificationsPermissionPlugin {
  areNotificationsEnabled(): Promise<{ enabled: boolean }>;
}

const AndroidPostNotificationsPermission = registerPlugin<AndroidPostNotificationsPermissionPlugin>('AndroidPostNotificationsPermission', {});

const Frame = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:stretch;
  .frame-row {
    padding:0 24px;
    margin-top:32px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    .frame-title {
      flex:1;
      font: ${Fonts.B1_Medium};
      color:${colors.CG_90};
    }
  }
  .frame-detail{
  padding: 0 24px;
  margin-top: 16px;
  display:flex;
  font: ${Fonts.B3_Medium};
  color:${colors.CG_70}
}
  .frame-modal {
    display:block;
    width:304px;
  }
`;
const NoticeFrame = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SpaceValue.XXL}px ${SpaceValue.L}px;
  gap:${SpaceValue.L}px;
  .toggle-frame{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-desc{
      display: flex;
      flex-direction: column;
      gap:4px;
    }
  }
`;

const NoticeNotApp = styled.div`
  padding: ${SpaceValue.XXL}px ${SpaceValue.L}px;
  display: flex;
  flex-direction: column;
  gap:${SpaceValue.L}px;
`;
const MyPageNotice:FC<ILoginPageProps> = ({ login = new Login() }) => {
  const [SMSOnModalVisible, setSMSOnModalVisible] = useState<boolean>(false);
  const [SMSOffModalVisible, setSMSOffModalVisible] = useState<boolean>(false);
  const [EMAILOnModalVisible, setEMAILOnModalVisible] = useState<boolean>(false);
  const [EMAILOffModalVisible, setEMAILOffModalVisible] = useState<boolean>(false);
  const [PUSHOffModalVisible, setPUSHOffModalVisible] = useState<boolean>(false);
  const [userNotificationPermission, setUserNotificationPermission] = useState<boolean>(false);
  const [updateType, setUpdateType] = useState<NoticeType>(NoticeType.SMS);
  const [updateOnOff, setUpdateOnOff] = useState<onOffType>('ON');
  const [updateResultModalVisible, setUpdateResultModalVisible] = useState<boolean>(false);
  const { setToastObject } = useToast();
  useEffect(() => {
    login.loadUserDefault();
  }, []);

  useEffect(() => {
    login.loadMarketingConsent();
  }, [login]);

  useEffect(() => {
    // 설정 변경하고 앱 화면으로 돌아왔을때 실시간 변화 확인
    document.addEventListener('visibilitychange', checkNotificationPermission);
    checkNotificationPermission();
    return () => {
      document.removeEventListener('visibilitychange', checkNotificationPermission);
    };
  }, []);

  const checkNotificationPermission = async () => {
    const permStatus = await PushNotifications.checkPermissions();
    let response = { enabled: false };
    try {
      response = await AndroidPostNotificationsPermission.areNotificationsEnabled();
    } catch (e) {
      console.error(e);
    }

    if (MobileStore.currentPlatform === 'android') {
      if (response.enabled) setUserNotificationPermission(true);
      else setUserNotificationPermission(false);
    } else if (permStatus.receive === 'granted') setUserNotificationPermission(true);
    else setUserNotificationPermission(false);
  };

  const openSettings = async () => {
    if (MobileStore.currentPlatform === 'ios') {
      await NativeSettings.openIOS({ option: IOSSettings.App });
    } else {
      await NativeSettings.openAndroid({ option: AndroidSettings.ApplicationDetails });
    }
  };

  return (
    <Frame>
      {
        !MobileStore.isMobile
          ? (
            <NoticeNotApp>
              <V2Text fontStyle={V2TextOption.fontStyle.body_3_m} color={V2TextOption.color.subtle}>
                앱 푸시
              </V2Text>
              <V2Text fontStyle={V2TextOption.fontStyle.body_2_m} color={V2TextOption.color.subtlest}>
                {`‘JOBDA 앱 > 설정 > 알림 설정’에서 변경 가능합니다.`}
              </V2Text>
            </NoticeNotApp>
          )
          : (
            <NoticeFrame>
              <V2Text fontStyle={V2TextOption.fontStyle.body_3_m} color={V2TextOption.color.subtle}>
                앱 푸시
              </V2Text>
              <div className='toggle-frame'>
                <div className='title-desc'>
                  <V2Text fontStyle={V2TextOption.fontStyle.body_1_sb} color={V2TextOption.color.default}>
                    서비스 알림
                  </V2Text>
                  <V2Text fontStyle={V2TextOption.fontStyle.body_2_m} color={V2TextOption.color.subtle}>
                    전형 안내, 검사, 매칭 제안에 대한 안내 및 요청
                  </V2Text>
                </div>
                <ToggleSwitch
                  onChange={() => {
                    openSettings();
                  }}
                  checked={userNotificationPermission}
                />
              </div>
            </NoticeFrame>
          )
      }
      <Divider8Grey />
      <NoticeFrame>
        <V2Text fontStyle={V2TextOption.fontStyle.body_3_m} color={V2TextOption.color.subtle}>
          이메일
        </V2Text>
        <div className='toggle-frame'>
          <div className='title-desc'>
            <V2Text fontStyle={V2TextOption.fontStyle.body_1_sb} color={V2TextOption.color.default}>
              채용, 이벤트, 혜택 정보
            </V2Text>
            <V2Text fontStyle={V2TextOption.fontStyle.body_2_m} color={V2TextOption.color.subtle}>
              채용 정보, 취업 준비에 도움이 되는 이벤트
            </V2Text>
          </div>
          <ToggleSwitch
            onChange={async (e:ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked)
                try {
                  if (!(await login.updateNotificationSettings('EMAIL', true)))
                    throw new Error('failed');
                  const DateTimeText = DateTime.local().toFormat(DATE_FORMAT_YY_MM_DD_DOT);
                  setToastObject({ isIcon: false, isOpen: true, type: 'SUCCESS', message: `${DateTimeText} 채용, 이벤트, 혜택 정보에 대한 이메일 수신을 동의했습니다.` });
                } catch (error) {
                  console.error(error);
                  alert('다시 시도해 주세요.');
                } finally {
                  setEMAILOnModalVisible(false);
                }
              else
                setEMAILOffModalVisible(true);
            }}
            checked={login.marketingConsentRs?.emailYn}
          />
        </div>
      </NoticeFrame>
      <Divider8Grey />
      <NoticeFrame>
        <V2Text fontStyle={V2TextOption.fontStyle.body_3_m} color={V2TextOption.color.subtle}>
          문자
        </V2Text>
        <div className='toggle-frame'>
          <div className='title-desc'>
            <V2Text fontStyle={V2TextOption.fontStyle.body_1_sb} color={V2TextOption.color.default}>
              채용, 이벤트, 혜택 정보
            </V2Text>
            <V2Text fontStyle={V2TextOption.fontStyle.body_2_m} color={V2TextOption.color.subtle}>
              채용 정보, 취업 준비에 도움이 되는 이벤트
            </V2Text>
          </div>
          <ToggleSwitch
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked)
                if (!login.userDefault?.mobile) setToastObject({ isIcon: false, isOpen: true, type: 'ERROR', message: '핸드폰 인증 완료 후, 설정 가능합니다' });
                else {
                  try {
                    if (!(await login.updateNotificationSettings('SMS', true)))
                      throw new Error('failed');
                    const DateTimeText = DateTime.local().toFormat(DATE_FORMAT_YY_MM_DD_DOT);
                    setToastObject({ isIcon: false, isOpen: true, type: 'SUCCESS', message: `${DateTimeText} 채용, 이벤트, 혜택 정보에 대한 문자 수신을 동의했습니다.` });
                  } catch (error: any) {
                    console.error(error);
                    if (error.response.data.errorCode === 'B901') setToastObject({ isIcon: false, isOpen: true, type: 'ERROR', message: '핸드폰 인증 완료 후, 설정 가능합니다' });
                    setToastObject({ isIcon: false, isOpen: true, type: 'ERROR', message: '변경에 실패했습니다. 다시 시도해 주세요.' });
                  } finally {
                    setSMSOnModalVisible(false);
                  }
                }

              else
                setSMSOffModalVisible(true);
            }}
            checked={login.marketingConsentRs?.smsYn}
          />
        </div>
      </NoticeFrame>

      <NoticeDialog
        title='이메일 알림을 끄시겠어요?'
        content='취업에 꼭 도움 되는 채용 소식과 이벤트, 혜택만 전해드릴게요.'
        isOpen={EMAILOffModalVisible}
        onCancel={async () => {
          try {
            if (!(await login.updateNotificationSettings('EMAIL', false)))
              throw new Error('failed');
            const DateTimeText = DateTime.local().toFormat(DATE_FORMAT_YY_MM_DD_DOT);
            setToastObject({ isIcon: false, isOpen: true, type: 'INFO', message: `${DateTimeText} 채용, 이벤트, 혜택 정보에 대한 이메일 수신을 거부했습니다.` });
          } catch (e) {
            console.error(e);
            alert('다시 시도해 주세요.');
          } finally {
            setEMAILOffModalVisible(false);
          }
        }}
        onOk={() => setEMAILOffModalVisible(false)}
        dialogType={DialogType.ALERT}
        firstButtonText='알림 유지하기'
        secondButtonText='끄기'
      />
      <NoticeDialog
        title='문자 알림을 끄시겠어요?'
        content='취업에 꼭 도움 되는 채용 소식과 이벤트, 혜택만 전해드릴게요.'
        isOpen={SMSOffModalVisible}
        onCancel={async () => {
          try {
            if (!(await login.updateNotificationSettings('SMS', false)))
              throw new Error('failed');
            const DateTimeText = DateTime.local().toFormat(DATE_FORMAT_YY_MM_DD_DOT);
            setToastObject({ isIcon: false, isOpen: true, type: 'INFO', message: `${DateTimeText} 채용, 이벤트, 혜택 정보에 대한 문자 수신을 거부했습니다.` });
          } catch (e) {
            console.error(e);
            alert('다시 시도해 주세요.');
          } finally {
            setSMSOffModalVisible(false);
          }
        }}
        onOk={() => setSMSOffModalVisible(false)}
        dialogType={DialogType.ALERT}
        firstButtonText='알림 유지하기'
        secondButtonText='끄기'
      />
      {/* <NoticeDialog
        title='푸시 알림을 끄시겠어요?'
        content='취업에 꼭 도움 되는 채용 소식과 이벤트, 혜택만 전해드릴게요.'
        isOpen={PUSHOffModalVisible}
        onCancel={() => {
          openSettings();
          setPUSHOffModalVisible(false);
        }}
        onOk={() => setPUSHOffModalVisible(false)}
        dialogType={DialogType.NOTICE}
        firstButtonText='알림 유지하기'
        secondButtonText='끄기'
      /> */}
    </Frame>
  );
};

export default inject(injectStore.login)(observer(MyPageNotice));
