import Icon from '__designkit__/icon/Icon';
import React, { FC } from 'react';
import styled from 'styled-components';
import ImgTutorial from 'assets/_v2/main/img_tutorial_main.png';
import ImgBgTutorial from 'assets/_v2/main/img_tutorial_main_bg.png';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background-image: url(${ImgBgTutorial});
    background-size: cover;
    background-position: center;
    z-index: -1;
  }
`;
const HeaderFrame = styled.div`
  display: flex;
  justify-content: flex-end;
  padding:12px;

`;
const ContentsFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;

  .img-tutorial{
    width: 100%;
    height: auto;
  }
`;
interface IMainTutoriamMain {
  onClose: () => void;
}
const MainTutorialMain:FC<IMainTutoriamMain> = ({ onClose }) => (
  <Frame>
    <HeaderFrame>
      <div role='button' onClick={onClose}>
        <Icon name='close' size={32} />
      </div>
    </HeaderFrame>
    <ContentsFrame>
      <img src={ImgTutorial} alt='tutorial' className='img-tutorial' />
      <SpacingBlock size={SpaceValue.XXL} vertical />
      <V2Text fontStyle={V2FontStyleType.body_2_b} color={V2TextColorsType.brand}>매칭 튜토리얼</V2Text>
      <SpacingBlock size={SpaceValue.XS} vertical />
      <V2Text fontStyle={V2FontStyleType.title_1_b} color={V2TextColorsType.default}>매칭 경험 체험하기</V2Text>
      <SpacingBlock size={SpaceValue.L} vertical />
      <V2Text fontStyle={V2FontStyleType.body_1_m} color={V2TextColorsType.subtle}>
        매칭 제안을 통해 입사지원을
        <br />
        경험할 수 있는 튜토리얼이에요.
        <br />
        지금 바로 입사 지원 제안을 경험해 보세요!
      </V2Text>
    </ContentsFrame>
  </Frame>
);

export default MainTutorialMain;
