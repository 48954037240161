import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDBaseInput from 'components/_v2/_common/input/JDBaseInput';
import JDPasswordInput from 'components/_v2/_common/input/JDPasswordInput';
import RoutePaths from 'consts/RoutePaths';
import { JDCheckBoxInput, JDMainButton } from 'consts/_v2/_common/style/mixins';
import ILocationState from 'interfaces/ILocationState';
import ILoginPageProps from 'interfaces/props/ILoginPageProps';
import { OpenIdProvider } from 'interfaces/rqrs/ISocialAuthLoginRs';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { LoginErrorCode } from 'models/Login';
import { injectStore } from 'models/store';
import { FindAccountPage } from 'pages/FindAccount';
import React, { ChangeEvent, FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';
import AuthorizeUtil from 'utils/AuthorizeUtil';
import { LanguageType } from 'pages/_v2/jobflex/JobflexLogin';

const StyledFormFrame = styled.div`
  padding: 24px 0 0px 0;

  .id-input{
    margin-bottom: 8px;
  }
 
  .password-input{
    margin-bottom: 16px;
  }

  .login-label-frame {
    margin-bottom: 24px;
    display: flex;
    justify-content:flex-end;
    span, a {
      align-self: center;
      color: ${colors.JOBDA_BLACK};
      font: ${Fonts.B2_Medium};
    }
  
    .checkbox-label {
      display: flex;
      align-items: center;
      input {
        ${JDCheckBoxInput()}
      }
      span {
        margin-left: 8px;
        vertical-align: -webkit-baseline-middle;
      }
    }
  }

  .jobda-login-frame {
    margin-bottom: 8px;
    button {
      ${JDMainButton(false)};
      font: ${Fonts.B1_Bold};
      height: 52px;
      border-radius: 4px;

    }  
  }
`;

const AgreeFrame = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 24px;
    .check-frame{
        display: flex;
        .check-icon{

        }
        .check-title{
            font:${Fonts.B2_Medium_P};
            color:${colors.CG_90};
            margin-left: 8px;
        }
    }
    .more-icon{

    }
`;
const LoginForm: FC<ILoginPageProps> = ((props) => {
  const { context = new Context(), login, languageType = LanguageType.KOR, builder = false, insight = false, isChecked = false, setOpenPrivacyNotice, setIsChecked, setLoginAction, mypage = false } = props;
  const useFormed = useForm({ mode: 'onChange' });
  const { watch, setError } = useFormed;
  const history = useHistory();

  const onLoginSuccess = useCallback(() => {
    if (context.loginRedirectPath !== RoutePaths.root) {
      const redirectPath = context.loginRedirectPath;
      context.setRedirectUrl(RoutePaths.root);
      history.replace(redirectPath);
      return;
    }

    const state = history.location.state as (ILocationState | undefined);

    if (state?.key !== 'jobda') {
      history.replace(RoutePaths.root);
    }
    history.goBack();
  }, [context.loginRedirectPath]);

  const onLoginHandler = async () => {
    if (setIsChecked && !mypage) {
      if (!isChecked) return;
    }
    try {
      const id = watch('id');
      const password = watch('password');
      await login?.login(id, password);
      context.autoLogin = true;
      if (mypage) {
        if (setIsChecked) setIsChecked(true);
        if (setLoginAction)setLoginAction(true);
      }
      if (setLoginAction) {
        setLoginAction(true);
      }
      await login?.loadCurrentUser();

      AuthorizeUtil.setIsAutoLogin(context.autoLogin);
      if (MobileStore.isMobile) AuthorizeUtil.setAutoLoginInfo(OpenIdProvider.WEB);

      if (!builder && !insight && !mypage) onLoginSuccess();
    } catch (e: any) {
      if (e === LoginErrorCode.U002) {
        setError('id', { type: 'string', message: '' });
        setError('password', { type: 'string', message: '가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.' });
      } else if (e === LoginErrorCode.U003) {
        setError('password', { type: 'string', message: '5회 이상 비밀번호를 잘못 입력했습니다. 비밀번호를 다시 설정해 주세요.' });
        setTimeout(() => {
          history.replace(RoutePaths.findAccount(FindAccountPage.PW));
        }, 2000);
      } else if (e.response?.data.errorCode === LoginErrorCode.B001) {
        setError('id', { type: 'string', message: '아이디를 입력해 주세요.' });
        setError('password', { type: 'string', message: '비밀번호를 입력해 주세요.' });
      }
    }
  };

  const onKeyPressEnterHandler = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onLoginHandler();
    }
  };

  return (
    <>
      <StyledFormFrame>
        <FormProvider {...useFormed}>
          <form>
            <JDBaseInput
              className='id-input'
              name='id'
              placeholder={languageType === LanguageType.KOR ? '아이디를 입력해 주세요.' : 'Enter your ID'}
              onKeyPress={onKeyPressEnterHandler}
              btnClear
            />
            <JDPasswordInput
              className='password-input'
              name='password'
              placeholder={languageType === LanguageType.KOR ? '비밀번호를 입력해 주세요.' : 'Enter your password'}
              onContextMenu={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => e.preventDefault()}
              onKeyPress={onKeyPressEnterHandler}
            />
            <div className='login-label-frame'>
              <Link to={RoutePaths.findAccount(FindAccountPage.ID)}>아이디ㆍ비밀번호 찾기</Link>
            </div>
            {
              insight && (
                <AgreeFrame>
                  <div
                    role='button'
                    className='check-frame'
                    onClick={() => {
                      if (setIsChecked) {
                        setIsChecked(!isChecked);
                      }
                    }}
                  >
                    <Icon name='check-circle-filled' size={24} color={isChecked ? colors.G_150 : colors.CG_50} />
                    <div className='check-title'>{languageType === LanguageType.KOR ? '개인정보 제 3자 제공에 동의합니다. (필수)' : 'I agree to the provision of personal information to third parties. (Required)'}</div>
                  </div>
                  <div
                    role='button'
                    onClick={() => {
                      if (setOpenPrivacyNotice) {
                        setOpenPrivacyNotice(true);
                      }
                    }}
                  >
                    <Icon name='large-arrow-right' size={24} color={colors.CG_50} />
                  </div>
                </AgreeFrame>
              )
            }
            <div className='jobda-login-frame'>
              <button
                type='button'
                onClick={onLoginHandler}
              >
                {languageType === LanguageType.KOR ? '로그인' : 'Sign in'}
              </button>
            </div>
          </form>
        </FormProvider>
      </StyledFormFrame>
    </>
  );
});

export default inject(injectStore.context, injectStore.login)(observer(LoginForm));
