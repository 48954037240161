import { PushNotificationSchema, PushNotifications } from '@capacitor/push-notifications';
import RoutePaths from 'consts/RoutePaths';
import URIs from 'consts/URIs';
import { MediumEnum } from 'consts/_v2/notification/NotificationPushType';
import { IPushNotificationSendLogSaveRq, NotificationLogType } from 'interfaces/_v2/log/ILogRqRs';
import { INotificationLogRq } from 'interfaces/_v2/notification/INotificationLogRq';
import { NotificationType } from 'interfaces/_v2/notification/INotificationRqRs';
import PushUtils from 'utils/PushUtils';
import { request } from 'utils/request';

const BASIC_TOPIC = 'push_agreed';

const FCM_TOKEN: string = '';

export const notificationData = { value: null as INotificationLogRq | null };

const register = async () => {
  try {
    await PushNotifications.addListener('registration', (token) => {
      console.info('Registration token: ', token.value);
      PushUtils.FCM_TOKEN = token.value;
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    // TODO: 서버에서 알림 받았을 때 수행. (프론트에서 Notification 작업해서 수행하c고 싶다면 local-notifications 사용)
    await PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      console.log('Push notification received: ', notification);
    });

    // 사용자가 알림을 탭했을 때 특정 동작 수행.
    await PushNotifications.addListener('pushNotificationActionPerformed', async (action) => {
      console.log('Push notification action performed', action.actionId, action.inputValue);

      const { data } = action.notification;
      let url = '';
      let dataRq: INotificationLogRq;
      let positionSn = 0;
      let logDataRq:IPushNotificationSendLogSaveRq;
      const matchingTalkMessageSn = -1;
      let channelId = '';
      if (data && data.pushNotificationType) {
        dataRq = {
          notificationId: data.notificationId,
          notificationType: data.pushNotificationType,
          mediumType: MediumEnum.PUSH,
        };
        logDataRq = {
          matchingTalkMessageSn: data.matchingTalkMessageSn,
          notificationLogType: NotificationLogType.MATCH_OFFER,
        };
        switch (data.pushNotificationType) {
          case NotificationType.INSIGHT_RESUME_SAVED:
            url = data.chatUrl;
            break;
          case NotificationType.CHAT:
            channelId = action.notification.data.channelId;
            url = `${RoutePaths.chatChannel}?channelId=${channelId}`;
            break;
          case NotificationType.ACC_COMPLETE:
            url = `/?utm_source=app&utm_medium=push&utm_campaign=jda_complete_acc`;
            break;
          case NotificationType.PACC_COMPLETE:
            url = `/?utm_source=app&utm_medium=push&utm_campaign=jda_complete_pacc`;
            break;
          case NotificationType.PST_COMPLETE:
            url = `/?utm_source=app&utm_medium=push&utm_campaign=jda_complete_pst`;
            break;
          case NotificationType.ACC_ANALYSIS_COMPLETE:
            url = `/acca/results?utm_source=app&utm_medium=push&utm_campaign=jda_analyzed_acc&utm_content=check_analyzed_acc`;
            break;
          case NotificationType.PACC_ANALYSIS_COMPLETE:
            url = `/phs/pacc/results?utm_source=app&utm_medium=push&utm_campaign=jda_analyzed_pacc&utm_content=check_analyzed_pacc`;
            break;
          case NotificationType.PST_ANALYSIS_COMPLETE:
            url = `/phs/pst/results?utm_source=app&utm_medium=push&utm_campaign=jda_analyzed_pst&utm_content=check_analyzed_pst`;
            break;
          case NotificationType.MATCHING_OFFER:
            positionSn = action.notification.data.positionSn;
            url = `/match/apply/status?tab=MATCH_OFFER&sn=${positionSn}&mediumType=PUSH&utm_source=app&utm_medium=push&utm_campaign=jda_offered_matching&utm_content=answer_offered_matching`;
            break;
          case NotificationType.MATCHING_OFFER_DEADLINE_REMINDER_D_DAY:
            positionSn = action.notification.data.positionSn;
            url = `/match/apply/status?tab=MATCH_OFFER&sn=${positionSn}&mediumType=PUSH&utm_source=app&utm_medium=push&utm_campaign=jda_offered_matching_dday&utm_content=answer_offered_matching`;
            break;
          case NotificationType.JOB_APPLICATION_DEADLINE_REMINDER_D_3:
            url = `/mypage/applying?utm_source=app&utm_medium=push&utm_campaign=jda_apply_nday&utm_content=write_application`;
            break;
          case NotificationType.JOB_APPLICATION_DEADLINE_REMINDER_D_DAY:
            url = `/mypage/applying?utm_source=app&utm_medium=push&utm_campaign=jda_apply_dday&utm_content=write_application`;
            break;
          case NotificationType.ACC_LITE_COMPLETE:
            url = `/?utm_source=app&utm_medium=push&utm_campaign=ats_complete_acc`;
            break;
          case NotificationType.JOB_APPLICATION_SUBMITTED:
            channelId = action.notification.data.channelId;
            url = `/chat/channel?channelId=${channelId}&utm_source=app&utm_medium=push&utm_campaign=jda_complete_apply&utm_content=join_chat`;
            break;
          case NotificationType.JOB_APPLICATION_CANCELED:
            url = `/?utm_source=app&utm_medium=push&utm_campaign=jda_cancel_apply`;
            break;
          case NotificationType.DELETE_JOB_APPLICATION:
            url = `/?utm_source=app&utm_medium=push&utm_campaign=ats_canceled_apply`;
            break;
          case NotificationType.COPY_JOB_APPLICATION:
            url = `/mypage/apply?utm_source=app&utm_medium=push&utm_campaign=ats_changed_apply&utm_content=check_status_apply`;
            break;
          case NotificationType.SIMPLE_MESSAGE:
            positionSn = action.notification.data.positionSn;

            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_received_message&utm_content=check_received_message`;
            break;
          case NotificationType.FILE_REQUEST:
            positionSn = action.notification.data.positionSn;

            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_requested_file&utm_content=submit_requested_file`;
            break;
          case NotificationType.FILE_REQUEST_DEADLINE_REMINDER_D_DAY:
            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_requested_file_dday&utm_content=submit_requested_file`;
            break;
          case NotificationType.FILE_REQUEST_SUBMITTED:
            url = `/?utm_source=app&utm_medium=push&utm_campaign=ats_submit_file`;
            break;
          case NotificationType.ACC_REQUEST:
            positionSn = action.notification.data.positionSn;
            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_requested_pacc_nday&utm_content=submit_requested_pacc`;
            break;
          case NotificationType.PHS_REQUEST:
            positionSn = action.notification.data.positionSn;
            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_requested_phs&utm_content=submit_requested_phs`;
            break;
          case NotificationType.ACC_RESULT_SUBMITTED_REMINDER_D_3:
            positionSn = action.notification.data.positionSn;
            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_requested_acc_nday&utm_content=submit_requested_acc`;
            break;
          case NotificationType.PACC_RESULT_SUBMITTED_REMINDER_D_3:
            positionSn = action.notification.data.positionSn;
            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_requested_pacc_nday&utm_content=submit_requested_pacc`;
            break;
          case NotificationType.PST_RESULT_SUBMITTED_REMINDER_D_3:
            positionSn = action.notification.data.positionSn;
            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_requested_pst_nday&utm_content=submit_requested_pst`;
            break;
          case NotificationType.ACC_RESULT_SUBMITTED_REMINDER_D_DAY:
            positionSn = action.notification.data.positionSn;
            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_requested_acc_dday&utm_content=submit_requested_acc`;
            break;
          case NotificationType.PACC_RESULT_SUBMITTED_REMINDER_D_DAY:
            positionSn = action.notification.data.positionSn;
            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_requested_pacc_dday&utm_content=submit_requested_pacc`;
            break;
          case NotificationType.PST_RESULT_SUBMITTED_REMINDER_D_DAY:
            positionSn = action.notification.data.positionSn;
            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_requested_pst_dday&utm_content=submit_requested_pst`;
            break;
          case NotificationType.ACC_RESULT_SUBMITTED:
            url = `/?utm_source=app&utm_medium=push&utm_campaign=ats_submit_jda_acc`;
            break;
          case NotificationType.ACC_LITE_RESULT_SUBMITTED:
            url = `/?utm_source=app&utm_medium=push&utm_campaign=ats_submit_acc`;
            break;
          case NotificationType.PACC_RESULT_SUBMITTED:
            url = `/?utm_source=app&utm_medium=push&utm_campaign=ats_submit_pacc`;
            break;
          case NotificationType.PST_RESULT_SUBMITTED:
            url = `/?utm_source=app&utm_medium=push&utm_campaign=ats_submit_pst`;
            break;
          case NotificationType.INTERVIEW_OFFER:
            positionSn = action.notification.data.positionSn;
            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_offered_interview&utm_content=answer_offered_interview`;
            break;
          case NotificationType.INTERVIEW_SCHEDULE_ADJUST:
            positionSn = action.notification.data.positionSn;
            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_offered_interview_schedule&utm_content=answer_offered_interview_schedule`;
            break;
          case NotificationType.INTERVIEW_SCHEDULE_CONFIRM:
            positionSn = action.notification.data.positionSn;
            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_received_interview_schedule&utm_content=check_received_interview_schedule`;
            break;
          case NotificationType.INTERVIEW_OFFER_DEADLINE_REMINDER_D_3:
            positionSn = action.notification.data.positionSn;
            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_offered_interview_nday&utm_content=answer_offered_interview`;
            break;
          case NotificationType.INTERVIEW_OFFER_DEADLINE_REMINDER_D_DAY:
            positionSn = action.notification.data.positionSn;
            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_offered_interview_dday&utm_content=answer_offered_interview`;
            break;
          case NotificationType.INTERVIEW_OFFER_RESPONDED:
            url = `/pass/interview?utm_source=app&utm_medium=push&utm_campaign=ats_answer_interview&utm_content=practice_interview`;
            break;
          case NotificationType.STATUS_CHANGE:
            positionSn = action.notification.data.positionSn;
            url = `/mypage/apply?tab=IN_PROGRESS&sn=${positionSn}&utm_source=app&utm_medium=push&utm_campaign=ats_received_result&utm_content=check_status_result`;
            break;
          default:
            break;
        }
        notificationData.value = dataRq;
        window.location.href = url;
        try {
          await request({
            method: 'post',
            url: URIs.post_notification_log,
            data: dataRq,
          });
          await request({
            method: 'post',
            url: URIs.post_v2_notification_logs_send_push,
            data: logDataRq,
          });
          console.log('Push notification click');
        } catch (error) {
          console.error('test error', error);
        }
      }
    });

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
    // FCM.subscribeTo({ topic: BASIC_TOPIC });
  } catch (e) {
    console.error(e);
  }
};

export { BASIC_TOPIC, FCM_TOKEN, register };
export default ({ BASIC_TOPIC, FCM_TOKEN, register });
