import Colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import { PhsAccType } from 'consts/MatchingMessageType';
import IconTierBronze from 'assets/_v2/phs/pst/icon_pst_tier_bronze.svg';
import IconTierGold from 'assets/_v2/phs/pst/icon_pst_tier_gold.svg';
import IconTierPlatinum from 'assets/_v2/phs/pst/icon_pst_tier_platinum.svg';
import IconTierSilver from 'assets/_v2/phs/pst/icon_pst_tier_silver.svg';
import IconTierTryAgain from 'assets/_v2/phs/pst/icon_pst_tier_try.svg';
import { AccGradeResultType } from 'consts/_v2/acc/AccApplyType';
import { PstGradeResultType, PstGradeResultTypeText } from 'consts/_v2/phs/pst/PstApplyType';
import React, { FC } from 'react';
import styled from 'styled-components';
import ImgStar from 'assets/_v2/phs/pst/img_pst_badge_star.svg';
import V2Badge from '__pc__/components/common/v2Design/badge';
import { V2BadgeOption, V2BadgeType } from '__pc__/constant/v2Design/V2BadgeType';

const Frame = styled.div`
 .progress {
      display: flex;
      padding:6px 8px;
      height: 25px;
      align-items: center;
      background: ${Colors.CG_30};
      border: 1px solid ${Colors.CG_50};
      border-radius: 4px;
      color: ${Colors.CG_70};
      font: ${Fonts.B4_Medium};
      line-height: 14px;
  }
`;

const GradeBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 48px;
    height: 48px;
  }
`;

interface IAccPstGradeProps {
  accGrade?: AccGradeResultType;
  pstGrade?: PstGradeResultType;
  type: PhsAccType;
  progressYn?: boolean;
}

const AccPstGrade: FC<IAccPstGradeProps> = ({ pstGrade, progressYn }) => {
  const gradeImage = () => {
    switch (pstGrade) {
      case PstGradeResultType.TRY_AGAIN:
        return IconTierTryAgain;
      case PstGradeResultType.BRONZE:
        return IconTierBronze;
      case PstGradeResultType.SILVER:
        return IconTierSilver;
      case PstGradeResultType.GOLD:
        return IconTierGold;
      case PstGradeResultType.PLATINUM:
        return IconTierPlatinum;
      default:
        break;
    }
    return IconTierTryAgain;
  };
  return (
    <Frame>
      {progressYn ? (
        <V2Badge
          size={V2BadgeOption.size.M}
          type={V2BadgeType.OutLine}
          fontStyle={V2BadgeOption.fontStyle.caption_2_sb}
          color={V2BadgeOption.color.subtlest}
          borderColor={V2BadgeOption.borderColor.default}
        >
          분석중
        </V2Badge>
      ) : (
        pstGrade && (
        <GradeBadge>
          {pstGrade !== PstGradeResultType.TRY_AGAIN}
          <img src={gradeImage()} alt='emblem' />
        </GradeBadge>
        )
      )}
    </Frame>
  );
};
export default AccPstGrade;
