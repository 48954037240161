import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { V2BackgroundColors, V2BackgroundColorsType } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { IconLogo } from 'consts/assets/icons/iconPages';
import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Lottie from 'lottie-react';
import IndicatorLottie from '__designkit__/assets/lottie/tutorial_indicate_lottie.json';
import { useUserInfo } from 'query/user/useUserInfoQuery';
import { getCreatedDayTime } from 'utils/DateUtils';
import MainTutorialIndicator from './MainTutorialIndicator';
import MainTutorialWaiting from './MainTutorialWaiting';

const dimOpacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
`;
const Dim = styled.div<{backgroundColor:V2BackgroundColorsType}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => V2BackgroundColors[props.backgroundColor]};
  opacity: 0.6;
  animation: ${dimOpacityAnimation} 0.3s ease-out;
  animation-duration: 0.4s;
  z-index: 10005;
`;

const Frame = styled.div`
    display: flex;
    flex-direction: column;
`;
const TitleFrame = styled.div`
    display: flex;
    align-items: center;
    gap:8px;
    .jda-icon{
        display: flex;
        width: 24px;
        height: 24px;
        padding: 0 3px;
        justify-content: center;
        align-items: center;
        border: 0.5px solid ${V2BorderColors.default};
        border-radius: 24px;
    }
    .name-text{
        font: ${Fonts.B2_Bold};
        color: ${Colors.CG_90};
    }
    .time-text{
        font: ${Fonts.B3_Medium};
        color: ${Colors.cA4A4A4};
    }
`;

const MessageFrame = styled.div<{step?:number}>`
    display: flex;
    flex-direction: column;
    padding:24px;
    width: 100%;
    border-radius: 2px 12px 12px 12px;
    background-color: ${Colors.CG_30};
    z-index:${(props) => (props.step === 3 ? 10006 : 1)} ;
  position:relative;
    .title-text{
        font :${Fonts.H5_Bold};
        color:${Colors.JOBDA_BLACK};
    }
    .content-text{
        font:${Fonts.B2_Medium_P};
        color:${Colors.CG_70};
        white-space: pre-line;
    }
`;
const BtnFrame = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  position:relative;
  .refuse-btn{
    border:1px solid ${Colors.CG_60};
  }
  .lottie-indicator {
        position: absolute;
        top:22px;
    left: 50%; 
    transform: translate(-50%, -50%); 
        width: 50px;
        height: 50px;
        z-index: 10006;
      }
`;
interface IMainTutorialCompanyMessageSuggest{
  setStep : React.Dispatch<React.SetStateAction<number>>;
  step:number;
}

const MainTutorialCompanyMessageSuggest:FC<IMainTutorialCompanyMessageSuggest> = ({ setStep, step }) => {
  const { data: userInfoData } = useUserInfo();
  const [backgroundColor, setBackgroundColor] = useState<V2BackgroundColorsType>(V2BackgroundColorsType.transparent);
  const [isIndicatorVisible, setIsIndicatorVisible] = useState<boolean>(false);
  const [isWaitingVisible, setIsWaitingVisible] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (step === 3) {
        setBackgroundColor(V2BackgroundColorsType.dimmer); // 배경색을 회색으로 설정
        setIsIndicatorVisible(true);
      }
    }, 500);

    return () => clearTimeout(timer); // 컴포넌트가 언마운트되면 타이머를 정리
  }, []);

  const handleNextClick = () => {
    if (step === 3) {
      setIsIndicatorVisible(false); // Indicator 숨김
      setIsWaitingVisible(true); // Waiting 표시

      setTimeout(() => {
        setIsWaitingVisible(false);
        setBackgroundColor(V2BackgroundColorsType.transparent);
        setStep(4); // 다음 스텝으로 이동
      }, 6500);
    }
  };

  const today = new Date();
  const todayISOString = today.toISOString();
  return (
    <>
      <Dim backgroundColor={backgroundColor} />

      <Frame>
        <TitleFrame>
          <div className='jda-icon'>
            <IconLogo className='logo' fill={Colors.JOBDA_BLACK} />
          </div>
          <div className='name-text'>잡다매칭 담당자</div>
          <div className='time-text'>{getCreatedDayTime(todayISOString)}</div>
        </TitleFrame>
        <SpacingBlock size={8} vertical />
        <MessageFrame step={step}>
          <div className='title-text'>[잡다매칭] 매칭 튜토리얼 직무 채용
            - 입사 지원 제안 안내
          </div>
          <SpacingBlock size={16} vertical />
          <div className='content-text'>
            {`${userInfoData?.name}님은 매칭 튜토리얼 포지션의 최우수 매칭 인재로 선정되었습니다. 적합도는 회원님의 프로필과 역량검사 결과를 통해 산정되며, 해당 정보가  인사 담당자에게 보여집니다.\n매칭 수락 시, 인사 담당자가 응답 마감일 기준 10일 이내에 면접 여부를 검토합니다.`}
          </div>
          <SpacingBlock size={40} vertical />
          <BtnFrame>
            <div onClick={handleNextClick} role='button'>
              {step === 3 && (
                <Lottie animationData={IndicatorLottie} className='lottie-indicator' />
              )}
              <Button
                className='primary-btn'
                buttonMode={ButtonMode.PRIMARY}
                size='large'
                label='수락하기'
              />
              <MainTutorialIndicator
                titleText='제안 수락하기'
                descText='빈틈없는 알고리즘과 전문 큐레이터가 당신에게 FIT한 기업을 제안해요.'
                stepText='03'
                buttonText='다음'
                position='bottom'
                onClickNext={() => handleNextClick()}
                visible={isIndicatorVisible}
              />
              <MainTutorialWaiting
                titleText='인사담당자가 매칭 수락자 대상으로 면접 제안을 검토하고 있어요.'
                descText=''
                stepText='03'
                position='bottom'
                visible={isWaitingVisible}
              />
            </div>
            <SpacingBlock size={8} vertical />
            <Button
              className='refuse-btn'
              textColor={Colors.CG_70}
              backgroundColor='transparent'
              buttonMode={ButtonMode.CUSTOM}
              size='large'
              label='거절하기'
            />
          </BtnFrame>
        </MessageFrame>

      </Frame>
    </>
  );
};
export default MainTutorialCompanyMessageSuggest;
